import { Location } from 'history'
import { UpdatePrivacyConsentParams } from '../../hooks/events/events.types'
import ChannelInterface from '../../interfaces/ChannelInterface'

export enum types {
  CHANNEL_UPDATE_SUCCESS = 'CHANNEL_UPDATE_SUCCESS',
  LOCATIONS_UPDATE_SUCCESS = 'LOCATIONS_UPDATE_SUCCESS',
  READ_CONFIG_SUCCESS = 'GLOBAL_READ_CONFIG_SUCCESS',
  UPDATE_PRIVACY_CONSENTS_SUCCESS = 'UPDATE_PRIVACY_CONSENTS_SUCCESS',
}

export interface UpdateChannelSuccess {
  type: types.CHANNEL_UPDATE_SUCCESS
  payload: {
    current: ChannelInterface
    alternate: ChannelInterface
  }
}

export interface UpdateLocationsSuccess {
  type: types.LOCATIONS_UPDATE_SUCCESS
  payload: {
    channelId: number
    lastLocation?: Location
    prevLocation?: Location
  }
}

export interface ReadAppStateSuccess {
  type: types.READ_CONFIG_SUCCESS
  payload: any
}

export interface UpdateConsentsStateSuccess {
  type: types.UPDATE_PRIVACY_CONSENTS_SUCCESS
  payload: UpdatePrivacyConsentParams
}

export type ActionTypes = UpdateChannelSuccess | UpdateLocationsSuccess | ReadAppStateSuccess | UpdateConsentsStateSuccess
