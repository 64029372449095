export enum PrivacyConsentsFieldEnum {
  REQUIRED = 'required',
  MARKETING = 'marketing',
  FUNCTIONAL = 'functional',
}

export default interface PrivacyConsentInterface {
  [PrivacyConsentsFieldEnum.REQUIRED]: true
  [PrivacyConsentsFieldEnum.MARKETING]: boolean
  [PrivacyConsentsFieldEnum.FUNCTIONAL]: boolean
}
