import GridViewIcon from '@mui/icons-material/GridView'
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp'
import SplitscreenIcon from '@mui/icons-material/Splitscreen'
import SplitscreenTwoToneIcon from '@mui/icons-material/SplitscreenTwoTone'
import { Switch } from '@mui/material'
import React from 'react'
import styles from './CatalogViewSwitch.module.scss'

export enum ViewMode {
  SINGLE_COLUMN,
  DOUBLE_COLUMNS,
}

type Props = {
  mode: ViewMode
  onModeChange: (mode: ViewMode) => void
}

const CatalogViewSwitch = (props: Props) => {
  return (
    <div className={styles['catalog-view-switch__container']}>
      <Switch
        checked={props.mode === ViewMode.SINGLE_COLUMN}
        onChange={(_, checked) => {
          props.onModeChange(checked ? ViewMode.SINGLE_COLUMN : ViewMode.DOUBLE_COLUMNS)
        }}
        classes={{
          root: styles['catalog-view-switch__switch__root'],
          switchBase: styles['catalog-view-switch__switch__switch-base'],
          thumb: styles['catalog-view-switch__switch__thumb'],
          track: styles['catalog-view-switch__switch__track'],
          input: styles['catalog-view-switch__switch__input'],
          checked: styles['catalog-view-switch__switch--checked'],
        }}
        disableRipple
      />
      <div className={styles['catalog-view-switch__icon--left']}>
        {props.mode === ViewMode.DOUBLE_COLUMNS ? (
          <GridViewSharpIcon className={styles['catalog-view-switch__icon']} />
        ) : (
          <GridViewIcon className={styles['catalog-view-switch__icon']} />
        )}
      </div>
      <div className={styles['catalog-view-switch__icon--right']}>
        {props.mode === ViewMode.SINGLE_COLUMN ? (
          <SplitscreenTwoToneIcon className={styles['catalog-view-switch__icon']} />
        ) : (
          <SplitscreenIcon className={styles['catalog-view-switch__icon']} />
        )}
      </div>
    </div>
  )
}

export default CatalogViewSwitch
