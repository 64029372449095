import { SelectChangeEvent } from '@mui/material'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './LanguageSwitcher.module.scss'
import * as actions from '../../../services/locale/locale.actions'
import { State } from '../../../services/reducers'
import { getLanguageByIsoCode } from '../../../utils/locale'
import FormattedMessage from '../../FormattedMessage'
import SwitcherBase from '../SwitcherBase'

type Props = {
  onChange?: () => void
  classes?: {
    root?: string
  }
}

const LanguageSwitcher = (props: Props) => {
  const { classes } = props
  const dispatch = useDispatch()
  const language = useSelector((state: State) => state.locale.language)
  const languages = useSelector((state: State) => state.locale.languages)
  const onChange = (event: SelectChangeEvent<any>) => {
    const isoCode = event.target.value as string
    dispatch(actions.updateLanguageRequest(getLanguageByIsoCode(isoCode)))
    props.onChange ? props.onChange() : _.noop()
  }

  return (
    <SwitcherBase
      classes={{
        root: classNames(styles['language'], classes?.root!),
      }}
      onSelected={onChange}
      items={Object.values(languages)}
      value={language.isoCode}
      itemValueKey="isoCode"
      itemNameKey="name"
      name="language-switcher"
      label={<FormattedMessage id="LanguageSwitcher.Label" description="LanguageSwitcher.Label" defaultMessage="Language" />}
    />
  )
}

export default LanguageSwitcher
