import ls from '@livesession/sdk'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { UserNewsletterOptionInterface } from '../../interfaces/UserNewsletterOptionInterface'
import * as actions from '../../services/auth/auth.actions'
import * as authApi from '../../services/auth/auth.api'
import { State } from '../../services/reducers'
import { AxiosError, AxiosErrorCallback, AxiosResponse, AxiosSuccessCallback } from '../../utils/axios'

export interface SignUpData extends GuestData {
  birthday: string
  plainPassword: string
  newPassword: string | object
}

export interface SignInData {
  email: string
  plainPassword: string
}

export interface GuestData {
  firstName: string
  lastName: string
  email: string
  tos: boolean
  newsletter: boolean
  newsletterOptions?: UserNewsletterOptionInterface[]
}

export const useAuth = () => {
  const dispatch = useDispatch()
  const user = useSelector((state: State) => state.user) //todo zmiana na user context
  const successSignInCallback = (response: AxiosResponse, callbackSuccess: null | AxiosSuccessCallback = null) => {
    const actionData = {
      user: response.data,
    }
    dispatch(actions.signInSuccess(actionData))
    _.isFunction(callbackSuccess) ? callbackSuccess(response) : _.noop()
  }

  const signIn = (
    data: SignInData,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
  ) => {
    ls.identify({
      email: data.email,
    })
    authApi
      .signIn(data.email, data.plainPassword)
      .then((response: AxiosResponse) => {
        ls.track('Sign In - Authorized', {
          action_str: 'Authorized',
          email_str: data.email,
        })
        successSignInCallback(response, callbackSuccess)
      })
      .catch((error: AxiosError) => {
        ls.track('Sign In - Error', {
          action_str: 'Error',
          email_str: data.email,
          errors_str: JSON.stringify(error.response),
        })
        console.error(error)
        _.isFunction(callbackError) ? callbackError(error) : _.noop()
      })
  }

  const signUp = (
    data: SignUpData,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
  ) => {
    ls.identify({
      name: `${data.firstName!} ${data.lastName!}`,
      email: data.email,
    })
    authApi
      .signUp(data)
      .then((response: AxiosResponse) => {
        ls.track('Sign Up - Signed up', {
          action_str: 'Signed up',
          email_str: data.email,
          firstName_str: data.firstName,
          lastName_str: data.lastName,
          tos_bool: data.tos,
        })
        successSignInCallback(response, callbackSuccess)
      })
      .catch((error: AxiosError) => {
        ls.track('Sign Up - Error', {
          action_str: 'Error',
          email_str: data.email,
          errors_str: JSON.stringify(error.response),
        })
        _.isFunction(callbackError) ? callbackError(error) : _.noop()
      })
  }

  const createGuest = (
    data: GuestData,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
  ) => {
    ls.identify({
      name: `${data.firstName!} ${data.lastName!}`,
      email: data.email,
    })
    authApi
      .createGuest(data)
      .then((response: AxiosResponse) => {
        ls.track('Guest - Authorized', {
          action_str: 'Authorized',
          email_str: data.email,
          firstName_str: data.firstName,
          lastName_str: data.lastName,
          tos_bool: data.tos,
        })
        successSignInCallback(response, callbackSuccess)
      })
      .catch((error: AxiosError) => {
        ls.track('Guest - Error', {
          action_str: 'Error',
          email_str: data.email,
          errors_str: JSON.stringify(error.response),
        })
        _.isFunction(callbackError) ? callbackError(error) : _.noop()
      })
  }

  const isAuth = (callbackSuccess: null | AxiosSuccessCallback = null, callbackError: null | AxiosErrorCallback = null) => {
    authApi
      .isAuth()
      .then((response: AxiosResponse) => {
        _.isFunction(callbackSuccess) ? callbackSuccess(response) : _.noop()
      })
      .catch((error: AxiosError) => {
        _.isFunction(callbackError) ? callbackError(error) : _.noop()
      })
  }

  const isAuthSoft = (rejectGuest: boolean = false) => {
    if (rejectGuest && user.profile?.isGuest) {
      return false
    }

    return !!user.auth.tokenId
  }

  const logOut = (callbackSuccess: null | AxiosSuccessCallback = null, callbackError: null | AxiosErrorCallback = null) => {
    authApi
      .logOut()
      .then((response: AxiosResponse) => {
        _.isFunction(callbackSuccess) ? callbackSuccess(response) : _.noop()
        dispatch(actions.logOutSuccess())
      })
      .catch((error: AxiosError) => {
        console.error(error)
        _.isFunction(callbackError) ? callbackError(error) : _.noop()
      })
  }

  const facebookAuth = (callbackSuccess: null | AxiosSuccessCallback = null, callbackError: null | AxiosErrorCallback = null) => {
    authApi
      .facebookAuth()
      .then((response: AxiosResponse) => {
        successSignInCallback(response, callbackSuccess)
      })
      .catch((error: AxiosError) => {
        console.error(error)
        _.isFunction(callbackError) ? callbackError(error) : _.noop()
      })
  }

  return {
    signIn,
    signUp,
    isAuth,
    isAuthSoft,
    logOut,
    facebookAuth,
    createGuest,
  }
}
