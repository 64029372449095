import classNames from 'classnames'
import React, { ReactNode } from 'react'
import styles from './ModalHeader.module.scss'

interface Props {
  className?: string
  children: ReactNode
}

const ModalHeader: React.FC<Props> = (props: Props) => (
  <div className={classNames(styles['modal-header'], props.className)}>{props.children}</div>
)

export default ModalHeader
