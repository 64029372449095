import Modal from '@mui/material/Modal'
import _ from 'lodash'
import React, { Children, isValidElement, ReactNode, useEffect } from 'react'
import styles from './Modal.module.scss'
import ModalButton from './ModalButton'
import ModalContent from './ModalContent'
import ModalFooter from './ModalFooter'
import ModalHeader from './ModalHeader'

interface Props {
  open: boolean
  onClose: () => void
  children: ReactNode
}

interface ChildProps {
  className?: string
  children: ReactNode
}

const ModalContainer: React.FC<Props> = (props: Props) => {
  const { children, open, onClose } = props
  const isValidChild = (type: {}): type is React.FC<ChildProps> => _.includes([ModalHeader, ModalContent, ModalFooter], type)
  const modalParent = document.querySelector('#app > div')

  useEffect(() => {
    Children.map(children, child => {
      if (!isValidElement(child) || !isValidChild(child.type)) {
        console.error(new Error('Modal only accepts <ModalHeader />, <ModalContent />, <ModalFooter /> elements'))
      }
    })
  }, [])

  return (
    <Modal open={open} onClose={onClose} container={modalParent}>
      <div className={styles['modal__container']}>
        <div className={styles['modal']}>{children}</div>
      </div>
    </Modal>
  )
}

export { ModalHeader, ModalContent, ModalFooter, ModalButton }
export default ModalContainer
