export enum types {
  NOTIFICATION_CREATE_REQUEST = 'NOTIFICATION_CREATE_REQUEST',
  NOTIFICATION_DELETE_REQUEST = 'NOTIFICATION_DELETE_REQUEST',
  NOTIFICATIONS_DELETE_REQUEST = 'NOTIFICATIONS_DELETE_REQUEST',
}

export type NotifyElement = string | React.ReactNode
export interface NotifyOptionsInterface {
  id?: number
}

export interface NotificationInterface {
  id: number
  type: string
  content: NotifyElement
  options?: NotifyOptionsInterface
  state?: number
}

export interface NotificationCreateRequestInterface {
  type: types.NOTIFICATION_CREATE_REQUEST
  payload: NotificationInterface
}
export interface NotificationDeleteRequestInterface {
  type: types.NOTIFICATION_DELETE_REQUEST
  payload: number
}
export interface NotificationsDeleteRequestInterface {
  type: types.NOTIFICATIONS_DELETE_REQUEST
}

export type ActionTypes = NotificationCreateRequestInterface | NotificationDeleteRequestInterface | NotificationsDeleteRequestInterface
