import ls from '@livesession/sdk'
import { FormikHelpers } from 'formik'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddressBookFormType } from '../../components/AddressBook/AddressBookForm/AddressBookForm.types'
import notify from '../../components/Notification/notify'
import { AddressInterface } from '../../interfaces/AddressInterface'
import CartInterface from '../../interfaces/CartInterface'
import UserInterface from '../../interfaces/UserInterface'
import * as addressApi from '../../services/address/address.api'
import * as cartActions from '../../services/cart/cart.actions'
import * as cartApi from '../../services/cart/cart.api'
import { ADDRESS_FORMAT_FALLBACK_KEY } from '../../services/locale/locale.types'
import { State } from '../../services/reducers'
import * as userActions from '../../services/user/user.actions'
import * as userApi from '../../services/user/user.api'
import { AxiosError, AxiosResponse } from '../../utils/axios'
import countriesList from '../../utils/countries'
import { handleErrorResponse } from '../../utils/formErrorHelper'

export enum ADDRESS_TYPE {
  BILLING = 'billing',
  SHIPPING = 'shipping',
}
//for those countries we check if postcode match subutb and state
export const DEEP_STATE_VALIDATION_COUNTRIES = ['AU']

export const COUNTRY_NAME_KEY = 'country.name'
export const TAX_CODE_KEY = 'taxCode'
export const COUNTRY_CODE_KR = 'KR'
export const displayRegex = /\{([A-z0-9.]*)\}/gm

export const useAddress = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [inEditMode, setInEditMode] = useState<boolean>(false)
  const [inCreateMode, setInCreateMode] = useState<boolean>(false)
  const [addressId, setAddressId] = useState<number | null>(null)
  const [contextType, setContextType] = useState<ADDRESS_TYPE | null>(null)
  const user = useSelector((state: State) => state.user.profile)
  const cart = useSelector((state: State) => state.cart)
  const locale = useSelector((state: State) => state.locale)
  const countryIsoCode = cart?.shippingAddress?.country ? cart?.shippingAddress.country : locale.language.isoCode.substr(-2, 2)
  const [addressFormatCountryContext, setAddressFormatCountryContext] = useState<string>(countryIsoCode)

  const getAddressFormat = (_countryIsoCode?: string) => {
    return locale.addressFormats[_countryIsoCode || addressFormatCountryContext] || locale.addressFormats[ADDRESS_FORMAT_FALLBACK_KEY]
  }

  const setAddressCountryContext = async (value: string) => {
    let countryCode = countriesList.getAlpha2Code(value, locale.language.shortIsoCode) || ''
    if (!locale.addressFormats.hasOwnProperty(countryCode)) {
      countryCode = ADDRESS_FORMAT_FALLBACK_KEY
    }

    setAddressFormatCountryContext(countryCode)
  }

  const getAddressPostCodes = async (postCode: string, countryCode: string) => {
    if (postCode && DEEP_STATE_VALIDATION_COUNTRIES.includes(countryCode.toUpperCase())) {
      try {
        const response = await addressApi.readPostCodes(postCode, countryCode)
        return response.data
      } catch (error: any) {
        console.error(error)
      }
    }

    return []
  }

  useEffect(() => {
    setAddressFormatCountryContext(countryIsoCode)
  }, [countryIsoCode])

  const dispatch = useDispatch()

  let addresses: AddressInterface[] = user ? _.reverse(_.cloneDeep(user.addresses)) : []

  if (cart && cart.billingAddress !== null) {
    addresses.push(cart.billingAddress)
  }
  if (cart && cart.shippingAddress !== null) {
    addresses.push(cart.shippingAddress)
  }

  addresses = _.reverse(addresses)

  const updateUser = (
    newUser: UserInterface,
    formik: FormikHelpers<AddressBookFormType> | null = null,
    message: React.ReactElement | string | null = null,
    callbackSuccess?: (data: CartInterface) => void,
  ) => {
    const setSubmitting = _.get(formik, 'setSubmitting', _.noop)

    setIsUpdating(true)
    setSubmitting(true)

    userApi
      .updateUser(newUser)
      .then((response: AxiosResponse) => {
        dispatch(userActions.userProfileReadSuccess(response.data))
        if (message) {
          notify.success(message)
        }
        setIsUpdating(false)
        setInEditMode(false)
        setInCreateMode(false)
        setSubmitting(false)
        if (callbackSuccess) {
          callbackSuccess(response.data)
        }
      })
      .catch((error: AxiosError) => {
        setSubmitting(false)
        setIsUpdating(false)
        handleErrorResponse(error.response || {}, formik)
      })
  }

  const updateCart = (
    newCart: CartInterface,
    formik: FormikHelpers<AddressBookFormType> | null = null,
    message?: React.ReactElement | string,
    strict?: boolean,
    callbackSuccess?: (data: CartInterface) => void,
  ) => {
    const setSubmitting = _.get(formik, 'setSubmitting', _.noop)

    setIsUpdating(true)
    setSubmitting(true)

    cartApi
      .updateCart(newCart, strict || false)
      .then((response: AxiosResponse) => {
        dispatch(cartActions.cartReadSuccess(response.data))
        if (message) {
          notify.success(message)
        }
        setIsUpdating(false)
        setInEditMode(false)
        setInCreateMode(false)
        setSubmitting(false)
        if (callbackSuccess) {
          callbackSuccess(response.data)
        }
      })
      .catch((error: AxiosError) => {
        setSubmitting(false)
        setIsUpdating(false)
        handleErrorResponse(error.response || {}, formik)
      })
  }

  const setAddressAsDefault = (
    type: ADDRESS_TYPE,
    id: number,
    formik: FormikHelpers<AddressBookFormType> | null = null,
    message?: React.ReactElement | string,
  ) => {
    if (!user) return

    const newUser = _.cloneDeep(user)
    newUser.addresses.forEach((address: AddressInterface, idx: number) => {
      //@ts-ignore
      newUser.addresses[idx][`is${_.upperFirst(type)}`] = false
      if (address.id === id) {
        //@ts-ignore
        newUser.addresses[idx][`is${_.upperFirst(type)}`] = true
      }
    })

    updateUser(newUser, formik, message)
  }

  const deleteAddress = (id: number, message?: React.ReactElement | string) => {
    const newUser = _.cloneDeep(user)

    if (!newUser || !user) {
      console.error('Delete Address - newUser not valid')
      return
    }

    newUser.addresses = newUser.addresses.filter(item => {
      if (item.id !== id) {
        return item
      }
      return null
    })

    ls.track('Address Deleted', {
      event_str: 'Deleted',
      addressId_int: id,
    })

    updateUser(newUser, null, message)
  }

  const setEditMode = (id: number | boolean) => {
    if (id === false) {
      setAddressId(null)
      setInEditMode(false)
      setInCreateMode(false)
    } else if (_.isNumber(id)) {
      setAddressId(id)
      setInEditMode(true)
      setInCreateMode(false)
    }
  }

  const setCreateMode = (active: boolean) => {
    setAddressId(null)
    setInEditMode(false)
    setInCreateMode(active)
  }

  const getCurrentAddress = () => {
    if (!addresses) return undefined

    return addresses.find(item => {
      if (item.id === addressId) {
        return item
      }
      return null
    })
  }

  const getAddressById = (id: number) => {
    if (!addresses) return null

    const res = addresses.find(item => {
      if (item.id === id) {
        return item
      }
      return null
    })

    return res || null
  }

  const getAddressByType = (type: ADDRESS_TYPE, useAddressBook?: boolean): AddressInterface | null => {
    let address = null
    if (type === ADDRESS_TYPE.SHIPPING) {
      address = cart.shippingAddress?.valid ? cart.shippingAddress : null
    }
    if (type === ADDRESS_TYPE.BILLING) {
      address = cart.billingAddress?.valid ? cart.billingAddress : null
    }

    if (!address && user) {
      if (useAddressBook) {
        user.addresses.forEach((addr: AddressInterface) => {
          if (type === ADDRESS_TYPE.SHIPPING && addr.isShipping && addr.valid) {
            address = addr
          } else if (type === ADDRESS_TYPE.BILLING && addr.isBilling && addr.valid) {
            address = addr
          }
        })
      } else if (type === ADDRESS_TYPE.BILLING) {
        address = getAddressByType(ADDRESS_TYPE.SHIPPING)
      }
    }
    return address
  }

  return {
    addresses,
    setAddressAsDefault,
    isUpdating,
    inCreateMode,
    setCreateMode,
    updateUser,
    deleteAddress,
    setEditMode,
    inEditMode,
    getCurrentAddress,
    getAddressByType,
    getAddressFormat,
    setAddressCountryContext,
    updateCart,
    getAddressById,
    contextType,
    setContextType,
    addressFormatCountryContext,
    getAddressPostCodes,
    addressFormats: locale.addressFormats,
  }
}

export default useAddress
