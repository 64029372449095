import { ActionTypes, types } from './app.types'
import AppInterface from '../../interfaces/AppInterface'

export function readAppStateRequest(languageIsoCode?: string): ActionTypes {
  return {
    type: types.READ_APP_STATE_REQUEST,
    payload: {
      languageIsoCode,
    },
  }
}

export function readAppStateSuccess(appState: AppInterface): ActionTypes {
  return {
    type: types.READ_APP_STATE_SUCCESS,
    payload: appState,
  }
}
