import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import { IconButton, SnackbarContent } from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import styles from './Notification.module.scss'
import * as notificationTypes from './Notification.types'
import { NotificationInterface } from '../../services/notification/notification.types'

interface Props extends NotificationInterface {
  onClose?: (event: React.SyntheticEvent<any, Event>, reason: string) => void
  className?: string
  flat?: boolean
}

const iconTypes = {
  [notificationTypes.SUCCESS]: CheckCircleIcon,
  [notificationTypes.WARNING]: WarningIcon,
  [notificationTypes.ERROR]: ErrorIcon,
  [notificationTypes.INFO]: InfoIcon,
}

const contentClasses = {
  [notificationTypes.SUCCESS]: styles['snackbar__content--success'],
  [notificationTypes.WARNING]: styles['snackbar__content--warning'],
  [notificationTypes.ERROR]: styles['snackbar__content--error'],
  [notificationTypes.INFO]: styles['snackbar__content--info'],
}

type SnackbarContentType = React.ComponentType<Props>

const Notification = React.forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
  const { id, type, content, className, onClose, flat } = props
  const Icon = iconTypes[type as keyof typeof iconTypes]
  const typeClasses = contentClasses[type.toLowerCase() as keyof typeof iconTypes]
  const messageId = `snackbar-message-${id}`
  const closeId = `snackbar-close-${id}`
  return (
    <SnackbarContent
      elevation={flat ? 0 : 2}
      square
      ref={ref}
      classes={{
        root: classNames(styles['snackbar__content'], { [styles['snackbar__content--info']]: type === notificationTypes.INFO }),
        action: styles['snackbar__action'],
      }}
      className={classNames(typeClasses, className)}
      aria-describedby="client-snackbar"
      message={
        <span id={messageId} className={styles['notification__message']}>
          <Icon className={classNames(styles['notification__icon'], styles['notification__icon--left'])} />
          <div>{content}</div>
        </span>
      }
      action={
        onClose
          ? [
              <IconButton
                key={closeId}
                aria-label="Close"
                color="inherit"
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClose(event, 'trigger')}
                size="large"
              >
                <CloseIcon className={styles['notification__icon']} />
              </IconButton>,
            ]
          : []
      }
    />
  )
})

Notification.displayName = 'Notification'

export default Notification
