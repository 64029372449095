import { RESULTS_PER_PAGE } from '../../hooks/search/search'
import axios from '../../utils/axios'
import * as types from './club.types'

export function auth(password: string) {
  return axios<any, types.ClubSignInResponse>({
    method: 'POST',
    url: '/club/auth.json',
    data: {
      password,
    },
  })
}

export function getClubData() {
  return axios<any, types.ClubGetClubResponse>({
    method: 'GET',
    url: '/club.json',
  })
}

export function searchOffers(page: number, perPage = RESULTS_PER_PAGE, fetchAllPages?: boolean) {
  return axios<any, types.ClubOffersResponse>({
    method: 'GET',
    url: `/club/offer.json?perPage=${perPage}&page=${page}&allPages=${Number(fetchAllPages)}`,
  })
}
