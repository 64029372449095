import React from 'react'
import routes from '../../../app/routes'
import FormattedMessage from '../../FormattedMessage'
import Link from '../../Link'
import styles from '../../Menu/MobileMenu/MobileMenu.module.scss'

type Props = {
  onAccountClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  onOrdersClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  onLogoutClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const SidebarAccount = (props: Props) => {
  return (
    <div>
      <ul className={styles['mobile-menu']}>
        <li className={styles['mobile-menu__item']}>
          <Link to={routes.user} onClick={props.onAccountClick} className={styles['mobile-menu__link']}>
            <FormattedMessage
              id="SidebarAccount.MyAccount"
              description="SidebarAccount.MyAccount"
              defaultMessage="My Account"
              className={styles['mobile-menu__label']}
            />
          </Link>
        </li>
        <li className={styles['mobile-menu__item']}>
          <Link to={routes.userOrders} onClick={props.onOrdersClick} className={styles['mobile-menu__link']}>
            <FormattedMessage
              id="SidebarAccount.MyOrders"
              description="SidebarAccount.MyOrders"
              defaultMessage="My Orders"
              className={styles['mobile-menu__label']}
            />
          </Link>
        </li>
        <li className={styles['mobile-menu__item']}>
          <Link to={routes.home} onClick={props.onLogoutClick} className={styles['mobile-menu__link']}>
            <FormattedMessage
              id="SidebarAccount.LogOut"
              defaultMessage="Log Out"
              description="SidebarAccount.LogOut"
              className={styles['mobile-menu__label']}
            />
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default SidebarAccount
