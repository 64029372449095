import path from 'path'
import React, { useContext } from 'react'
import ChannelContext from '../../context/ChannelContext'
import { getHostname } from '../../utils/link'

export const useAsset = () => {
  const channel = useContext(ChannelContext).current

  const getAssetUrl = (file: string, withoutTheme: boolean = true, abosluteUrl: boolean = true) => {
    const assetUrl = withoutTheme ? '/' : `/themes/${channel.theme}/`
    const url = `${getHostname(channel.domain)}${assetUrl}${file}`
    return url
  }

  return {
    getAssetUrl,
  }
}

export default useAsset
