import ls from '@livesession/sdk'
import _ from 'lodash'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as api from './auth.api'
import { SignInSuccessAction, types } from './auth.types'
import UserInterface from '../../interfaces/UserInterface'
import * as cartActions from '../cart/cart.actions'
import * as userActions from '../user/user.actions'
import { types as userTypes, UserProfileReadSuccess } from '../user/user.types'

export function* logOutSuccessEvent() {
  FB.getLoginStatus(function (response: fb.StatusResponse) {
    if (response.status === 'connected') {
      FB.logout()
    }
  })
  yield call(api.destroyCookie)
  yield call(api.logOut)
  yield put(cartActions.cartDeleteRequest())
}

export function* signInSuccessEvent(action: SignInSuccessAction | UserProfileReadSuccess): any {
  let propagation = null
  let readProfile = null
  let user: UserInterface | null = null
  if (action.type === types.SIGN_IN_SUCCESS) {
    user = action.payload.user
  } else if (action.type === userTypes.USER_PROFILE_READ) {
    user = action.payload
  }
  if (user && _.has(user, 'tokenId') && _.has(user, 'secret') && user.secret !== null) {
    const { tokenId, secret } = user
    propagation = yield (call as any)(api.setCookie, tokenId, secret)
  }

  if (user) {
    ls.identify({
      name: `${user.firstName!} ${user.lastName!}`,
      email: user.email,
    })
    readProfile = yield put(userActions.userProfileReadSuccess(user))
  }

  const readCart = yield put(cartActions.cartReadRequest())

  yield all([propagation, readProfile, readCart])
}

function* signInListener() {
  yield takeEvery(types.SIGN_IN_SUCCESS, signInSuccessEvent)
}

function* logOutListener() {
  yield takeEvery(types.LOG_OUT_SUCCESS, logOutSuccessEvent)
}

export default function* authSaga() {
  yield all([signInListener(), logOutListener()])
}
