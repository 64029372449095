import React from 'react'
import Helmet from 'react-helmet'

export const PrerenderRedirect: React.FC<{ to: string }> = ({ to }) => (
  <Helmet>
    <meta name="prerender-status-code" content="301" />
    <meta name="prerender-header" content={`Location: ${to}`} />
  </Helmet>
)

export default PrerenderRedirect
