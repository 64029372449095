import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import styles from './MegaMenuItems.module.scss'
import { MenuItemInterface } from '../../../../interfaces/MenuInterface'
import NavigationLink from '../../NavigationLink'

const GTM_MEGA_MENU = 'gtm-mega-menu'

type Props = {
  items: MenuItemInterface[]
  activeItem: MenuItemInterface | null
  activeItemIds: number[]
  shownLink: string | null
  handleItemClick: (event: React.MouseEvent<HTMLElement>) => void
  handleMenuOpen: (event: React.MouseEvent<HTMLLIElement>) => void
  handleMenuClose: () => void
}

const MegaMenuItems = (props: Props) => {
  const { items, activeItem, activeItemIds, shownLink, handleItemClick, handleMenuOpen, handleMenuClose } = props

  const getMenuTabClasses = (item: MenuItemInterface) =>
    classNames(GTM_MEGA_MENU, styles['mega-menu__tab'], {
      [styles['mega-menu__item--active']]: activeItemIds.includes(item.id) || item.link === activeItem?.link,
      [styles['mega-menu__item--hover']]: shownLink === item.link,
    })

  const getMenuItemClasses = (item: MenuItemInterface) =>
    classNames(styles[`mega-menu__item--level${item.level}`], {
      [styles['mega-menu__item--special']]: item.isSpecial,
    })

  const getMegaMenuItemsListClasses = (item: MenuItemInterface) =>
    classNames(styles['mega-menu__list'], {
      [styles['mega-menu__list--active']]: shownLink === item.link,
    })

  const renderSubMenu = (parentItem: MenuItemInterface) => {
    const cols = parentItem.children.filter((item, index) => index > 0 && item.isBreak).length + 1
    return _.cloneDeep(parentItem.children)
      .reduce((result: MenuItemInterface[], item, index) => {
        if (item.isBreak) {
          if (index > 0) {
            result.push({
              ...item,
              key: `${index}~${item.link}`,
              isBreak: true,
            })
          }

          delete item.isBreak
        }

        result.push(item)
        return result
      }, [])
      .map((item: MenuItemInterface) => {
        if (item.isBreak) {
          return <li key={item.key} className={styles['mega-menu__item--break']} />
        }

        const itemStyle = {
          width: `${100 / Math.max(1, cols)}%`,
        }
        return (
          <li key={item.link} style={itemStyle} className={styles['mega-menu__list-item']}>
            <NavigationLink to={item.link} classes={{ root: getMenuItemClasses(item) }}>
              <span
                data-link={item.link}
                className={classNames(GTM_MEGA_MENU, getMenuItemClasses(item))}
                data-gtmvalue={item.anchor}
                onClick={handleItemClick}
              >
                {item.anchor}
              </span>
            </NavigationLink>
          </li>
        )
      })
  }
  return (
    <>
      {items.map(parentItem => {
        let rows = 0
        let maxRows = 0
        parentItem.children.forEach((child, index) => {
          if (child.isBreak) {
            rows = 1
          } else ++rows
          maxRows = rows > maxRows ? rows : maxRows
        })

        const rowHeight = 36
        const paddingSize = 40
        const style = {
          height: `${maxRows * rowHeight + paddingSize}px`,
          padding: `${paddingSize}px ${paddingSize}px 0px ${paddingSize}px`,
        }

        return parentItem.children.length > 0 ? (
          <li
            key={parentItem.link}
            data-link={parentItem.link}
            className={styles['mega-menu__item--level1']}
            onMouseEnter={handleMenuOpen}
            onMouseLeave={handleMenuClose}
            id="submenu"
          >
            <span className={getMenuTabClasses(parentItem)} data-gtmvalue={parentItem.anchor}>
              {parentItem.name}
            </span>
            <ul className={getMegaMenuItemsListClasses(parentItem)} style={style}>
              {renderSubMenu(parentItem)}
            </ul>
          </li>
        ) : (
          <li key={parentItem.link} className={styles['mega-menu__item--level1']} onMouseLeave={handleMenuClose} id="submenu">
            <NavigationLink to={parentItem.link}>
              <span data-link={parentItem.link} onClick={handleItemClick} className={getMenuTabClasses(parentItem)}>
                {parentItem.anchor}
              </span>
            </NavigationLink>
          </li>
        )
      })}
    </>
  )
}

export default MegaMenuItems
