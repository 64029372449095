import { AddressInterface } from './AddressInterface'
import { ChannelSiteInterface } from './ChannelInterface'
import { DiscountInterface } from './DiscountInterface'
import { PhotoSetInterface } from './PhotoInterface'
import { ProductInterface } from './ProductInterface'
import { ShippingMethodInterface } from './ShippingInterface'
import { ChannelConfig } from '../hooks/channelConfig/channelConfig.types'

export const ITEM_QTY_LIMIT = 100
export const CLUB_ITEM_QTY_LIMIT = 2000
export const CART_PAYMENTS_LIMIT_RECAPTCHA_THRESHOLD = 6
export const CART_PAYMENTS_RECAPTCHA_BLOCK_THRESHOLD = 0
export const PAYMENTS_LIMIT_HEADER = 'x-payment-rate-limit'

export enum PRODUCT_TYPE_ENUM {
  PARENT = 1,
  REGULAR = 2,
  VIRTUAL = 3,
  PACKAGE = 4,
  GIFT = 5,
}

export enum PAYMENT_STATUS {
  SUCCESS = 'success',
}

export enum ORDER_STATUS_ENUM {
  NEW = 1,
  AWAITING_PAYMENT = 2,
  PAYMENT_DONE = 3,
  PAYMENT_ERROR = 4,
  BILLED = 5,
  SHIPPED = 6,
  PAYMENT_CANCELLED = 7,
  BLOCKED = 8,
  PROCESSING = 9,
  RECEIVING = 10,
  REFUNDED = 11,
  REJECTED = 12,
  RECEIVED = 13,
  AWAITING_CANCEL = 14,
  CANCELLED = 15,
}

export const ORDER_STATUS_FOR_RETURN = [ORDER_STATUS_ENUM.BILLED]

export enum ORDER_TYPE_ENUM {
  BRANDSITE = 1,
  BRANDSHOP = 2,
  NETSUITE_CASH = 3,
  NETSUITE_INVOICE = 4,
  NETSUITE_RECEIPT = 5,
  REFUND = 6,
  RETURN = 7,
}

export interface CartItemInterface {
  id: number
  skuCode: string
  name: string
  quantity: number
  priceNet: string
  priceGross: string
  product: ProductInterface
  productId: number
  productInfo: any //TODO fix IT
  productType: number
  productReferenceId: number
  productReferenceType: number
  taxCode: string
  taxSymbol: string
  taxRate: string
  mode: number | null
  costNet: string
  costGross: string
  costTax: string
  errors?: CartError[] | string[]
  originalPriceGross?: string
  returnable?: boolean
}

export interface CartError {
  code: string
  message: string
  args: string | number[]
}

export interface CartItemGiftInterface {
  id: number
  code: string | null
  recipientEmail: string
  recipientName: string
  senderEmail: string
  senderName: string
  headline: string
  message: string
  amountNet: string
  amountGross: string
  gift: {
    id: number
    image: PhotoSetInterface
  }
}

export interface CartInterface {
  id: number | null
  name?: string
  status: ORDER_STATUS_ENUM
  currency: string
  itemCount: number | boolean
  totalItemsNet: string
  totalItemsGross: string
  totalItemsTax: string
  totalNet: string
  totalGross: string
  totalTax: string
  shippingCostNet: string
  shippingCostGross: string
  userId: number | null
  createdAt: string
  submittedAt: string
  exchangeRate: string
  locale: string
  valid: boolean
  errors?: CartError[] | string[]
  items: CartItemInterface[]
  shippingMethod: ShippingMethodInterface | null
  billingAddress: AddressInterface | null
  shippingAddress: AddressInterface | null
  discounts: DiscountInterface[]
  totalDiscountNet: string
  totalDiscountGross: string
  payments: CartPaymentInterface[]
  gifts: CartItemGiftInterface[] | null
  requiredPayment: string | null
  authorityToLeave?: boolean
  shippingSpendToFree?: string | null
  deliveryNotice: string | null
  shippingTrackingNumber?: string
  shippingTrackingUrl?: string
  type: ORDER_TYPE_ENUM | null
  returnDeadline?: string | null
  channelEntry: ChannelSiteInterface
  paymentsLimit: number
  forcedCountry: string | null
}

export interface CartPaymentInterface {
  id: number
  paymentMethod: string
  paymentStatus: string
  updatedAt: string | number
  createdAt: string | number
  amount: string
}

export interface RefundInterface extends CartInterface {
  connectedSaleOrderId: number
  referenceNumber: string | number | null
}

export interface RefundInstructionsInterface {
  address: string | null
  confirmationMessage: string | null
  country: string | null
  id: number | null
  message: string | null
  terms: string | null
}

export interface CartCustomsDescriptors {
  country: string
  configField: keyof Pick<ChannelConfig, 'koreaOrderValue' | 'usOrderValue'>
}

export default CartInterface
