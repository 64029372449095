import { useContext } from 'react'
import AppContext from '../../context/AppContext'
import { TriggerEventInterface, AppEventsEnum } from './events.types'

const useAppEvents = () => {
  const { subscribers } = useContext(AppContext)

  const triggerEvent: TriggerEventInterface = (name: AppEventsEnum, data?: any): void => {
    subscribers.forEach(s => s[name] && s[name]!(data))
  }

  return { triggerEvent }
}

export default useAppEvents
