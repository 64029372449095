import axios from '../../utils/axios'

export function searchItems(query: string) {
  return axios({
    method: 'GET',
    url: '/search.json',
    params: {
      query,
    },
  })
}

export function searchStores(distance: string, country?: string, location?: string, offer?: string, online: number = 0) {
  const _params = {} as any
  if (country) _params.country = country
  if (location) _params.location = location
  if (offer) _params.offer = offer
  if (distance && !country) _params.distance = distance
  _params.online = online

  return axios({
    method: 'GET',
    url: '/search/store.json',
    params: _params,
  })
}

export function searchFull(query: string, page: number = 1, perPage: number = 20, fetchAllPages?: boolean) {
  const url = `/search/full.json?query=${query}&perPage=${perPage}&page=${page}&allPages=${Number(fetchAllPages)}`

  return axios({
    method: 'GET',
    url,
  })
}

export function searchHints(query: string) {
  return axios({
    method: 'GET',
    url: '/search/quick.json',
    params: {
      query,
    },
  })
}
