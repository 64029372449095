import { Alert, Typography } from '@mui/material'
import { FormikHelpers } from 'formik'
import React from 'react'
import ClubSignInForm, { ClubSignInFormValues } from '../../../components/ClubSignInForm'
import FormattedMessage from '../../../components/FormattedMessage'
import LoadingSpinner from '../../../components/LoadingSpinner'
import PageLayout from '../../../components/PageLayout'
import useClubContext from '../../../hooks/club/clubContext'
import styles from './ClubSignIn.module.scss'

interface Props {
  onSubmit: (values: ClubSignInFormValues, formik: FormikHelpers<ClubSignInFormValues>) => Promise<void>
}

const ClubSignIn = (props: Props) => {
  const clubContext = useClubContext()

  return (
    <PageLayout>
      {clubContext.isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles['container']}>
          {clubContext.loginError ? (
            <>
              <Alert severity="error">
                <Typography variant="body1" align="center" className={styles['container__heading']}>
                  {clubContext.loginError}
                </Typography>
              </Alert>
              <br />
            </>
          ) : null}
          <Typography variant="h5" align="center" paragraph className={styles['container__heading']}>
            <FormattedMessage id="Club.SignIn.Heading" defaultMessage="Please enter your password" />
          </Typography>
          <Typography variant="body1" align="center" gutterBottom className={styles['container__heading']}>
            <FormattedMessage
              id="Club.SignIn.EnterPassword"
              defaultMessage="To access your exclusive club content, please enter the password."
            />
          </Typography>
          <ClubSignInForm onSubmit={props.onSubmit} className={styles['container__form']} />
        </div>
      )}
    </PageLayout>
  )
}

export default ClubSignIn
