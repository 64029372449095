import Skeleton from '@mui/material/Skeleton'
import React from 'react'
import { CatalogListSubcategoryInterface } from '../../../../interfaces/CatalogInterface'
import { _at } from '../../../../utils/translations'
import FormattedMessage from '../../../FormattedMessage'
import Link from '../../../Link'
import Photo from '../../../Photo'
import styles from '../CatalogListItem.module.scss'

interface Props {
  item: CatalogListSubcategoryInterface
}

const CatalogListSubcategory: React.FC<Props> = (props: Props) => {
  const item = props.item
  const images = item.description.photos
  const name = _at('name', item.translations)

  return (
    <Link to={item.to} classes={{ root: styles['catalog-item'] }} key={item.id}>
      <div className={styles['catalog-item__img-container']}>
        <Photo src={images} type="catalog" classes={styles['catalog-item__img']} alt={name} noEmpty />
      </div>
      {item?.id ? (
        <div className={styles['catalog-item__info']}>
          <p className={styles['catalog-item__print-name']}>{name}</p>
          <p className={styles['catalog-item__name']}>
            <span>
              <FormattedMessage
                tag="span"
                oldId="app.label.shopNow"
                id="App.Label.ShopNow"
                description="App.Label.ShopNow"
                defaultMessage="Shop More"
              />
            </span>
          </p>
        </div>
      ) : (
        <Skeleton variant="rectangular" classes={{ root: styles['catalog-item__info'] }} animation="wave" />
      )}
    </Link>
  )
}

export default CatalogListSubcategory
