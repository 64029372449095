import { Button, DialogActions, DialogContent } from '@mui/material'
import * as React from 'react'
import FormattedMessage from '../../FormattedMessage/FormattedMessage'
import Link from '../../Link/Link'

type Props = {
  onAccept: () => void
  onShowMoreInformation: () => void
}

const PrivacyConsentDescriptionDialogView = (props: Props) => {
  const { onAccept, onShowMoreInformation } = props

  return (
    <>
      <DialogContent>
        <FormattedMessage
          id="PrivacyConsentDialog.Description"
          defaultMessage="We use cookies to personalise content and ads to provide social media features and to analyse 
            our traffic. We also share information about your use of our site with our social media, advertising and 
            analytics partners who may combine it with other information that you've provided to them or that they've 
            collected from your use of their services. To get more information about these cookies and the processing 
            of your personal data, check our Privacy Policy at the bottom of the page."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onShowMoreInformation} color="info" variant="contained">
          <FormattedMessage id="PrivacyConsentDialog.MoreInformation" defaultMessage="Manage tracking" />
        </Button>
        <Button onClick={onAccept} color="primary" variant="contained">
          <FormattedMessage id="PrivacyConsentDialog.Accept" defaultMessage="Accept tracking" />
        </Button>
      </DialogActions>
    </>
  )
}

export default PrivacyConsentDescriptionDialogView
