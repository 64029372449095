import { _at } from './translations'
import { CatalogItemType } from '../interfaces/CatalogInterface'

export type LinkType = {
  name: string
  to: string
  itemType: CatalogItemType
}

export const getProductLink = (productId: number, productSlug: string) => `/product/${productId}-${productSlug}`

export const getOppositeShopProductRoute = (domain: string, productRoute: string) => {
  const protocol = window.location.protocol
  return `${protocol}//${domain}${productRoute}`
}

export function getHostname(host?: string) {
  return window?.location?.protocol + '//' + (host || window?.location?.host!)
}
