import React, { useContext } from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import { helmetJsonLdProp } from 'react-schemaorg'
import { BreadcrumbList, ListItem, Organization, Product } from 'schema-dts'
import routes from '../../app/routes'
import AppContext from '../../context/AppContext'
import ChannelContext from '../../context/ChannelContext'
import useProduct from '../../hooks/product'
import ProductInterface from '../../interfaces/ProductInterface'
import { State } from '../../services/reducers'
import { getHostname } from '../../utils/link'
import { getBreadcrumbs } from '../../utils/product'
import { _at } from '../../utils/translations'
import useAsset from '../Asset/useAsset'
import { THEME_FUNKITA } from '../Channel/loadTheme'
import { resolveRoute } from '../Link/linkResolver'

type BreadcrumbItem = {
  name: string
  anchor?: string
}

type Props = {
  title?: string
  description?: string
  image?: string
  product?: ProductInterface
  canonical?: string
  imageWidth?: string
  imageHeight?: string
  imageAlt?: string
  keywords?: string
  breadcrumbs?: BreadcrumbItem[]
  noRobots?: boolean
  hrefLangs?: true | JSX.Element[]
}

const DEFAULT_IMAGE = 'img/order-confirmation.jpg'
const ROBOTS_WHITELIST = [
  routes.home,
  routes.product,
  routes.catalog,
  routes.search,
  routes.cmsPage,
  routes.sizeGuide,
  routes.giftVoucher,
  routes.blog,
  routes.blogTag,
  routes.blogPost,
  routes.contact,
  routes.storeLocator,
  routes.cms,
]

const MetaTags = (props: Props) => {
  const channel = React.useContext(ChannelContext).current
  const { title, description, image, product, canonical, imageWidth, imageHeight, imageAlt, keywords, breadcrumbs, noRobots } = props
  const pageLinks = product ? product?.description.pageLinks : channel?.category?.description.pageLinks
  const language = useSelector((store: State) => store.locale.language)
  const currency = useSelector((store: State) => store.locale.currency)
  const { setProduct, getPrice, getAvailability } = useProduct()
  const productAvailability = getAvailability(product!)
  const productName = _at('printName', product?.translations!) || ''
  const productBreadcrumbs = product ? getBreadcrumbs(channel, product, language.isoCode) : []
  const location = useLocation()
  const appContext = useContext(AppContext)
  const urlParams = useParams()
  const [allowRobots, setAllowRobots] = React.useState<boolean>(false)

  React.useEffect(() => {
    for (const whiteListed of ROBOTS_WHITELIST) {
      const resolvedUrl = resolveRoute(whiteListed, urlParams)
      if (resolvedUrl === location.pathname) {
        setAllowRobots(true)
        break
      }
    }
  }, [location.pathname])

  const hrefLangs =
    props.hrefLangs && props.hrefLangs !== true
      ? props.hrefLangs
      : props.hrefLangs &&
        pageLinks &&
        pageLinks.map(i => <link rel="alternate" href={i.url} hrefLang={i.lang} key={`${i.url} - ${i.lang}`} />)

  React.useEffect(() => {
    if (product) {
      setProduct(product)
    }
  }, [product?.id])

  const { getAssetUrl } = useAsset()
  const url = `${getHostname(channel.canonicalDomain)}${location.pathname}${location.search}`

  let imageURL = image ? getAssetUrl(image!, true, true) : null

  if (!image && product && product.description?.photos?.product) {
    imageURL = product.description?.photos?.product[0]?.url
  } else if (!image) {
    imageURL = getAssetUrl(DEFAULT_IMAGE, false, true)
  }

  const productSchema = product
    ? helmetJsonLdProp<Product>({
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: productName,
        brand: {
          '@type': 'Brand',
          name: channel.frontLabel,
        },
        description,
        sku: product.skuCode,
        image: imageURL!,
        offers: {
          '@type': 'Offer',
          url: canonical,
          price: getPrice()?.grossPrice,
          priceCurrency: currency.isoCode,
          availability: productAvailability?.available ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
          itemCondition: 'https://schema.org/NewCondition',
        },
      })
    : {}

  const productBreadcrumbsList = productBreadcrumbs.map((item, index) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      name: item.anchor,
      item: `${getHostname(channel.canonicalDomain)}${item.link}`,
    } as ListItem
  })

  if (product && productBreadcrumbsList.length > 0) {
    productBreadcrumbsList.push({
      '@type': 'ListItem',
      position: productBreadcrumbsList.length + 1,
      name: productName,
    })
  }

  let metaBreadcrumbs = product
    ? helmetJsonLdProp<BreadcrumbList>({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: productBreadcrumbsList,
      })
    : {}

  if (breadcrumbs) {
    metaBreadcrumbs = helmetJsonLdProp<BreadcrumbList>({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs.map((item, index) => {
        return {
          '@type': 'ListItem',
          position: index + 1,
          name: item.name,
          item: item.anchor ? `${getHostname(channel.canonicalDomain)}${item.anchor}` : '',
        } as ListItem
      }),
    })
  }

  const logoJsonLd = helmetJsonLdProp<Organization>({
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: getHostname(channel.canonicalDomain),
    logo: getHostname(channel.canonicalDomain) + `/favicon_${channel.theme}.ico?v=2`,
  })
  return (
    <>
      <Helmet script={[productSchema, metaBreadcrumbs, logoJsonLd]}>
        <meta charSet="utf-8" />
        {channel.path == '/' ? hrefLangs : null}
        <link rel="shortcut icon" href={`/favicon_${channel.theme}.ico?v=2`} />
        <link rel="icon" href={`/favicon_${channel.theme}.png?v=2`} />
        <link rel="apple-touch-icon" href={`/favicon_${channel.theme}.png?v=2`} />
        <title>{title}</title>
        {allowRobots && channel.robots && !noRobots ? (
          <meta name="robots" content={channel.robots} />
        ) : (
          <meta name="robots" content="noindex,nofollow" />
        )}
        {appContext?.redirect ? <meta name="prerender-status-code" content="301" /> : null}

        {appContext?.redirect ? (
          <meta name="prerender-header" content={`Location: ${getHostname(channel.canonicalDomain)}${appContext.redirect}`} />
        ) : null}

        <meta name="description" content={description} />
        {keywords ? <meta name="keywords" content={keywords} /> : null}
        {canonical ? <link rel="canonical" href={canonical} /> : null}
        {product ? <meta property="product:price:amount" content={getPrice()?.grossPrice} /> : null}
        {product ? <meta property="product:price:currency" content={currency.isoCode} /> : null}
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {imageURL ? <meta property="og:image" content={imageURL} /> : null}
        {imageWidth ? <meta property="og:image:width" content={imageWidth} /> : null}
        {imageHeight ? <meta property="og:image:height" content={imageHeight} /> : null}
        {imageAlt ? <meta property="og:image:alt" content={imageAlt} /> : null}
        {language?.isoCode ? <meta property="og:locale" content={language.isoCode.replace('-', '_').toLowerCase()} /> : null}
        <meta name="twitter:card" content="summary" />
        {channel.theme === THEME_FUNKITA ? (
          <meta name="twitter:site" content="@funkitaswimwear" />
        ) : (
          <meta name="twitter:site" content="@funkytrunks" />
        )}
        {channel.theme === THEME_FUNKITA ? (
          <meta name="twitter:site:id" content="@funkitaswimwear" />
        ) : (
          <meta name="twitter:site:id" content="@funkytrunks" />
        )}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {channel.theme === THEME_FUNKITA ? (
          <meta name="twitter:creator" content="@funkitaswimwear" />
        ) : (
          <meta name="twitter:creator" content="@funkytrunks" />
        )}

        {product ? <meta name="twitter:data1" content="Price" /> : null}
        {product ? <meta name="twitter:label1" content={getPrice()?.grossPrice} /> : null}

        {imageURL ? <meta name="twitter:image" content={imageURL} /> : null}
      </Helmet>
    </>
  )
}

export default MetaTags
