import React from 'react'
import { useAuth } from '../../../hooks/auth'
import { FacebookLogin } from '../../../utils/fb'
import FormattedMessage from '../../FormattedMessage'
import notify from '../../Notification/notify'
import SidebarAccount from '../SidebarAccount'
import SidebarAuthForms from '../SidebarAuthForms'

type Props = {
  onItemClick: (event: React.SyntheticEvent) => void
}

const SidebarTabAccount = (props: Props) => {
  const onLogoutClick = (event: React.SyntheticEvent) => {
    logOut(() => {
      props.onItemClick(event)
    })
  }

  const onFacebookClick = () => {
    FacebookLogin(
      () => {
        facebookAuth(null, () => {
          notify.error(
            <FormattedMessage
              id="SignForms.FacebookAuthFatalError"
              defaultMessage="Something went wrong, please try again and try clear your cookies."
            />,
          )
        })
      },
      () => {
        notify.error(<FormattedMessage id="SignForms.FacebookAuthFailed" defaultMessage="Please authorize our application" />)
      },
    )
  }

  const { isAuthSoft, logOut, facebookAuth } = useAuth()

  return isAuthSoft(true) ? (
    <SidebarAccount onAccountClick={props.onItemClick} onOrdersClick={props.onItemClick} onLogoutClick={onLogoutClick} />
  ) : (
    <SidebarAuthForms onFacebookButtonClick={onFacebookClick} />
  )
}

export default SidebarTabAccount
