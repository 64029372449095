import React from 'react'
import styles from './CatalogList.module.scss'
import CatalogListItem from './CatalogListItem'
import CatalogNoProducts from './CatalogNoProducts/CatalogNoProducts'
import { ViewMode } from './CatalogViewSwitch/CatalogViewSwitch'
import { CatalogListItemInterface } from '../../interfaces/CatalogInterface'
import ErrorBoundary from '../ErrorBoundary'
import { ResultsComponentProps } from '../Pagination'

type Props<T> = ResultsComponentProps<CatalogListItemInterface> & {
  isLoading?: boolean
  viewMode: ViewMode
}

const CatalogList = <T extends object>(props: Props<T>) => {
  const { items, children, isLoading, viewMode } = props
  const content = items && items.map((item, idx) => <CatalogListItem item={item} key={item?.id || idx} viewMode={viewMode} />)
  if (items && items.length === 0 && !isLoading) {
    return (
      <ErrorBoundary>
        <CatalogNoProducts />
      </ErrorBoundary>
    )
  }
  return (
    <ErrorBoundary>
      <div className={styles['catalog__container']}>
        {children!}
        {content}
      </div>
    </ErrorBoundary>
  )
}

export default CatalogList
