import React, { FC } from 'react'
import AppContext, { ContextProps } from '../../context/AppContext'
import ChildrenType from '../../interfaces/ChildrenInterface'

type Props = ContextProps & {
  children: ChildrenType
}

const AppProvider: FC<Props> = ({ children, redirect, subscribers }) => {
  return <AppContext.Provider value={{ redirect, subscribers }}>{children}</AppContext.Provider>
}

export default AppProvider
