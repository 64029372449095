import React from 'react'
import ChildrenType from '../../../interfaces/ChildrenInterface'
import Link from '../../Link'

const defaultProps = Object.freeze({
  ariaCurrent: 'page' as 'page' | 'step' | 'location' | 'date' | 'time' | 'true',
})

type Classes = {
  root?: string
}

type Props = typeof defaultProps & {
  classes?: Classes
  style?: React.CSSProperties
  to: string | object
  onClick?: () => void
  children: ChildrenType
}

const NavigationLink = (props: Props) => {
  const { classes, to, onClick, ariaCurrent, style, children } = props
  return (
    <Link aria-current={ariaCurrent!} className={classes?.root} style={style} to={to} onClick={onClick}>
      {children}
    </Link>
  )
}

NavigationLink.defaultProps = defaultProps

export default NavigationLink
