import _ from 'lodash'
import { useEffect, useState } from 'react'
import { BreakpointsType, MEDIA_MODE, StateType } from './matchMedia.types'

const createMatchMedia = (query: string, alias: string) => {
  return {
    mediaQuery: window.matchMedia(query),
    alias,
  }
}

export const breakpoints: BreakpointsType = {
  [MEDIA_MODE.IS_MOBILE]: createMatchMedia('(max-width: 500px)', MEDIA_MODE.IS_MOBILE),
  [MEDIA_MODE.IS_SMALL_TABLET]: createMatchMedia('(max-width: 769px) and (min-width: 501px)', MEDIA_MODE.IS_SMALL_TABLET),
  [MEDIA_MODE.IS_TABLET]: createMatchMedia('(max-width: 1024px) and (min-width: 701px)', MEDIA_MODE.IS_TABLET),
  [MEDIA_MODE.IS_DESKTOP]: createMatchMedia('(min-width: 1025px)', MEDIA_MODE.IS_DESKTOP),
}

const useMatchMedia = () => {
  const [matches, setMatches] = useState<StateType | null>(null)

  const handleResize = (mediaQuery: MediaQueryList, event: MediaQueryListEvent) => {
    let matchedBreakpoint
    const matchesCopy = {}

    for (const key in breakpoints) {
      const item = breakpoints[key]
      if (item.mediaQuery.media === mediaQuery.media) {
        matchedBreakpoint = item
        break
      }
    }

    for (const key in breakpoints) {
      const item = breakpoints[key]
      //@ts-ignore TODO: fix it
      matchesCopy[item.alias] = window.matchMedia(item.mediaQuery.media).matches
    }

    if (matchedBreakpoint) {
      //@ts-ignore TODO: fix it
      matchesCopy[matchedBreakpoint.alias] = mediaQuery.matches
      setMatches(matchesCopy)
    }
  }

  useEffect(() => {
    const matchesCopy = _.cloneDeep(matches) || {}
    for (const key in breakpoints) {
      const item = breakpoints[key]
      matchesCopy[item.alias] = window.matchMedia(item.mediaQuery.media).matches
      //@ts-ignore - ugly, no idea at the moment
      item.mediaQuery.addListener(handleResize)
    }
    if (!matches) {
      setMatches(matchesCopy)
    }

    return () => {
      for (const key in breakpoints) {
        const item = breakpoints[key]
        //@ts-ignore - ugly, no idea at the moment
        breakpoints[item.alias].mediaQuery.removeListener(handleResize)
      }
    }
  })

  return {
    isMobile: (matches && matches[breakpoints[MEDIA_MODE.IS_MOBILE].alias]!) || false,
    isSmallTablet: (matches && matches[breakpoints[MEDIA_MODE.IS_SMALL_TABLET].alias]!) || false,
    isTablet: (matches && matches[breakpoints[MEDIA_MODE.IS_TABLET].alias]!) || false,
    isDesktop: (matches && matches[breakpoints[MEDIA_MODE.IS_DESKTOP].alias]!) || false,
  }
}

export default useMatchMedia
