import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { Fab } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import useMatchMedia from '../../../../hooks/matchMedia'
import { CatalogListProductInterface } from '../../../../interfaces/CatalogInterface'
import { State } from '../../../../services/reducers'
import { getProductPrice } from '../../../../utils/catalog'
import { getFullProductName, getProductEvent, getProductFeatures } from '../../../../utils/product'
import { _at } from '../../../../utils/translations'
import Link from '../../../Link'
import Photo from '../../../Photo'
import Price from '../../../Price'
import QuickCartAdd from '../../../QuickCartAdd'
import { ViewMode } from '../../CatalogViewSwitch/CatalogViewSwitch'
import styles from '../CatalogListItem.module.scss'

interface Props {
  item?: CatalogListProductInterface
  viewMode?: ViewMode
  onLinkClick?: () => void
  classes?: {
    root?: string
    img?: string
    imgLink?: string
  }
}

enum QUICK_ADD_STATE_ENUM {
  HIDDEN = 0,
  FAB_ONLY = 1,
  EXTENDED = 2,
}

const CatalogListProduct: React.FC<Props> = (props: Props) => {
  const { isDesktop, isMobile } = useMatchMedia()
  const [quickAdd, setQuickAdd] = React.useState<QUICK_ADD_STATE_ENUM>(
    isDesktop ? QUICK_ADD_STATE_ENUM.HIDDEN : QUICK_ADD_STATE_ENUM.FAB_ONLY,
  )
  const item = _.cloneDeep(props.item)
  const currency = useSelector((state: State) => state.locale.currency)
  const images = item?.photos || item?.description?.photos
  const printName = _at('printName', item?.translations!)
  // eslint-disable-next-line no-nested-ternary
  const prices = !_.isEmpty(item?.pricing) ? item?.pricing : item?.variants ? item?.variants[0].pricing : []
  const price = getProductPrice(prices!, currency.isoCode)
  const features = item ? getProductFeatures(item).slice(0, 2) : []
  const event = item ? getProductEvent(item) : null

  React.useEffect(() => {
    setQuickAdd(isDesktop ? QUICK_ADD_STATE_ENUM.HIDDEN : QUICK_ADD_STATE_ENUM.FAB_ONLY)
  }, [isDesktop])

  const containerClasses = classNames(styles['catalog-item'], props.classes?.root!, {
    [styles['catalog-item--double-column']]: isMobile && props.viewMode === ViewMode.DOUBLE_COLUMNS,
  })

  const imgLinkClasses = classNames(styles['catalog-item__img-link'], props.classes?.imgLink, {
    [styles['catalog-item--double-column__img-link']]: isMobile && props.viewMode === ViewMode.DOUBLE_COLUMNS,
  })

  const imgClasses = classNames(styles['catalog-item__img'], props.classes?.img, {
    [styles['catalog-item--double-column__img']]: isMobile && props.viewMode === ViewMode.DOUBLE_COLUMNS,
  })

  const featuresClasses = classNames(styles['catalog-item__features'], {
    [styles['catalog-item__features--hidden']]: isMobile && props.viewMode === ViewMode.DOUBLE_COLUMNS,
  })

  return (
    <div
      className={containerClasses}
      key={item?.id}
      onMouseLeave={() => setQuickAdd(isDesktop ? QUICK_ADD_STATE_ENUM.HIDDEN : QUICK_ADD_STATE_ENUM.FAB_ONLY)}
      onMouseEnter={() => setQuickAdd(QUICK_ADD_STATE_ENUM.FAB_ONLY)}
    >
      <div className={styles['catalog-item__img-container']}>
        {item?.id && (quickAdd == QUICK_ADD_STATE_ENUM.FAB_ONLY || quickAdd == QUICK_ADD_STATE_ENUM.EXTENDED) ? (
          <Fab
            color="primary"
            aria-label="Add to cart"
            classes={{ root: styles['item__quick-add'] }}
            onTouchStart={() => {
              setQuickAdd(quickAdd === QUICK_ADD_STATE_ENUM.EXTENDED ? QUICK_ADD_STATE_ENUM.FAB_ONLY : QUICK_ADD_STATE_ENUM.EXTENDED)
            }}
            onMouseEnter={() => setQuickAdd(QUICK_ADD_STATE_ENUM.EXTENDED)}
          >
            <AddShoppingCartIcon />
          </Fab>
        ) : null}
        <Link to={item?.to} className={imgLinkClasses} onClick={props.onLinkClick}>
          <Photo src={images!} type="catalog" secondSrc={images!} classes={imgClasses} alt={item ? getFullProductName(item) : ''} noEmpty />
        </Link>
        {price.percentDiscountAmount ? (
          <div aria-label="Discount" className={styles['item__quick-discount']}>
            -{price.percentDiscountAmount}%
          </div>
        ) : null}
        {item?.id ? <QuickCartAdd item={item} visible={quickAdd === QUICK_ADD_STATE_ENUM.EXTENDED} viewMode={props.viewMode} /> : null}
        {event ? <div className={styles['catalog-item__event']}>{event.stringValue}</div> : null}
      </div>

      {item?.id ? (
        <Link to={item?.to} classes={{ root: styles['catalog-item__info'] }} onClick={props.onLinkClick}>
          <p className={styles['catalog-item__print-name']}>{printName}</p>
          <p className={styles['catalog-item__name']}>
            <span>{item?.name}</span>
          </p>
          <Price
            saleValue={price.salePriceGross}
            value={price.grossPrice}
            currency={price.currency}
            classes={{
              sale: styles['catalog-item__sale'],
              oldValue: styles['catalog-item__old-price'],
            }}
          />
          {features.length > 0 ? (
            <div className={featuresClasses}>
              {features.map(feature => (
                <div key={`product-${item.id}-feature-${feature.id}`} className={styles['features__feature']}>
                  {feature.stringValue}
                </div>
              ))}
            </div>
          ) : null}
        </Link>
      ) : (
        <Skeleton variant="rectangular" classes={{ root: styles['catalog-item__info'] }} animation="wave" />
      )}
    </div>
  )
}

export default CatalogListProduct
