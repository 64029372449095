import React from 'react'
import styles from './HeaderMessage.module.scss'
import useChannelConfig from '../../hooks/channelConfig/channelConfig'

const HeaderMessage = () => {
  const channelConfig = useChannelConfig()

  return <span className={styles['top-message']}>{channelConfig.current.headerTextLeft}</span>
}

export default HeaderMessage
