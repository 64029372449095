import { createLocation, Location } from 'history'

export const hasBasename = (path: string, prefix: string) => {
  return new RegExp('^' + prefix + '(\\/|\\?|#|$)', 'i').test(path)
}

export const stripBasename = (path: string, prefix: string) => {
  return hasBasename(path, prefix) ? path.substr(prefix.length) : path
}

export const stripTrailingSlash = (path: string) => {
  return path.charAt(path.length - 1) === '/' ? path.slice(0, -1) : path
}

export const addLeadingSlash = (path: string) => {
  return path.charAt(0) === '/' ? path : '/' + path
}

export const parsePath = (path: string): Location => {
  let pathname = path || '/'
  let search = ''
  let hash = ''

  const hashIndex = pathname.indexOf('#')
  if (hashIndex !== -1) {
    hash = pathname.substr(hashIndex)
    pathname = pathname.substr(0, hashIndex)
  }

  const searchIndex = pathname.indexOf('?')
  if (searchIndex !== -1) {
    search = pathname.substr(searchIndex)
    pathname = pathname.substr(0, searchIndex)
  }

  return createLocation(pathname, undefined, '', undefined)
}

export const createPath = (location: Location) => {
  const { pathname, search, hash } = location
  let path = pathname || '/'

  if (search && typeof search === 'string' && search !== '?') {
    path += search.charAt(0) === '?' ? search : `?${search}`
  }

  if (hash && typeof hash === 'string' && hash !== '#') {
    path += hash.charAt(0) === '#' ? hash : `#${hash}`
  }

  return path
}

export const parseQueryString = (query: string) => {
  if (!query) {
    return {}
  }

  return (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
    const [key, value] = param.split('=')
    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
    return params
  }, {} as { [key: string]: any });
}
