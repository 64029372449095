import ls from '@livesession/sdk'
import { createLocation, Location } from 'history'
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import LinkAnchor from './LinkAnchor'
import useAppEvents from '../../hooks/events/events'
import { AppEventsEnum } from '../../hooks/events/events.types'
import ChannelInterface from '../../interfaces/ChannelInterface'
import { State } from '../../services/reducers'
import { fixPath } from '../../utils/path'
import { createPath } from '../ChannelProvider/utils/path-utils'

type ToType = Location | string
type ResolveToType = Location | string | ((to: ToType) => ToType)

type Props = any & {
  to: ToType
}

export const resolveToLocation = (to: ResolveToType, currentLocation: ToType): ToType => {
  return typeof to === 'function' ? to(currentLocation) : to
}

export const normalizeToLocation = (to: ToType, currentLocation: Location): Location => {
  return typeof to === 'string' ? createLocation(to, undefined, undefined, currentLocation) : to
}

const fixTo = (to: ToType, channel?: ChannelInterface): ToType => {
  if (typeof to === 'string') {
    to = fixPath(to.replace(channel?.path || '', ''))
  } else {
    to.pathname = fixPath(to.pathname.replace(channel?.path || '', ''))
  }

  return to
}

const ChannelLink = (props: Props) => {
  const { component = LinkAnchor, replace, to: inTo, ...rest } = props
  const { history } = rest
  const to = normalizeToLocation(inTo, rest.location)
  const toPath = createPath(to)
  const _channels = useSelector((store: State) => store.global.channels)
  const channels = [_channels.alternate, _channels.current]
  const channel = channels.find(item => rest.code === item.code)
  const href = fixPath(channel !== undefined ? _.trim(channel.path + toPath, '/') : _.trim(toPath, '/'))
  const { triggerEvent } = useAppEvents()

  return React.createElement(component, {
    ..._.omit(rest, ['staticContext']),
    href,
    navigate() {
      triggerEvent(AppEventsEnum.SWITCH_CHANNEL, channel)
      ls.track('shop switch', {
        target_str: channel?.theme!,
      })
      history.setChannelPath(channel != null ? _.trim(channel.path) : '/')
      const method = replace ? history.replace : history.push
      const fixedTo = fixTo(resolveToLocation(toPath, rest.location), channel)
      method(fixedTo)
    },
  })
}

export default withRouter(ChannelLink)
