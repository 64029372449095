import { defineMessages } from 'react-intl'

const messages = defineMessages({
  schemaFieldRequired: {
    id: 'Validation.FieldRequired',
    defaultMessage: 'This field is required.',
  },
  schemaMinChar: {
    id: 'Validation.MinChar',
    defaultMessage: 'Must contain {val} characters at least.',
  },
  schemaMaxChar: {
    id: 'Validation.MaxChar',
    defaultMessage: 'Must contain less than {val} characters.',
  },
  schemaSelectValue: {
    id: 'Validation.MustSelectValue',
    defaultMessage: 'You must choose one of available options.',
  },
  schemaWrongEmail: {
    id: 'Validation.WrongEmailFormat',
    defaultMessage: 'You must enter correct e-mail address.',
  },
  schemaWrongDateFormat: {
    id: 'Validation.WrongDateFormat',
    defaultMessage: 'You must enter correct date.',
  },
  schemaWrongMinDateRangeFormat: {
    id: 'Validation.WrongMinDateRangeFormat',
    defaultMessage: 'This value should be {val} or more.',
  },
  schemaWrongMaxDateRangeFormat: {
    id: 'Validation.WrongMaxDateRangeFormat',
    defaultMessage: 'This value cannot point to future.',
  },
  schemaWrongCountry: {
    id: 'Validation.WrongCountry',
    defaultMessage: 'You must enter correct country name.',
  },
  schemaWrongState: {
    id: 'Validation.WrongState',
    defaultMessage: 'You must enter correct state name.',
  },
  schemaPasswordsNotMatch: {
    id: 'Validation.PasswordsNotMatch',
    defaultMessage: 'Passwords not match.',
  },
  schemaTermsNotAccepted: {
    id: 'Validation.TermsNotAccepted',
    defaultMessage: 'You must accept the Terms of Service.',
  },
  schemaWrongPostCode: {
    id: 'Validation.WrongPostCode',
    defaultMessage: 'Wrong format.',
  },
  schemaEmailsNotMatch: {
    id: 'Validation.EmailsNotMatch',
    defaultMessage: 'Repeated e-mail must match the first one.',
  },
})

export default messages
