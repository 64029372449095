import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { cartDeleteSuccess, cartReadRequest, cartReadSuccess } from './cart.actions'
import * as api from './cart.api'
import { CartDeleteRequestInterface, CartProductUpdateRequestInterface, CartReadRequestInterface, types } from './cart.types'

export function* cartReadRequestEvent(): any {
  const cartResponse = yield call(api.readCart)
  yield all([put(cartReadSuccess(cartResponse.data))])
}

function* cartReadRequestEventListener() {
  yield takeLatest(types.UPDATE_REQUEST, cartReadRequestEvent)
}

export function* cartRemoveRequestEvent(action: CartDeleteRequestInterface) {
  yield all([put(cartDeleteSuccess()), put(cartReadRequest())])
}

function* cartRemoveRequestEventListener() {
  yield takeEvery(types.DELETE_REQUEST, cartRemoveRequestEvent)
}
export default function* cartSaga() {
  yield all([fork(cartReadRequestEventListener), fork(cartRemoveRequestEventListener)])
}
