import PrivacyConsentInterface from '../interfaces/PrivacyConsentInterface'

const STORAGE_PRIVACY_CONSENTS = 'STORAGE_PRIVACY_CONSENTS'

export const getPrivacyConsents = (): PrivacyConsentInterface | null => {
  const consents = localStorage.getItem(STORAGE_PRIVACY_CONSENTS)
  return consents ? { ...JSON.parse(consents), required: true } : null
}

export const savePrivacyConsents = (consents: PrivacyConsentInterface) => {
  localStorage.setItem(STORAGE_PRIVACY_CONSENTS, JSON.stringify(consents))
}
