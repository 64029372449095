import _ from 'lodash'
import { createIntl, createIntlCache, IntlShape } from 'react-intl'
import { loadLocaleData } from './localeData'
import {
  TranslationInterface as CMSTranslationInterface,
  TranslationModule,
  TranslationStatus,
  TranslationType,
} from '../interfaces/CMSPageInterface'
import { TranslationInterface, TranslationsMapInterface } from '../interfaces/TranslationInterface'
import store from '../services/store'

/* eslint-disable import/no-mutable-exports */
export const FALLBACK_LANG = 'en-AU'

const langCode: string = store?.getState().locale.language.isoCode || FALLBACK_LANG
let messages: Record<string, string> = {}
const intlCache = createIntlCache()
let intl: IntlShape | undefined

async function bootstrap(locale: string) {
  messages = await loadLocaleData(locale)
  intl = createIntl({ locale, messages }, intlCache)
}

bootstrap(langCode)

/** Get translation from translations array by country lang **/
function _at(key: string | false, translationArray: TranslationsMapInterface, countryLang: string | false = false) {
  if (_.isEmpty(translationArray) || !translationArray) {
    return ''
  }

  if (!countryLang) {
    countryLang = store?.getState().locale.language.isoCode || FALLBACK_LANG
  }

  if (!_.has(translationArray, countryLang)) {
    countryLang = store?.getState().global.fallbackLanguageIsoCode || FALLBACK_LANG
  }

  if (typeof key !== 'string') {
    return _.has(translationArray, countryLang) ? (translationArray[countryLang] as unknown as string) : ''
  }

  // if (key && !_.has(translationArray[countryLang], key)) {
  //   return ''
  // }

  if (typeof key == 'string') return _.get(translationArray[countryLang], key, '') as string

  return ''
}

export const _cms = (
  translations: CMSTranslationInterface[] | undefined,
  module: TranslationModule,
  type: TranslationType,
  language?: string | undefined,
  active: boolean = true,
  noFallback: boolean = false,
) => {
  if (!translations) {
    console.warn('No translations array provided')
    return ''
  }

  if (!language) {
    language = store?.getState().locale.language.isoCode || FALLBACK_LANG
  }

  const status = active ? TranslationStatus.ACTIVE : TranslationStatus.INACTIVE

  let translation = translations.find(i => i.module === module && i.type === type && i.language === language && i.status === status)

  if (!translation && !noFallback) {
    translation = translations.find(i => i.module === module && i.type === type && i.language === FALLBACK_LANG && i.status === status)
  }

  if (!translation) {
    //no target language translation, no en-AU (fallback) translations, get the first one
    translation = translations.find(i => i.module === module && i.type === type && i.status === status)
  }

  return translation?.content || ''
}

export { intl, _at }
export default messages
