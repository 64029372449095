import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { Button } from '@mui/material'
import classNames from 'classnames'
import React, { useContext } from 'react'
import UpSellContext from '../../context/UpSellContext'
import useCart from '../../hooks/cart/cart'
import useClubContext from '../../hooks/club/clubContext'
import useAppEvents from '../../hooks/events/events'
import { AppEventsEnum } from '../../hooks/events/events.types'
import useProduct from '../../hooks/product/product'
import useSizeGuide from '../../hooks/sizeGuide/sizeGuide'
import { ProductInterface, SIZE_PRESET_NONE } from '../../interfaces/ProductInterface'
import LoadingSpinner, { Sizes } from '../LoadingSpinner'
import styles from './QuickCartAdd.module.scss'

type Props = {
  item: ProductInterface
  product: ProductInterface
}

export const QuickCartAddItem = (props: Props) => {
  const { item, product } = props
  const [disabled, setDisabled] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const { getAvailability } = useProduct()
  const { addProduct, getProductCartQty, cart } = useCart()
  const { triggerEvent } = useAppEvents()
  const upSell = useContext(UpSellContext)
  const sizeGuide = useSizeGuide()
  const clubContext = useClubContext()

  const getAvailablity = React.useCallback(() => {
    const av = getAvailability(item)
    if (!av) return 0
    return av.available
  }, [item.id])

  React.useEffect(() => {
    if (getAvailablity() === 0 && !clubContext.isInClubFlow) {
      setDisabled(true)
    }
  }, [clubContext.isInClubFlow])

  const addToCart = React.useCallback(() => {
    if (loading || disabled) return null
    setLoading(true)
    addProduct(
      {
        productId: item.id,
        quantity: getProductCartQty(item.id) + 1,
      },
      () => {
        setLoading(false)
        triggerEvent(AppEventsEnum.ADJUST_CART, { adjQty: 1, currency: cart.currency, product, variantId: item.id })
        upSell.handleOpen(product.id)
      },
      () => {
        setLoading(false)
      },
      false,
      true,
    )
  }, [item.id, disabled, loading, JSON.stringify(cart.items), product.id])

  const classes = classNames(styles['items__item'], {
    [styles['items__item--disabled']]: disabled,
  })
  const content =
    item.size && item.size.preset !== SIZE_PRESET_NONE ? sizeGuide.getSizeLabel(item.size.id) || item.size.label : <AddShoppingCartIcon />
  return (
    <Button classes={{ root: classes }} key={item.id} onClick={addToCart} disabled={loading}>
      {loading ? <LoadingSpinner size={Sizes.BUTTON} /> : content}
    </Button>
  )
}

export default QuickCartAddItem
