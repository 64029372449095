import _ from 'lodash'
import { Action as ReduxAction, applyMiddleware, compose, createStore, Dispatch as ReduxDispatch, Store as ReduxStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducers, { State } from './reducers'
import sagas from './sagas'
import isDevMode from '../utils/isDevMode'

type Store = ReduxStore<State, ReduxAction> & {
  dispatch: ReduxDispatch
}

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers =
  typeof window === 'object' && isDevMode && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const store: Store = createStore(reducers, composeEnhancers(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(sagas)
store.subscribe(() => {})
export default store
