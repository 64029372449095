import _ from 'lodash'
import { ActionTypes, LocaleInterface, types } from './locale.types'

export interface StateInterface extends LocaleInterface {}

const initialState = <StateInterface>{
  currencies: {},
  languages: {},
  language: {},
  currency: {},
  addressFormats: {},
}

export default function localeReducer(state = initialState, action: ActionTypes) {
  switch (action.type) {
    case types.READ_ADDRESS_FORMATS_SUCCESS: {
      const newState = _.cloneDeep(state)
      newState.addressFormats = _.cloneDeep(action.payload)
      return newState
    }

    case types.READ_CONFIG_SUCCESS: {
      const newState = _.cloneDeep(action.payload)
      newState.addressFormats = state.addressFormats
      return _.cloneDeep(newState)
    }

    case types.UPDATE_LANGUAGE_SUCCESS: {
      const newState = _.cloneDeep(state)
      newState.addressFormats = state.addressFormats
      newState.language = _.cloneDeep(action.payload)
      return newState
    }

    case types.UPDATE_CURRENCY_SUCCESS: {
      const newState = _.cloneDeep(state)
      newState.addressFormats = state.addressFormats
      newState.currency = _.cloneDeep(action.payload)
      return newState
    }

    default: {
      return state
    }
  }

  return state
}
