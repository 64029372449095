import { Slide, Snackbar } from '@mui/material'
import { SlideProps } from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Notification from './Notification'
import styles from './Notification.module.scss'
import * as notificationStates from './notificationStates'
import notify from './notify'
import { NotificationInterface } from '../../services/notification/notification.types'
import ErrorBoundary from '../ErrorBoundary'
/* eslint-disable react/jsx-no-bind */

const DIRECTIONS = {
  top: 'up',
  bottom: 'down',
  left: 'left',
  right: 'right',
}

type SlideTransitionProps = TransitionProps & { children: React.ReactElement<any, any> }

interface Props {
  messages: NotificationInterface[]
  newestOnTop?: boolean
  direction: string
  autoHideDuration?: number
}

export type OnClose = (id: number, event: Event | React.SyntheticEvent<any, Event>, reason: string) => void

function unmount() {
  notify.dismiss()
}

function remove(id: number) {
  notify.dismiss(id)
}

function renderNotification(item: NotificationInterface, props: Props, onClose: OnClose) {
  const { id, type, content, state } = item
  const { direction, autoHideDuration } = props
  const open = state === notificationStates.SHOWN
  const snackbarId = `snakcbar-${id}`
  const snackbarClasses = {
    root: styles['snackbar'],
    anchorOriginBottomCenter: styles['snackbar__bottom-center'],
  }

  const SlideTransition: React.ComponentType<SlideTransitionProps> = (_props: SlideTransitionProps) => {
    const direct = DIRECTIONS[direction as keyof typeof DIRECTIONS]
    return (
      //@ts-ignore
      <Slide {..._props} direction={direct} mountOnEnter unmountOnExit>
        {_props.children}
      </Slide>
    )
  }

  return (
    <React.Fragment key={snackbarId}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        id={snackbarId}
        classes={snackbarClasses}
        open={open}
        onClose={onClose.bind(null, id)}
        autoHideDuration={autoHideDuration}
        TransitionComponent={SlideTransition}
        TransitionProps={{
          in: open,
          onExited: remove.bind(null, id),
        }}
      >
        <Notification id={id} className={styles['notification']} onClose={onClose.bind(null, id)} type={type} content={content} />
      </Snackbar>
    </React.Fragment>
  )
}

const NotificationContainer = (props: Props) => {
  const [notifications, setNotifications] = useState([] as NotificationInterface[])
  useEffect(() => {
    if (!_.isEqual(notifications, props.messages)) {
      const newNotifications: NotificationInterface[] = []
      for (const message of props.messages) {
        const notification: NotificationInterface | undefined = notifications.find((item: NotificationInterface) => item.id === message.id)

        if (notification !== undefined) {
          newNotifications.push({ ...notification })
        } else {
          newNotifications.push({
            ...message,
            state: notificationStates.SHOWN,
          })
        }
      }
      setNotifications(newNotifications)
    }
  }, [props.messages])

  useEffect(() => {
    return unmount
  }, [])

  function onClose(id: number, event: Event | React.SyntheticEvent<any, Event>, reason: string) {
    if (reason === 'clickaway') {
      return
    }

    const updatedNotifications: NotificationInterface[] = _.cloneDeep(notifications)
    const index = notifications.findIndex((item: NotificationInterface) => item.id === id)
    if (index >= 0) {
      updatedNotifications[index].state = notificationStates.CLOSING
    }
    setNotifications(updatedNotifications)
  }

  const classes = classNames(styles['notification-container'], {
    [styles['notification-container--reversed']]: !props.newestOnTop,
  })

  const renderedNotification = notifications
    .filter((item: NotificationInterface) => item.state === notificationStates.SHOWN || item.state === notificationStates.CLOSING)
    .map((item: NotificationInterface) => renderNotification(item, props, onClose))

  return (
    <ErrorBoundary>
      <div className={classes}>{renderedNotification}</div>
    </ErrorBoundary>
  )
}

const NotificationContainerMemoized = React.memo(NotificationContainer, (prevProps: Props, nextProps: Props) => {
  const currMessages = prevProps.messages
  const nextMessages = nextProps.messages
  let isEqual = currMessages.length === nextMessages.length

  if (isEqual) {
    for (const nextMessage of nextMessages) {
      const currMessage = currMessages.find(item => item.id === nextMessage.id)
      isEqual = currMessage !== undefined && nextMessage.state == currMessage.state

      if (!isEqual) {
        break
      }
    }
  }

  return isEqual
})

export default NotificationContainerMemoized
