import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import EventIcon from '@mui/icons-material/Event'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import '@mui/lab'
import { Button, IconButton } from '@mui/material'
import { FormControlProps } from '@mui/material/FormControl'
import { TextFieldProps } from '@mui/material/TextField'
import {
  DatePicker,
  DatePickerToolbar,
  DatePickerToolbarProps,
  LocalizationProvider,
  PickersActionBarProps,
  pickersLayoutClasses,
  PickersLayoutContentWrapper,
  PickersLayoutProps,
  PickersLayoutRoot,
  usePickerLayout,
} from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateField as MuiDateField } from '@mui/x-date-pickers/DateField'
import { DateView } from '@mui/x-date-pickers/models'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import styles from './DateField.module.scss'
import { FormikBag } from '../../utils/formikBag'
import FormattedMessage from '../FormattedMessage'
import TextField from '../TextField'

type InputFieldProps = TextFieldProps & {
  label?: string
  error: boolean
  helperText: any
}

type Props = {
  languageIsoCode: string
  formControlProps?: FormControlProps
  pickerProps?: Object
  dateFormat?: string
  form: FormikBag
  textFieldProps: InputFieldProps
}

const CustomActionBar = (props: PickersActionBarProps) => {
  return (
    <div className={styles['actions']}>
      {props.actions?.includes('cancel') ? (
        <Button variant="text" color="info" onClick={props.onCancel}>
          <FormattedMessage id="DateField.Calendar.Cancel" description="DateField.Calendar.Cancel" defaultMessage="Cancel" />
        </Button>
      ) : null}
      {props.actions?.includes('clear') ? (
        <Button variant="text" color="info" onClick={props.onClear}>
          <FormattedMessage id="DateField.Calendar.Clear" description="DateField.Calendar.Clear" defaultMessage="Clear" />
        </Button>
      ) : null}
      {props.actions?.includes('accept') ? (
        <Button variant="text" color="primary" onClick={props.onAccept}>
          <FormattedMessage id="DateField.Calendar.Save" description="DateField.Calendar.Save" defaultMessage="Save" />
        </Button>
      ) : null}
    </div>
  )
}

const CustomLayout = (props: PickersLayoutProps<moment.Moment | null, moment.Moment, DateView>) => {
  const { value, onChange } = props
  const [showKeyboardView, setShowKeyboardView] = React.useState(false)

  const { toolbar, tabs, content, actionBar } = usePickerLayout({
    ...props,
    slotProps: {
      ...props.slotProps,
      toolbar: {
        ...props.slotProps?.toolbar,
        showKeyboardViewSwitch: props.wrapperVariant === 'mobile',
        showKeyboardView,
        setShowKeyboardView,
      } as any,
    },
  })

  const keyboardViewClasses = classNames(styles['layout__keyboard-view'], {
    [styles['layout__keyboard-view--landscape']]: props.isLandscape,
    [styles['layout__keyboard-view--portrait']]: !props.isLandscape,
  })

  return (
    <PickersLayoutRoot ownerState={props}>
      {toolbar}
      {actionBar}
      <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
        {tabs}
        {showKeyboardView ? (
          <div className={keyboardViewClasses}>
            <MuiDateField value={value} onChange={onChange} className={styles['keyboard-view__date-field']} />
          </div>
        ) : (
          content
        )}
      </PickersLayoutContentWrapper>
    </PickersLayoutRoot>
  )
}

const CustomToolbar = (
  props: DatePickerToolbarProps<any> & {
    showKeyboardViewSwitch?: boolean
    showKeyboardView?: boolean
    setShowKeyboardView?: React.Dispatch<React.SetStateAction<boolean>>
  },
) => {
  const { showKeyboardViewSwitch, showKeyboardView, setShowKeyboardView, ...baseProps } = props

  const containerClasses = classNames(styles['toolbar'], {
    [styles['toolbar--landscape']]: baseProps.isLandscape,
    [styles['toolbar--portrait']]: !baseProps.isLandscape,
  })

  const buttonClasses = classNames({
    [styles['toolbar__button--landscape']]: baseProps.isLandscape,
    [styles['toolbar__button--portrait']]: !baseProps.isLandscape,
  })

  if (showKeyboardViewSwitch) {
    return (
      <div className={containerClasses}>
        <DatePickerToolbar {...baseProps} className={styles['toolbar__base']} />
        <div>
          <IconButton color="inherit" onClick={() => setShowKeyboardView!(prev => !prev)} className={buttonClasses}>
            {showKeyboardView ? <CalendarMonthIcon /> : <ModeEditIcon />}
          </IconButton>
        </div>
      </div>
    )
  }

  return <DatePickerToolbar {...baseProps} />
}

const DateField: React.FC<Props> = (props: Props) => {
  const moment = new AdapterMoment({ locale: props.languageIsoCode.toLocaleLowerCase() })
  const localMomentFormat = moment.moment.localeData().longDateFormat('L')
  const initValueIsValid = moment.moment(props.textFieldProps.value as string, localMomentFormat).isValid()
  const defaultDateValue = initValueIsValid ? moment.moment(props.textFieldProps.value as string, localMomentFormat) : null

  const [dateFormat, setDateFormat] = useState<string>(props.dateFormat || localMomentFormat)
  const [dateValue, setDateValue] = useState<moment.Moment | null>(defaultDateValue)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  function handleOnChange(date: moment.Moment | null) {
    let formattedDate: string = ''
    if (date !== null) {
      formattedDate = date.format(dateFormat).toString()
    }
    props.form.setFieldValue(props.textFieldProps.name as string, formattedDate)
    props.form.setFieldTouched(props.textFieldProps.name as string)
    setDateValue(date)
  }

  useEffect(() => {
    moment.moment.locale(props.languageIsoCode.toLocaleLowerCase())
    setDateFormat(props.dateFormat || moment.moment.localeData().longDateFormat('L'))
  }, [props.languageIsoCode])

  useEffect(() => {
    if (dateValue) {
      const dateValueFormatted = dateValue.format(dateFormat).toString()
      props.form.setFieldValue(props.textFieldProps.name as string, dateValueFormatted)
    }
  }, [dateValue, props.languageIsoCode])

  const extraProps = props.pickerProps || {}

  return (
    <LocalizationProvider dateLibInstance={moment.moment} dateAdapter={AdapterMoment} adapterLocale={props.languageIsoCode}>
      <DatePicker
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        label={props.textFieldProps.label}
        onChange={handleOnChange}
        {...extraProps}
        format={dateFormat}
        value={dateValue}
        localeText={{
          toolbarTitle: props.textFieldProps.label,
        }}
        slotProps={{
          actionBar: ({ wrapperVariant }) => ({
            actions: wrapperVariant === 'desktop' ? ['clear'] : ['cancel', 'clear', 'accept'],
          }),
          textField: {
            id: props.textFieldProps.name,
            name: props.textFieldProps.name,
            helperText: props.textFieldProps.helperText,
            error: props.textFieldProps.error,
            onBlur: () => props.form.setFieldTouched(props.textFieldProps.name!, true, false),
            InputProps: {
              endAdornment: (
                <IconButton classes={{ root: styles['calendar__button'] }} onClick={handleOpen} size="large">
                  <EventIcon />
                </IconButton>
              ),
            },
            formControlProps: props.formControlProps,
            // At the current moment there is no options to modify what type props should have
            // it is currently marked as an enhancement in issue on github: https://github.com/mui/mui-x/issues/9775
          } as any,
        }}
        slots={{
          actionBar: CustomActionBar,
          textField: TextField,
          layout: CustomLayout,
          toolbar: CustomToolbar,
        }}
      />
    </LocalizationProvider>
  )
}

export default DateField
