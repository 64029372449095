type MatchMediaType = {
  mediaQuery: MediaQueryList
  alias: string
}

export type StateType = {
  [alias: string]: boolean
}

export type BreakpointsType = {
  [alias: string]: MatchMediaType
}

export enum MEDIA_MODE {
  IS_MOBILE = 'isMobile',
  IS_SMALL_TABLET = 'isSmallTablet',
  IS_TABLET = 'isTablet',
  IS_DESKTOP = 'isDesktop',
}
