import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import classNames from 'classnames'
import React, { useState } from 'react'
import routes from '../../app/routes'
import ChannelContext from '../../context/ChannelContext'
import useChannelConfig from '../../hooks/channelConfig'
import useClubContext from '../../hooks/club/clubContext'
import AuthMenu from '../AuthMenu'
import HeaderMessage from '../HeaderMessage'
import Link from '../Link'
import SwitchPanel, { DISPLAY_MODE } from '../Localization/SwitchPanel'
import Menu from '../Menu'
import MiniCart from '../MiniCart'
import SearchModal from '../Search/SearchModal/SearchModal'
import ShopSwitcher from '../ShopSwitcher'
import styles from './AppHeader.module.scss'

type Props = {
  onHamburgerClick: () => void
}

const AppHeader: React.FC<Props> = props => {
  const [showSearchModal, setShowSearchModal] = useState(false)
  const cartRef = React.useRef<HTMLDivElement>(null)
  const menuRef = React.useRef<HTMLDivElement>(null)
  const megaMenuRef = React.useRef<HTMLDivElement>(null)
  const channel = React.useContext(ChannelContext).current
  const clubContext = useClubContext()
  const channelConfig = useChannelConfig()

  return (
    <header className={styles['header']}>
      <div className={styles['header__bar']}>
        <SwitchPanel type={DISPLAY_MODE.DESKTOP} />
        <div className={styles['bar__simple-message']}>{channelConfig.current.headerTextRight}</div>
        <ShopSwitcher classes={styles['bar__opposite-logo']} />
        <HeaderMessage />
      </div>
      <div className={styles['header__middle']}>
        {clubContext.isInClubFlow ? null : (
          <span className={styles['middle__hamburger']}>
            <MenuIcon classes={{ root: 'phone--visible tablet--small--visible tablet--visible' }} onClick={props.onHamburgerClick} />
          </span>
        )}
        <span className={styles['middle__logo']}>
          <Link to={routes.home}>{channel.frontLabel}</Link>
          {clubContext.isInClubFlow ? (
            <Link to={routes.home}>
              <span className={styles['club-logo']}>{clubContext.club?.name}</span>
            </Link>
          ) : null}
        </span>
        <div className={styles['middle__actions']}>
          <AuthMenu classes={{ link: 'desktop--visible' }} selfRef={menuRef} miniCartRef={cartRef} megaMenuRef={megaMenuRef} />
          <MiniCart selfRef={cartRef} menuRef={menuRef} />
          {clubContext.isInClubFlow && clubContext.isAuthenticated ? (
            <LogoutIcon
              classes={{ root: classNames('phone--visible tablet--small--visible tablet--visible', styles['logout-icon']) }}
              onClick={clubContext.logout}
            />
          ) : null}
          {clubContext.isInClubFlow ? null : (
            <SearchIcon
              classes={{ root: 'phone--visible tablet--small--visible tablet--visible' }}
              onClick={() => setShowSearchModal(true)}
            />
          )}
        </div>
      </div>
      {clubContext.isInClubFlow ? null : (
        <Menu type={DISPLAY_MODE.DESKTOP} channel={channel} classes={{ root: 'visible-pc' }} selfRef={megaMenuRef} />
      )}
      {clubContext.isInClubFlow && clubContext.club?.description ? (
        <div className={styles['club-description']} dangerouslySetInnerHTML={{ __html: clubContext.club.description }} />
      ) : null}
      <SearchModal open={showSearchModal} onClose={() => setShowSearchModal(false)} />
    </header>
  )
}

export default AppHeader
