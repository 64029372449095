import { defineMessages } from 'react-intl'

const messages = defineMessages({
  size: {
    id: 'Cart.Label.Size',
    defaultMessage: 'Size',
  },
  quantity: {
    id: 'Cart.Label.Quantity',
    defaultMessage: 'Quantity',
  },
  price: {
    id: 'Cart.Label.Price',
    defaultMessage: 'Total',
  },
})

export default messages
