import { CartInterface } from '../../interfaces/CartInterface'

export enum types {
  PRODUCT_ADD_REQUEST = 'CART_PRODUCT_ADD_REQUEST',
  PRODUCT_REMOVE_REQUEST = 'CART_PRODUCT_REMOVE_REQUEST',
  PRODUCT_QUANTITY_CHANGE_REQUEST = 'CART_PRODUCT_QUANTITY_CHANGE_REQUEST',
  UPDATE_REQUEST = 'CART_UPDATE_REQUEST',
  UPDATE_SUCCESS = 'CART_UPDATE_SUCCESS',
  DELETE_REQUEST = 'CART_DELETE_REQUEST',
  DELETE_SUCCESS = 'CART_DELETE_SUCCESS',
  SHIPPING_METHOD_UPDATE_REQUEST = 'CART_SHIPPING_METHOD_UPDATE_REQUEST',
  SHIPPING_METHOD_UPDATE_SUCCESS = 'CART_SHIPPING_METHOD_UPDATE_SUCCESS',
}

export interface CartProductPayloadInterface {
  productId: number
  quantity: number
  action?: string
}

export interface CartProductUpdateRequestInterface {
  type: types.PRODUCT_QUANTITY_CHANGE_REQUEST
  payload: CartProductPayloadInterface
}

export interface CartReadRequestInterface {
  type: types.UPDATE_REQUEST
}

export interface CartReadSuccessInterface {
  type: types.UPDATE_SUCCESS
  payload: CartInterface
}

export interface CartDeleteRequestInterface {
  type: types.DELETE_REQUEST
}

export interface CartDeleteSuccessInterface {
  type: types.DELETE_SUCCESS
}

export interface CartShippingMethodUpdateRequestInterface {
  type: types.SHIPPING_METHOD_UPDATE_REQUEST
}

export interface CartShippingMethodUpdateSuccessInterface {
  type: types.SHIPPING_METHOD_UPDATE_SUCCESS
}

export type ActionTypes =
  | CartProductUpdateRequestInterface
  | CartReadRequestInterface
  | CartReadSuccessInterface
  | CartDeleteRequestInterface
  | CartDeleteSuccessInterface
  | CartShippingMethodUpdateRequestInterface
  | CartShippingMethodUpdateSuccessInterface
