import _ from 'lodash'

type FbCallback = (response: fb.StatusResponse) => void

export const FacebookLogin = (successCallback: FbCallback | null = null, errorCallback: FbCallback | null = null) => {
  FB.login(
    function(response: fb.StatusResponse) {
      if (response.authResponse) {
        successCallback ? successCallback(response) : _.noop()
      } else {
        errorCallback ? errorCallback(response) : _.noop()
        console.log('FB Auth error', response)
      }
    },
    {
      scope: 'email',
    },
  )
}
