import CurrencyInterface from '../../interfaces/CurrencyInterface'
import LanguageInterface from '../../interfaces/LanguageInterface'
import axios from '../../utils/axios'

export function updateLanguage(language: LanguageInterface) {
  return axios({
    method: 'POST',
    url: '/config/locale.json',
    data: {
      locale: language.isoCode,
    },
  })
}

export function updateCurrency(currency: CurrencyInterface) {
  return axios({
    method: 'POST',
    url: '/config/locale.json',
    data: {
      currency: currency.isoCode,
    },
  })
}
