import React from 'react'
import CatalogListProduct from './CatalogListProduct'
import CatalogListSubcategory from './CatalogListSubcategory'
import { CatalogItemType, CatalogListItemInterface } from '../../../interfaces/CatalogInterface'
import ErrorBoundary from '../../ErrorBoundary'
import { ViewMode } from '../CatalogViewSwitch/CatalogViewSwitch'

interface Props {
  item?: CatalogListItemInterface
  viewMode?: ViewMode
  onProductLinkClick?: () => void
  classes?: {
    root?: string
    img?: string
    imgLink?: string
  }
}

const CatalogListItem = (props: Props) => {
  let content = null

  if (props.item?.itemType === CatalogItemType.Category) {
    content = <CatalogListSubcategory item={props.item} />
  } else {
    content = (
      <CatalogListProduct item={props.item} classes={props.classes} viewMode={props.viewMode} onLinkClick={props.onProductLinkClick} />
    )
  }

  return <ErrorBoundary>{content}</ErrorBoundary>
}

export default CatalogListItem
