import React from 'react'
import { ChannelInterface } from '../../interfaces/ChannelInterface'
import ChannelProvider from '../ChannelProvider'

type Props = {
  channels: ChannelInterface[]
}

const AppLayout = (props: Props) => {
  return <ChannelProvider channels={props.channels} />
}

export default AppLayout
