import classNames from 'classnames'
import React from 'react'
import styles from './SizeGuideContent.module.scss'
import useMatchMedia from '../../../hooks/matchMedia/matchMedia'
import useSizeGuide from '../../../hooks/sizeGuide/sizeGuide'
import { PresetInterface } from '../../../interfaces/SizeGuideInterface'
import Asset from '../../Asset/Asset'
import SizeGuideTable from '../SizeGuideTable/SizeGuideTable'

type Props = {
  preset: PresetInterface
}

const SizeGuideContent = (props: Props) => {
  const { preset } = props
  const { isTablet, isDesktop } = useMatchMedia()

  const sizeGuide = useSizeGuide()

  const sizesForType = sizeGuide.getSizesByPresetLabel(preset.label)
  // eslint-disable-next-line no-nested-ternary
  const columnCount = isDesktop ? 8 : isTablet ? 5 : 3
  const image = isDesktop && sizeGuide.getPresetImageByPresetLabel(preset.label)

  return (
    <>
      <div className={classNames(styles['content'])}>
        <SizeGuideTable preset={preset} sizes={sizesForType} columnCount={columnCount} />
        {image && (
          <div>
            <Asset type="img" file={image} withoutTheme classes={styles['content__image']} alt="Size Guide" />
          </div>
        )}
      </div>
    </>
  )
}

export default SizeGuideContent
