import { Theme } from '@mui/material'
import funkitaTheme from '../../app/styles/muiFunkita'
import funkytrunksTheme from '../../app/styles/muiFunkyTrunks'

export const THEME_FUNKITA = 'funkita'
export const THEME_FUNKY_TRUNKS = 'funkytrunks'
const DEFAULT_THEME = THEME_FUNKITA

function loadTheme(name: string | undefined): Theme {
  let theme = null

  switch (name || DEFAULT_THEME) {
    case THEME_FUNKY_TRUNKS:
      theme = funkytrunksTheme
      break

    default:
      theme = funkitaTheme
      break
  }

  return theme
}

export default loadTheme
