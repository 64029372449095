import { defineMessages } from 'react-intl'

const messages = defineMessages({
  appNewsletterSpamHint: {
    id: 'App.NewsletterSpamHint',
    defaultMessage:
      "We won't spam you but just send you exclusive sales and promo information every now and then. You can opt-out at any time.",
  },
  appUnknownError: {
    id: 'App.UnknownError',
    defaultMessage: 'An unknown error has occurred',
  },
  appUnknownShortError: {
    id: 'App.UnknownErrorShort',
    defaultMessage: 'An unknown error has occurred.',
  },
})

export default messages
