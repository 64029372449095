import ls from '@livesession/sdk'
import { euMember } from 'is-european'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrivacyConsentInterface, { PrivacyConsentsFieldEnum } from '../../interfaces/PrivacyConsentInterface'
import { updatePrivacyConsentsSuccess } from '../../services/global/global.actions'
import { State } from '../../services/reducers'
import { DEFAULT_COUNTRY_CODE } from '../../utils/locale'
import { savePrivacyConsents } from '../../utils/privacyConsent'
import useAppEvents from '../events/events'
import { AppEventsEnum } from '../events/events.types'

const defaultConsents: PrivacyConsentInterface = {
  [PrivacyConsentsFieldEnum.REQUIRED]: true,
  [PrivacyConsentsFieldEnum.MARKETING]: true,
  [PrivacyConsentsFieldEnum.FUNCTIONAL]: true,
}

const usePrivacyConsent = (withEventTriggers: boolean = false) => {
  const privacyConsents = useSelector((state: State) => state.global.privacyConsents)
  const countryByIp = useSelector((store: State) => store.global.countryByIp) || DEFAULT_COUNTRY_CODE
  const isEuCountry = euMember(countryByIp)
  const dispatch = useDispatch()
  const { triggerEvent } = useAppEvents()

  useEffect(() => {
    if (withEventTriggers && isEuCountry && privacyConsents) {
      triggerEvent(AppEventsEnum.UPDATE_PRIVACY_CONSENTS, {
        type: 'update',
        consents: {
          [PrivacyConsentsFieldEnum.REQUIRED]: true,
          [PrivacyConsentsFieldEnum.MARKETING]: privacyConsents[PrivacyConsentsFieldEnum.MARKETING],
          [PrivacyConsentsFieldEnum.FUNCTIONAL]: privacyConsents[PrivacyConsentsFieldEnum.FUNCTIONAL],
        },
      })
      triggerEvent(AppEventsEnum.UPDATE_PIXEL_FUNCTIONAL_CONSENT, {
        [PrivacyConsentsFieldEnum.REQUIRED]: true,
        [PrivacyConsentsFieldEnum.MARKETING]: privacyConsents?.[PrivacyConsentsFieldEnum.MARKETING] ?? false,
        [PrivacyConsentsFieldEnum.FUNCTIONAL]: privacyConsents?.[PrivacyConsentsFieldEnum.FUNCTIONAL] ?? false,
      })
    }
  }, [countryByIp, privacyConsents?.[PrivacyConsentsFieldEnum.MARKETING], privacyConsents?.[PrivacyConsentsFieldEnum.FUNCTIONAL]])

  const saveToStorage = (consents: PrivacyConsentInterface) => {
    savePrivacyConsents(consents)
    dispatch(
      updatePrivacyConsentsSuccess({
        type: 'update',
        consents,
      }),
    )
    if (!consents[PrivacyConsentsFieldEnum.FUNCTIONAL]) {
      ls.optOut()
    }
  }

  return {
    default: defaultConsents,
    isPrivacyConsentRequired: isEuCountry,
    privacyConsents,
    saveConsents: saveToStorage,
  }
}

export default usePrivacyConsent
