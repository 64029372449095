import * as api from '../../services/newsletter/newsletter.api'

export const useNewsletter = () => {
  const signToNewsletter = (inputEmailValue: string) => {
    return api.createUserNewsletter(inputEmailValue)
  }

  return {
    signToNewsletter,
  }
}
