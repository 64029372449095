import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './UpSellProducts.module.scss'
import UpSellContext from '../../../context/UpSellContext'
import { CatalogListItemInterface } from '../../../interfaces/CatalogInterface'
import CatalogListItem from '../../CatalogList/CatalogListItem/CatalogListItem'

interface Props {
  products: CatalogListItemInterface[]
}

const UpSellProducts = (props: Props) => {
  const { products } = props
  const upSell = useContext(UpSellContext)

  if (!products || products?.length === 0) {
    return null
  }

  const list = products.slice(0, 3).map(item => (
    <div key={`${item.id}-${item.itemType}`} className={styles['list__container']}>
      <CatalogListItem
        item={item}
        onProductLinkClick={upSell.handleClose}
        classes={{ root: styles['list__item'], img: styles['list__item__img'], imgLink: styles['list__item__img-link'] }}
      />
    </div>
  ))

  return (
    <div className={styles['up-sell-products']}>
      <h2 className={styles['up-sell-products__heading']}>
        <FormattedMessage id="UpSell.YouMayAlsoLike" description="UpSell.YouMayAlsoLike" defaultMessage="You May Also Like" />
      </h2>
      <div className={styles['up-sell-products__list']}>{list}</div>
    </div>
  )
}

export default UpSellProducts
