import axios from '../../utils/axios'

/** @todo: Zmienić po wprowadzeniu zmian na backendzie */
export function searchUserNewsletterIn() {
  return axios({
    method: 'GET',
    url: '/newsletter.json?filter[inRegistration]=1',
    data: null,
  })
}

export function createUserNewsletter(email: string) {
  return axios({
    method: 'POST',
    url: '/newsletter/add.json',
    data: { email },
  })
}
