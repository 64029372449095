import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { TransitionGroup } from 'react-transition-group'
import styles from './SearchModal.module.scss'
import FormattedMessage from '../../FormattedMessage/FormattedMessage'
import Modal, { ModalButton, ModalContent, ModalFooter, ModalHeader } from '../../Modal/Modal'
import Search from '../Search'

type Props = {
  open: boolean
  onClose: () => void
}

const SearchModal = (props: Props) => {
  const intl = useIntl()
  const [results, setResults] = useState<JSX.Element[]>([])

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <ModalHeader>
        <Search
          classes={{ root: styles['search'], input: styles['search__input'] }}
          onClose={props.onClose}
          isVisible={props.open}
          setResults={setResults}
          skipResultsRender
          placeholder={intl.formatMessage({ id: 'SearchModalView.SearchForPlaceholder', defaultMessage: 'Search for...' })}
        />
      </ModalHeader>
      <ModalContent className={styles['results']}>
        <TransitionGroup component="ul" className={styles['suggestions']}>
          {results}
        </TransitionGroup>
      </ModalContent>
      <ModalFooter>
        <ModalButton onClick={props.onClose}>
          <FormattedMessage id="SearchModalView.Back" description="SearchModalView.Back" defaultMessage="Close" />
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export default SearchModal
