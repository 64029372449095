import { ActionTypes, LocaleInterface, types } from './locale.types'
import { AddressFormatMapInterface } from '../../interfaces/AddressInterface'
import CurrencyInterface from '../../interfaces/CurrencyInterface'
import LanguageInterface from '../../interfaces/LanguageInterface'

export function updateCurrencyRequest(currency: CurrencyInterface): ActionTypes {
  return {
    type: types.UPDATE_CURRENCY_REQUEST,
    payload: currency,
  }
}

export function updateCurrencySuccess(currency: CurrencyInterface): ActionTypes {
  return {
    type: types.UPDATE_CURRENCY_SUCCESS,
    payload: currency,
  }
}

export function updateLanguageRequest(language: LanguageInterface): ActionTypes {
  return {
    type: types.UPDATE_LANGUAGE_REQUEST,
    payload: language,
  }
}

export function updateLanguageSuccess(language: LanguageInterface): ActionTypes {
  return {
    type: types.UPDATE_LANGUAGE_SUCCESS,
    payload: language,
  }
}

export function readConfigSuccess(data: LocaleInterface): ActionTypes {
  return {
    type: types.READ_CONFIG_SUCCESS,
    payload: data,
  }
}

export function readAddressFormatsSuccess(data: AddressFormatMapInterface): ActionTypes {
  return {
    type: types.READ_ADDRESS_FORMATS_SUCCESS,
    payload: data,
  }
}
