import React, { FC, useEffect, useRef, useState } from 'react'
import UpSellModal from './UpSellModal/UpSellModal'
import UpSellContext from '../../context/UpSellContext'
import useClubContext from '../../hooks/club/clubContext'
import { CatalogListItemInterface } from '../../interfaces/CatalogInterface'
import ChildrenType from '../../interfaces/ChildrenInterface'
import * as api from '../../services/product/product.api'
import { toCatalogProducts } from '../../transformers/CatalogTransformer'

type Props = {
  children: ChildrenType
}

const UpSellProvider: FC<Props> = ({ children }) => {
  const _mounted = useRef<boolean>(false)
  const clubContext = useClubContext()
  const [productId, setProductId] = useState<number | null>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [upSellProducts, setUpSellProducts] = useState<CatalogListItemInterface[]>()

  useEffect(() => {
    _mounted.current = true
    if (productId == null || !showModal) {
      return
    }
    const fetchUpSellProducts = async () => {
      try {
        const response = await api.readUpSellProducts(productId)
        if (_mounted.current) {
          setUpSellProducts(toCatalogProducts(response.data))
        }
      } catch (error: any) {
        console.error(error)
      }
    }

    fetchUpSellProducts()
  }, [showModal, productId])

  const handleOpen = (id: number) => {
    if (showModal) {
      return
    }
    setProductId(id)
    setShowModal(true)
  }

  const handleClose = () => {
    if (!showModal) {
      return
    }
    setShowModal(false)
    setUpSellProducts([])
  }

  return (
    <UpSellContext.Provider value={{ handleOpen, handleClose, showModal }}>
      {clubContext.isInClubFlow ? null : <UpSellModal promotionProducts={upSellProducts} />}
      {children}
    </UpSellContext.Provider>
  )
}

export default UpSellProvider
