import CloseIcon from '@mui/icons-material/Close'
import { Collapse, MenuItem, MenuList } from '@mui/material'
import classnames from 'classnames'
import React from 'react'
import routes from '../../../app/routes'
import { useAuth } from '../../../hooks/auth'
import UserInterface from '../../../interfaces/UserInterface'
import FormattedMessage from '../../FormattedMessage'
import Link from '../../Link'

type Classes = {
  link?: string
  list?: string
}

type Props = {
  classes?: Classes
  user: UserInterface
  miniCartRef?: React.RefObject<HTMLElement>
  selfRef?: React.RefObject<HTMLElement>
  megaMenuRef?: React.RefObject<HTMLElement>
}

const UserMenu = React.forwardRef((props: Props, ref: React.Ref<HTMLElement>) => {
  const { miniCartRef, selfRef, megaMenuRef } = props

  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen)
    miniCartRef?.current?.dispatchEvent(new Event('hideNow'))
  }

  const handleClose = (): void => {
    if (open) {
      setOpen(false)
    }
  }

  const handleHideNow = () => {
    setOpen(false)
  }

  React.useEffect(() => {
    megaMenuRef?.current?.addEventListener('mouseenter', () => {
      setOpen(false)
    })

    selfRef?.current?.addEventListener('hideNow', handleHideNow)

    return () => {
      megaMenuRef?.current?.removeEventListener('mouseenter', handleClose)
      selfRef?.current?.removeEventListener('hideNow', handleHideNow)
    }
  }, [])

  const [open, setOpen] = React.useState(false)
  const { logOut } = useAuth()
  const username = props.user && `${props.user.firstName} ${props.user.lastName}`

  return (
    <>
      <a className={classnames(props.classes?.link, 'profile')} onClick={handleToggle}>
        <FormattedMessage id="User.Label.Profile" description="User.Label.Profile" defaultMessage="Profile" />
      </a>
      <div
        className={classnames('auth-menu', open && 'show')}
        onMouseLeave={handleClose}
        ref={(selfRef as React.RefObject<HTMLDivElement>)!}
      >
        <Collapse in={open} className="content">
          <div className="header">
            <div className="name">Hi {username}</div>
            <a href="#" className={classnames(props.classes?.link, 'logout')} onClick={() => logOut()}>
              <FormattedMessage id="AuthMenu.UserMenu.LogOut" description="AuthMenu.UserMenu.LogOut" defaultMessage="Log Out" />
            </a>
            <div className="btn-close-wrapper" onClick={handleClose}>
              <CloseIcon />
            </div>
          </div>
          <MenuList classes={{ root: props.classes?.list }}>
            <MenuItem onClick={handleClose} disableRipple>
              <Link to={routes.userInfo}>
                <FormattedMessage id="AuthMenu.UserMenu.MyAccount" description="AuthMenu.UserMenu.MyAccount" defaultMessage="My Account" />
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose} disableRipple>
              <Link to={routes.userOrders}>
                <FormattedMessage id="AuthMenu.UserMenu.MyOrders" description="AuthMenu.UserMenu.MyOrders" defaultMessage="My Orders" />
              </Link>
            </MenuItem>
          </MenuList>
        </Collapse>
      </div>
    </>
  )
})

UserMenu.displayName = 'UserMenu'

export default UserMenu
