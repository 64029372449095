import React from 'react'
import { LinkProps } from 'react-router-dom'

const isModifiedEvent = (event: any) => {
  return !!(event?.metaKey || event?.altKey || event?.ctrlKey || event?.shiftKey)
}

type Props = LinkProps & {
  navigate: () => void
}

const LinkAnchor = (props: Props) => {
  const { innerRef, navigate, onClick, ...rest } = props
  const { target } = rest

  return (
    <a
      {...rest}
      ref={innerRef}
      onClick={event => {
        try {
          if (onClick) onClick(event)
        } catch (ex) {
          event.preventDefault()
          throw ex
        }

        if (
          !event.defaultPrevented && // onClick prevented default
          event.button === 0 && // ignore everything but left clicks
          (!target || target === '_self') && // let browser handle "target=_blank" etc.
          !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
          event.preventDefault()
          navigate()
        }
      }}
    >
      {rest.children}
    </a>
  )
}

export default LinkAnchor
