import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import styles from './ShopSwitcher.module.scss'
import ChannelContext from '../../context/ChannelContext'
import { State } from '../../services/reducers'
import ChannelLink from '../ChannelLink'

type CounterModifier = (counter: number) => void

type Props = {
  classes?: string
}

let timerId: null | NodeJS.Timeout = null

function startTimer(revealCounter: number, setRevealCounter: CounterModifier): void {
  timerId = setTimeout(() => setRevealCounter(revealCounter + 1), 3000)
}

function clearTimer(): void {
  if (timerId) {
    clearTimeout(timerId)
    timerId = null
  }
}

const ShopSwitcher: React.FC<Props> = props => {
  const channel = useContext(ChannelContext)
  const locations = useSelector((state: State) => state.global.locations[channel.alternate?.id || -1])
  const [revealCounter, setRevealCounter] = useState(0)
  const intl = useIntl()

  useEffect(() => {
    clearTimer()

    if (revealCounter < 6) {
      startTimer(revealCounter, setRevealCounter)
    }

    return clearTimer
  })

  if (channel.alternate === null) {
    return null
  }

  const goToLabel = channel.current.isWomenShop
    ? intl.formatMessage({ id: 'ShopSwitcher.FunkitaGoTo', defaultMessage: 'Click to shop men&boys' })
    : intl.formatMessage({ id: 'ShopSwitcher.FunkytrunksGoTo', defaultMessage: 'Click to shop women&girls' })
  let label = channel.alternate.frontLabel
  const shouldShowGotoLabel = revealCounter <= 6 && revealCounter % 2 !== 0

  if (shouldShowGotoLabel) {
    label = goToLabel
  }

  return (
    <ChannelLink code={channel.alternate.code} to={locations?.last || '/'} className={styles['opposite-logo__link']}>
      <span className={classNames(props.classes, styles['opposite-logo'], shouldShowGotoLabel && styles['opposite-logo--go-to-shop'])}>
        <span>{label}</span>
      </span>
    </ChannelLink>
  )
}

export default ShopSwitcher
