import { FormikHelpers, useFormik } from 'formik'
import _ from 'lodash'
import React, { useContext, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import guestSchema from './SignAsGuestSchema'
import signUpSchema from './SignUpFormSchema'
import SignUpFormView from './SignUpFormView'
import routes from '../../app/routes'
import HistoryContext from '../../context/HistoryContext'
import { useAuth } from '../../hooks/auth/auth'
import useAppEvents from '../../hooks/events/events'
import { AppEventsEnum } from '../../hooks/events/events.types'
import useMatchMedia from '../../hooks/matchMedia'
import { NewsletterOptionInterface } from '../../interfaces/NewsletterInterface'
import { UserNewsletterOptionInterface } from '../../interfaces/UserNewsletterOptionInterface'
import { State } from '../../services/reducers'
import { AXIOS_CODE_BAD_REQ, AxiosError, AxiosResponse } from '../../utils/axios'
import { handleErrorResponse } from '../../utils/formErrorHelper'
import Yup from '../../utils/yup'
import { RESET_PASSWORD_ORIGIN } from '../../web/views/ResetPassword'
import FormattedMessage from '../FormattedMessage'
import Link from '../Link'
import notify from '../Notification/notify'

export const FORM_NAME = 'FROM_SIGNUP'

export enum FORM_KIND {
  SIGN_UP = 'signUp',
  GUEST = 'guest',
}

type Props = {
  exited: boolean
  expanded: boolean
  toggle: (name: string, expanded: boolean) => void
  newsletterOption?: NewsletterOptionInterface
  validationSchema?: any
  onExpand?: () => void
}

export type FormikValues = SignUpValuesType | GuestValuesType

type SignUpValuesType = {
  firstName: string
  lastName: string
  email: string
  newsletter: boolean
  newsletterOptions?: UserNewsletterOptionInterface[]
  tos: boolean
  birthday: string
  plainPassword: string
  newPassword: string
}

type GuestValuesType = {
  firstName: string
  lastName: string
  email: string
  newsletter: boolean
  newsletterOptions?: UserNewsletterOptionInterface[]
  tos: boolean
  birthday?: undefined
}

type FormConfigType = {
  [key in FORM_KIND]: {
    initialValues: FormikValues
    onSubmit: (values: FormikValues, formikBag: FormikHelpers<FormikValues>) => void
    buttonLabel: JSX.Element
    schema: Yup.AnySchema
  }
}

const SignUpForm = (props: Props) => {
  const { isDesktop } = useMatchMedia()
  const language = useSelector((state: State) => state.locale.language)
  const newsletterOption: NewsletterOptionInterface | null = props.newsletterOption || null
  const isMobile: boolean = !isDesktop
  const history = useContext(HistoryContext)
  const { location } = history
  const isCheckout = location.pathname.includes(routes.cart)
  const [formKind, setFormKind] = useState<FORM_KIND>(isCheckout ? FORM_KIND.GUEST : FORM_KIND.SIGN_UP)
  const guestMode = isCheckout && formKind === FORM_KIND.GUEST && props.expanded
  const { signUp, createGuest } = useAuth()
  const intl = useIntl()
  const { triggerEvent } = useAppEvents()

  const guestInitialValues = {
    email: '',
    firstName: '',
    lastName: '',
    newsletter: false,
    tos: false,
  }

  const signUpInitialValues = {
    email: '',
    repeatEmail: '',
    firstName: '',
    lastName: '',
    newsletter: false,
    tos: false,
    plainPassword: '',
    newPassword: '',
    birthday: '',
  }

  const handleNewsletter = (formData: FormikValues) => {
    if (formData.newsletter && newsletterOption) {
      formData.newsletterOptions = [
        {
          newsletterOption: newsletterOption.id,
          active: true,
        },
      ]
    }
    //@ts-ignore
    delete formData.newsletter
  }

  const onSignUpSubmit = (values: FormikValues, formikBag: FormikHelpers<FormikValues>) => {
    const formData = _.pick(values, Object.keys(signUpInitialValues)) as SignUpValuesType

    handleNewsletter(formData)
    signUp(
      formData,
      (response: AxiosResponse) => {
        triggerEvent(AppEventsEnum.SIGN_UP, { pathname: location.pathname, method: 'Brandshop' })
      },
      (error: any) => {
        formikBag.setSubmitting(false)
        if (error.response?.status == AXIOS_CODE_BAD_REQ && error.response.data.fields?.email) {
          notify.error(
            <FormattedMessage
              id="SignUpForm.UserExistsError"
              defaultMessage="An account with the email {email} exists. Please sign in or click <link>here</link> to recover password. "
              values={{
                email: values.email,
                // eslint-disable-next-line react/display-name
                link: (chunks: any) => (
                  <Link
                    to={`${routes.authForgotPassword}?from=${isCheckout ? RESET_PASSWORD_ORIGIN.CART : RESET_PASSWORD_ORIGIN.STANDALONE}`}
                    className="strong"
                  >
                    here
                  </Link>
                ),
              }}
            />,
          )
          //clear alert from backend
          delete error.response.data.fields.email
          handleErrorResponse(error.response, formikBag, true)
        } else handleErrorResponse(error.response, formikBag, false)
      },
    )
  }

  const onGuestSubmit = (values: FormikValues, formikBag: FormikHelpers<FormikValues>) => {
    const formData = _.pick(values, Object.keys(guestInitialValues)) as GuestValuesType
    handleNewsletter(formData)
    //@ts-ignore
    delete formData.tos // must be deleted because backend rejects requests with tos property

    createGuest(
      formData,
      (response: AxiosResponse) => {
        triggerEvent(AppEventsEnum.SIGN_UP, { pathname: location.pathname, method: 'Guest' })
      },
      (error: any) => {
        formikBag.setSubmitting(false)
        if (error.response?.status == AXIOS_CODE_BAD_REQ && error?.response?.data?.fields?.email) {
          notify.error(
            <FormattedMessage
              id="SignUpForm.UserExistsError"
              defaultMessage="An account with the email {email} exists. Please sign in or click <link>here</link> to recover password. "
              values={{
                email: values.email,
                // eslint-disable-next-line react/display-name
                link: (chunks: any) => (
                  <Link
                    to={`${routes.authForgotPassword}?from=${isCheckout ? RESET_PASSWORD_ORIGIN.CART : RESET_PASSWORD_ORIGIN.STANDALONE}`}
                    className="strong"
                  >
                    here
                  </Link>
                ),
              }}
            />,
          )
          //clear alert from backend
          delete error.response.data.fields.email
          handleErrorResponse(error.response, formikBag, true)
        } else handleErrorResponse(error.response, formikBag, false)
      },
    )
  }

  const formConfig: FormConfigType = {
    [FORM_KIND.GUEST]: {
      initialValues: guestInitialValues,
      onSubmit: onGuestSubmit,
      buttonLabel: (
        <FormattedMessage
          id="SignUpForm.GuestCheckoutButton"
          description="SignUpForm.GuestCheckoutButton"
          defaultMessage="Checkout As Guest"
        />
      ),
      schema: guestSchema(intl),
    },
    [FORM_KIND.SIGN_UP]: {
      initialValues: signUpInitialValues,
      onSubmit: onSignUpSubmit,
      buttonLabel: (
        <FormattedMessage id="SignUpForm.SignUpButton" description="SignUpForm.SignUpButton" defaultMessage="Sign Up And Checkout" />
      ),
      schema: signUpSchema(intl),
    },
  }

  const formik = useFormik({
    validationSchema: formConfig[formKind].schema,
    initialValues: {
      ...formConfig[FORM_KIND.GUEST].initialValues,
      ...formConfig[FORM_KIND.SIGN_UP].initialValues,
    },
    onSubmit: formConfig[formKind].onSubmit,
  })

  const renderButtonLabel = () => {
    if (!isCheckout) {
      return (
        <FormattedMessage
          id="SignUpForm.SignAndCheckoutButton"
          description="SignUpForm.SignAndCheckoutButton"
          defaultMessage="Sign Up Now"
        />
      )
    }
    if (!props.expanded) {
      return <FormattedMessage id="SignUpForm.Continue" description="SignUpForm.Continue" defaultMessage="Continue" />
    }
    return formConfig[formKind].buttonLabel
  }

  const handleFormKindCheckbox = () => setFormKind(guestMode ? FORM_KIND.SIGN_UP : FORM_KIND.GUEST)

  const handleButtonClick = props.onExpand && !props.expanded ? props.onExpand : formik.submitForm

  return (
    <SignUpFormView
      formik={formik}
      isCheckout={isCheckout}
      buttonLabel={renderButtonLabel()}
      handleFormKindCheckbox={handleFormKindCheckbox}
      guestMode={guestMode}
      languageIsoCode={language.isoCode}
      handleButtonClick={handleButtonClick}
      newsletterOption={newsletterOption}
      isMobile={isMobile}
      expanded={props.expanded}
      exited={props.exited}
    />
  )
}

export default SignUpForm
