import DeleteIcon from '@mui/icons-material/DeleteOutline'
import HelpIcon from '@mui/icons-material/HelpOutline'
import { Button, Divider, IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material'
import classNames from 'classnames'
import React, { useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import styles from './CartItem.module.scss'
import routes from '../../../app/routes'
import ChannelContext from '../../../context/ChannelContext'
import UpSellContext from '../../../context/UpSellContext'
import { useCart } from '../../../hooks/cart'
import useAppEvents from '../../../hooks/events/events'
import { AppEventsEnum } from '../../../hooks/events/events.types'
import useMatchMedia from '../../../hooks/matchMedia'
import useSizeGuide from '../../../hooks/sizeGuide/sizeGuide'
import {
  CartItemGiftInterface,
  CartItemInterface,
  CLUB_ITEM_QTY_LIMIT,
  ITEM_QTY_LIMIT,
  PRODUCT_TYPE_ENUM,
} from '../../../interfaces/CartInterface'
import { FlowType } from '../../../interfaces/ChannelInterface'
import { SIZE_PRESET_NONE } from '../../../interfaces/ProductInterface'
import { AxiosResponse } from '../../../utils/axios'
import { getFullProductName, isGiftVoucher } from '../../../utils/product'
import { _at } from '../../../utils/translations'
import FormattedMessage from '../../FormattedMessage'
import Link from '../../Link'
import Notification from '../../Notification/Notification'
import NumberField from '../../NumberField'
import Photo from '../../Photo'
import Price from '../../Price'

interface Props {
  gifts: CartItemGiftInterface[] | null
  item: CartItemInterface
  currencyCode: string
  readOnly?: boolean
  classes?: {
    imageBox?: string
    root?: string
  }
  hidePrice?: boolean
  compactLayout?: boolean
}

const QTY_EXCEEDS_ERR_CODE = 'QTY_EXCEEDS'
const SOLD_OUT_ERR_CODE = 'SOLD_OUT'

const CartItem = (props: Props) => {
  const intl = useIntl()
  const { adjustProduct, removeProduct, removeGiftVoucher } = useCart()
  const [updatingCart, setUpdatingCart] = React.useState<boolean>(false)
  const { triggerEvent } = useAppEvents()
  const { isMobile, isDesktop, isSmallTablet } = useMatchMedia()
  const upSell = useContext(UpSellContext)
  const sizeGuide = useSizeGuide()
  const channel = useContext(ChannelContext)

  const adjustProductMemoized = useCallback(
    (value: number, onError: (lastValue: number) => void) => {
      setUpdatingCart(true)
      adjustProduct(
        {
          productId: item.productId,
          quantity: value,
        },
        (response: AxiosResponse): void => {
          const adjQty = value - item.quantity
          triggerEvent(AppEventsEnum.ADJUST_CART, { adjQty, currency: response.data.currency, item })
          setUpdatingCart(false)
        },
        () => {
          setUpdatingCart(false)
          onError(item.quantity)
        },
      )
    },
    [props.item.id, props.item.quantity],
  )

  const deleteGiftVoucher = (id: number) => {
    removeGiftVoucher(id)
  }

  const removeProductMemoized = useCallback(
    (event: any) => {
      setUpdatingCart(true)
      removeProduct(
        item.productId,
        (response: AxiosResponse): void => {
          const adjQty = -item.quantity
          triggerEvent(AppEventsEnum.ADJUST_CART, { adjQty, currency: response.data.currency, item })
          setUpdatingCart(false)
        },
        () => {
          setUpdatingCart(false)
        },
      )
    },
    [props.item.id],
  )

  const qty = [1, 2, 3, 4, 5]
  const { item, readOnly, gifts, hidePrice } = props
  const isGift = isGiftVoucher(item.skuCode || item.product.parent?.skuCode!)
  const photos = item.product.parent?.description?.photos || ''

  const parent = item.product.parent
  const productId = parent?.id
  const productSlug = _at('slug', parent?.description?.translations!)
  const categorySlug = 'product'
  const printName = _at('printName', item.productInfo?.translations!)
  const name = _at('title', parent?.description?.translations!).replace(printName, '')
  const to = {
    name: routes.product,
    params: {
      productId,
      categorySlug,
      productSlug,
    },
  }

  let notAvailable = -1

  item?.errors &&
    item.errors &&
    item.errors.forEach(i => {
      return typeof i === 'object' && i?.code! === QTY_EXCEEDS_ERR_CODE ? (notAvailable = parseInt(i?.message, 10)) : -1
    })

  let soldOut = item?.errors && item.errors?.findIndex(i => typeof i === 'object' && i?.code! === SOLD_OUT_ERR_CODE) > -1

  if (item.productType == PRODUCT_TYPE_ENUM.VIRTUAL) {
    soldOut = false
    notAvailable = -1
  }

  const itemDetailsClasses = classNames({
    [styles['item__details']]: !props.compactLayout,
    [styles['item__details--compact']]: props.compactLayout,
    [styles['item__details--readonly']]: readOnly && !props.compactLayout,
    [styles['item__details--readonly--compact']]: readOnly && props.compactLayout,
    [styles['item--cart__details']]: isGift,
  })

  const detailsPriceClasses = classNames({
    [styles['details__price']]: !props.compactLayout,
    [styles['details__price--compact']]: props.compactLayout,
    [styles['details__price--readonly']]: readOnly && !props.compactLayout,
    [styles['details__price--readonly--compact']]: readOnly && props.compactLayout,
    [styles['item--cart__price']]: isGift,
    [styles['item--cart__sold-out']]: soldOut,
  })

  const detailsNameClasses = classNames({
    [styles['details__name']]: !props.compactLayout,
    [styles['details__name--compact']]: props.compactLayout,
    [styles['details__name--readonly']]: readOnly && !props.compactLayout,
  })

  const detailsSizeClasses = classNames({
    [styles['details__size']]: !props.compactLayout,
    [styles['details__size--compact']]: props.compactLayout,
    [styles['details__size--readonly']]: readOnly && !props.compactLayout,
    [styles['details__size--readonly--compact']]: readOnly && props.compactLayout,
  })

  const itemClasses = classNames(
    {
      [styles['item']]: !props.compactLayout,
      [styles['item--compact']]: props.compactLayout,
    },
    props.classes?.root,
    {
      [styles['item--gift']]: isGift,
    },
  )

  const itemGiftsClasses = classNames({
    [styles['item__gifts']]: !readOnly,
    [styles['item__gifts--readonly']]: readOnly,
  })

  const giftsItemClasses = classNames({
    [styles['gifts__item']]: !readOnly,
    [styles['gifts__item--readonly']]: readOnly && !props.compactLayout,
    [styles['gifts__item--readonly--compact']]: readOnly && props.compactLayout,
  })

  const detailsNoStockClasses = classNames({
    [styles['details__no-stock']]: !props.compactLayout,
    [styles['details__no-stock--compact']]: props.compactLayout,
  })

  const detailsQtyActionClasses = classNames({
    [styles['details__qty-action']]: !props.compactLayout,
    [styles['details__qty-action--compact']]: props.compactLayout,
  })

  const itemImageBoxClasses = classNames(
    {
      [styles['item__image-box']]: !props.compactLayout,
      [styles['item__image-box--compact']]: props.compactLayout,
    },
    props.classes?.imageBox,
  )

  const detailsQtyClasses = classNames({
    [styles['details__qty']]: !props.compactLayout,
    [styles['details__qty--compact']]: props.compactLayout,
  })

  const detailsQtyReadOnlyClasses = classNames({
    [styles['details__qty--readonly']]: !props.compactLayout,
    [styles['details__qty--readonly--compact']]: props.compactLayout,
  })

  const detailsRemoveClasses = classNames({
    [styles['details__remove']]: !props.compactLayout,
    [styles['details__remove--compact']]: props.compactLayout,
  })

  const priceLabelClasses = classNames({
    [styles['price__label']]: !props.compactLayout,
    [styles['price__label--compact']]: props.compactLayout,
  })

  const noStockMessage =
    !readOnly && (soldOut || notAvailable > -1) ? (
      <div className={detailsNoStockClasses}>
        <Notification
          flat
          id={-1}
          type="error"
          className={styles['no-stock__notification']}
          content={
            <>
              {soldOut ? <FormattedMessage id="CartItem.ItemNotAvailableAtAll" defaultMessage="Sold out" /> : null}
              {notAvailable > -1 ? <FormattedMessage id="CartItem.ItemNotAvailable" defaultMessage="Reduce the quantity" /> : null}
            </>
          }
        />
      </div>
    ) : null

  const adjustQty = () => {
    if (notAvailable > -1) adjustProductMemoized(notAvailable, () => {})
  }

  const qtyErrorContent = (
    <Button variant="contained" color="primary" sx={{ minWidth: 155 }} onClick={removeProductMemoized}>
      <FormattedMessage id="Cart.CartItem.RemoveSoldOutItem" defaultMessage="Remove this item" />
    </Button>
  )

  const qtyAdjustContent = (
    <Button variant="contained" color="primary" sx={{ minWidth: 155 }} onClick={adjustQty}>
      <FormattedMessage id="Cart.CartItem.RemoveSoldOutItem" defaultMessage="Adjust quantity" />
    </Button>
  )

  return (
    <div className={itemClasses}>
      <div className={itemImageBoxClasses}>
        <Photo src={photos} type="catalog" classes={styles['item__image']} alt={getFullProductName(item.productInfo)} />
      </div>
      <div className={itemDetailsClasses}>
        <div className={detailsNameClasses}>
          {isGift ? (
            <FormattedMessage id="Cart.CartItem.EGiftItemName" defaultMessage="Gift Vouchers" />
          ) : (
            <>
              <Link to={to} onClick={upSell.handleClose}>
                {name}
              </Link>
              <Link to={to} onClick={upSell.handleClose}>
                {printName}
              </Link>
            </>
          )}
        </div>

        <div className={detailsSizeClasses}>
          {item.product.size?.label && item.product.size?.preset !== SIZE_PRESET_NONE ? (
            <>
              <FormattedMessage id="Cart.CartItem.Size" description="Cart.CartItem.Size" defaultMessage="Size" tag="span" />:
              {` ${sizeGuide.getSizeLabel(item.product.size.id) || item.product.size.label}`}
            </>
          ) : (
            ''
          )}
        </div>
        {readOnly && !isGift ? (
          <div className={detailsQtyReadOnlyClasses}>
            <FormattedMessage id="Cart.CartItem.Quantity" description="Cart.CartItem.Size" tag="span" defaultMessage="Quantity" />:
            {` ${isGift ? gifts?.length : item.quantity}`}
          </div>
        ) : null}
        {isDesktop ? noStockMessage : null}

        {!readOnly && !soldOut ? (
          <div className={detailsQtyClasses}>
            {isGift ? null : (
              <NumberField
                classes={{
                  input: styles['qty'],
                }}
                value={item.quantity}
                handleClick={adjustProductMemoized}
                minValue={qty[0]}
                maxValue={channel.current.flowType === FlowType.Club ? CLUB_ITEM_QTY_LIMIT : ITEM_QTY_LIMIT}
                disabled={updatingCart || soldOut || notAvailable > -1}
              />
            )}
          </div>
        ) : null}

        {!readOnly && soldOut ? <div className={detailsQtyClasses} /> : null}

        {isGift ? (
          <List classes={{ root: itemGiftsClasses }}>
            {gifts?.map(gift => {
              return (
                <ListItem key={gift.id} classes={{ root: giftsItemClasses }}>
                  <ListItemText classes={{ root: styles['gifts__text'] }}>
                    <FormattedMessage
                      id="Cart.GiftItem.ItemLine"
                      defaultMessage="{amount} for {name}"
                      values={{
                        name: gift.recipientName,
                        amount: intl.formatNumber(parseInt(gift.amountGross, 10), {
                          style: 'currency',
                          currency: props.currencyCode,
                        }),
                      }}
                      className={readOnly ? styles['gifts-text__content'] : undefined}
                    />
                  </ListItemText>
                  {readOnly ? null : (
                    <IconButton
                      aria-label="Remove"
                      size="small"
                      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => deleteGiftVoucher(gift.id)}
                      classes={{ root: styles['gifts__delete'] }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  {readOnly ? null : <Divider classes={{ root: styles['gifts__divider'] }} />}
                </ListItem>
              )
            })}
          </List>
        ) : null}
      </div>
      {hidePrice || soldOut || notAvailable > -1 ? null : (
        <div className={detailsPriceClasses}>
          <Price value={item.costGross} currency={props.currencyCode} className={priceLabelClasses} />
          <div className={detailsRemoveClasses}>
            {!readOnly && !isGift ? (
              <IconButton aria-label="Remove" size="small" onClick={removeProductMemoized} color={soldOut ? 'primary' : 'default'}>
                <DeleteIcon />
              </IconButton>
            ) : (
              !readOnly && (
                <Tooltip
                  enterTouchDelay={1}
                  leaveTouchDelay={2500}
                  title={
                    <FormattedMessage
                      id="Cart.CartItem.GiftHowToRemove"
                      defaultMessage="To delete this item, you must remove all gifts on sub-list"
                    />
                  }
                >
                  <IconButton aria-label="delete" size="small">
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              )
            )}
          </div>
        </div>
      )}
      {!isMobile && !isSmallTablet && !readOnly && notAvailable > -1 ? <div className={detailsPriceClasses}>{qtyAdjustContent}</div> : null}
      {(isMobile || isSmallTablet) && !readOnly && notAvailable > -1 ? (
        <div className={detailsQtyActionClasses}>{qtyAdjustContent}</div>
      ) : null}
      {!isMobile && !isSmallTablet && !readOnly && soldOut ? <div className={detailsPriceClasses}>{qtyErrorContent}</div> : null}
      {(isMobile || isSmallTablet) && !readOnly && soldOut ? <div className={detailsQtyActionClasses}>{qtyErrorContent}</div> : null}
      {notAvailable && !isDesktop ? noStockMessage : null}
    </div>
  )
}

export default CartItem
