import { Tab, Tabs } from '@mui/material'
import _ from 'lodash'
import React, { useState } from 'react'
import styles from './SizeGuideTabs.module.scss'
import { PresetInterface, SizeGuideInterface } from '../../../interfaces/SizeGuideInterface'
import FormattedMessage from '../../FormattedMessage'
import SizeGuideContent from '../SizeGuideContent'

type Props = {
  sizesByOrder: PresetInterface[]
  sizes: SizeGuideInterface[]
}

const defaultTabClass = {
  root: styles['tab'],
  selected: styles['tab--selected'],
  label: styles['tab__label'],
}

const rippleTabClass = {
  ripple: styles['tab--ripple'],
}

const SizeGuideTabs = (props: Props) => {
  const { sizesByOrder } = props

  const [activeIndex, setActiveIndex] = useState<number>(0)
  const selectedSizesByOrder = sizesByOrder[activeIndex] ? sizesByOrder[activeIndex] : sizesByOrder[0]

  const handleTabChange = (event: React.SyntheticEvent<any, Event>, newIndex: number) => {
    setActiveIndex(newIndex)
  }

  const tabs = sizesByOrder.map(({ type }) => {
    const messageId = `SizeGuide.Label.${_.upperFirst(type)}`
    return (
      <Tab
        key={type}
        label={<FormattedMessage id={messageId} description={messageId} />}
        classes={defaultTabClass}
        TouchRippleProps={{ classes: rippleTabClass }}
      />
    )
  })

  return (
    <>
      <Tabs value={activeIndex} className={styles['tabs']} variant="fullWidth" onChange={handleTabChange}>
        {tabs}
      </Tabs>
      {selectedSizesByOrder && <SizeGuideContent preset={selectedSizesByOrder} />}
    </>
  )
}

export default SizeGuideTabs
