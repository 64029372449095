import { defineMessages } from 'react-intl'

const messages = defineMessages({
  addressFormLabelFirstName: {
    id: 'AddressBookForm.Field.FirstName',
    defaultMessage: 'First Name',
  },
  addressFormLabelLastName: {
    id: 'AddressBookForm.Field.LastName',
    defaultMessage: 'Last Name',
  },
  addressFormLabelStreet: {
    id: 'AddressBookForm.Field.Street',
    defaultMessage: 'Street',
  },
  addressFormLabelStreet2: {
    id: 'AddressBookForm.Field.Street2',
    defaultMessage: 'Street (line 2)',
  },
  addressFormLabelCity: {
    id: 'AddressBookForm.Field.City',
    defaultMessage: 'City',
  },
  addressFormLabelCountry: {
    id: 'AddressBookForm.Field.Country',
    defaultMessage: 'Country',
  },
  addressFormLabelPostCode: {
    id: 'AddressBookForm.Field.PostCode',
    defaultMessage: 'ZIP / Postal code',
  },
  addressFormLabelPhoneNumber: {
    id: 'AddressBookForm.Field.PhoneNumber',
    defaultMessage: 'Phone number',
  },
  addressFormLabelState: {
    id: 'AddressBookForm.Field.State',
    defaultMessage: 'State / Region ',
  },
  addressFormLabelEmail: {
    id: 'AddressBookForm.Field.Email',
    defaultMessage: 'E-mail',
  },
})

export default messages
