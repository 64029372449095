import getUserLocale from 'get-user-locale'
import CurrencyInterface from '../interfaces/CurrencyInterface'
import LanguageInterface from '../interfaces/LanguageInterface'
import store from '../services/store'

export const DEFAULT_LANGUAGE_ISO_CODE = 'en-AU'
export const DEFAULT_LANGUAGE_SHORT_ISO_CODE = 'en'
export const DEFAULT_COUNTRY_CODE = 'AU'

export function getLanguageByIsoCode(isoCode: string): LanguageInterface {
  const languages = Object.values(store.getState()?.locale?.languages!)
  const fallbackLanguageCode = store.getState()?.global?.fallbackLanguageIsoCode!
  let language = languages.find((item: LanguageInterface) => item.isoCode.toLowerCase() === isoCode.toLowerCase())
  if (!language) {
    language = store.getState()?.locale?.languages[fallbackLanguageCode]!
  }

  return language
}

export function getCurrencyByCode(isoCode: string): CurrencyInterface {
  const currencies = Object.values(store.getState()?.locale?.currencies!)
  const fallbackCurrency = store.getState()?.global?.fallbackCurrencyIsoCode!
  let currency = currencies.find((item: CurrencyInterface) => item.isoCode.toLowerCase() === isoCode.toLowerCase())
  if (!currency) {
    currency = store.getState()?.locale?.currencies[fallbackCurrency]!
  }

  return currency
}

export function getPreferredLocale(): string {
  let userLocale = getUserLocale() || DEFAULT_LANGUAGE_ISO_CODE

  if (userLocale.length < 3) {
    userLocale = `${userLocale}-${userLocale.toUpperCase()}`
  }

  return userLocale
}
