import ls from '@livesession/sdk'
import { Typography } from '@mui/material'
import { FormikHelpers } from 'formik'
import { Location as LocationType } from 'history'
import _ from 'lodash'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, useHistory, useLocation, useParams } from 'react-router'
import { Dispatch } from 'redux'
import styles from './ResetPassword.module.scss'
import routes from '../../../app/routes'
import FormattedMessage from '../../../components/FormattedMessage'
import LoadingSpinner from '../../../components/LoadingSpinner'
import PageLayout from '../../../components/PageLayout'
import ResetPasswordForm, { FormikValues as ResetPasswordValues } from '../../../components/ResetPasswordForm'
import SetNewPasswordForm from '../../../components/SetNewPasswordForm'
import { SetPasswordFormType } from '../../../components/SetNewPasswordForm/SetNewPasswordForm.types'
import * as authActions from '../../../services/auth/auth.actions'
import * as userApi from '../../../services/user/user.api'
import { AxiosError, AxiosResponse } from '../../../utils/axios'
import { handleErrorResponse } from '../../../utils/formErrorHelper'

export enum RESET_PASSWORD_ORIGIN {
  STANDALONE = 0,
  CART = 1,
}

export interface MatchParams {
  token: string
}

const ResetPassword = () => {
  const params = useParams<MatchParams>()
  const [isTokenValid, setTokenValidation] = useState<boolean | null>(null)
  const [isSubmitted, setSubmitted] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)
  const token: string | null = params.token || ''
  const searchParams = qs.parse(useLocation().search, { ignoreQueryPrefix: true })
  const fromPage = parseInt(searchParams.from as string, 10)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (token) {
      userApi
        .updateResetPassword(token)
        .then((response: AxiosResponse) => {
          dispatch(authActions.signInSuccess(response.data))
          setTokenValidation(true)
        })
        .catch((err: AxiosError) => {
          setTokenValidation(false)
        })
    }
  }, [isTokenValid])

  const resetPasswordOnSubmit = (values: ResetPasswordValues, formik: FormikHelpers<ResetPasswordValues>) => {
    userApi
      .createResetPassword(values.email, fromPage)
      .then((response: AxiosResponse) => {
        ls.track('Reset Password - Requested', {
          action_str: 'Requested',
          email_str: values.email,
        })
        setSubmitted(true)
        formik.resetForm()
        formik.setSubmitting(true)
      })
      .catch((error: AxiosError) => {
        setSubmitted(false)
        formik.setSubmitting(false)
        handleErrorResponse(error.response, formik)
      })
  }

  const setPasswordOnSubmit = async (values: SetPasswordFormType, formikBag: FormikHelpers<SetPasswordFormType>) => {
    try {
      const response = await userApi.updateSetPassword(values.newPassword, token!)
      ls.track('Reset Password - Changed', {
        action_str: 'Changed',
      })
      formikBag.resetForm()
      setPasswordChanged(true)
      dispatch(authActions.signInSuccess({ user: response.data }))
      if (fromPage === RESET_PASSWORD_ORIGIN.CART) {
        history.push(routes.cartDelivery)
      } else {
        history.push(routes.userInfo)
      }
    } catch (error: any) {
      console.error(error)
      setPasswordChanged(false)
      handleErrorResponse(error.response, formikBag)
    }
  }

  let content: any = (
    <>
      <Typography variant="body1" align="center" gutterBottom className={styles['container__heading']}>
        <FormattedMessage
          id="ResetPassword.PleaseEnterUsedEmail"
          description="ResetPassword.PleaseEnterUsedEmail"
          defaultMessage="Please enter the email address used to register. We will e-mail you a recovery link."
        />
      </Typography>
      <ResetPasswordForm onSubmit={resetPasswordOnSubmit} className={styles['container__form']} />
    </>
  )

  if (isSubmitted) {
    content = (
      <Typography variant="body1" align="center" gutterBottom className={styles['container__heading']}>
        <FormattedMessage
          id="ResetPassword.EmaiLWithLinkWasSent"
          description="ResetPassword.EmaiLWithLinkWasSent"
          defaultMessage="Please check your email box (including spam) and use the link from message to set your password."
        />
      </Typography>
    )
  }

  if (token) {
    content = <LoadingSpinner />

    if (isTokenValid === false) {
      content = <Redirect to={routes.home} />
    }

    if (passwordChanged) {
      content = (
        <Typography variant="body1" align="center" gutterBottom className={styles['container__heading']}>
          <FormattedMessage
            id="ResetPassword.PasswordWasChanged"
            description="ResetPassword.PasswordWasChanged"
            defaultMessage="Your password has been changed"
          />
        </Typography>
      )
    } else {
      content = (
        <>
          <Typography variant="body1" align="center" gutterBottom className={styles['container__heading']}>
            <FormattedMessage
              id="ResetPassword.EnterNewPasswordHelpText"
              description="ResetPassword.EnterNewPasswordHelpText"
              defaultMessage="Please enter new password and repeat it"
            />
          </Typography>
          <SetNewPasswordForm classes={{ root: styles['container__form'] }} onSubmit={setPasswordOnSubmit} />
        </>
      )
    }
  }

  return (
    <PageLayout>
      <div className={styles['container']}>
        <Typography variant="h5" align="center" paragraph className={styles['container__heading']}>
          {token ? (
            <FormattedMessage
              id="ResetPassword.ChangePasswordHeading"
              description="ResetPassword.ChangePasswordHeading"
              defaultMessage="Set your new password"
            />
          ) : (
            <FormattedMessage
              id="ResetPassword.ForgotYourPasswordHeading"
              description="ResetPassword.ForgotYourPasswordHeading"
              defaultMessage="Forgot your password?"
            />
          )}
        </Typography>
        {content}
      </div>
    </PageLayout>
  )
}

export default ResetPassword
