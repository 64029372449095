import countriesIsoList from 'i18n-iso-countries'
import countriesEN from 'i18n-iso-countries/langs/en.json'
import countriesFR from 'i18n-iso-countries/langs/fr.json'
import countriesPL from 'i18n-iso-countries/langs/pl.json'
//TODO: register neww locale if new language was added

countriesEN.countries.US.push('United States')
countriesIsoList.registerLocale(countriesEN)
countriesIsoList.registerLocale(countriesFR)
countriesIsoList.registerLocale(countriesPL)

export default countriesIsoList
