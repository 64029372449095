import path from 'path'
import React, { useContext } from 'react'
import Helmet from 'react-helmet'
import { MediaType, PreloadMedia } from 'react-preload-media'
import ChannelContext from '../../context/ChannelContext'
import { getHostname } from '../../utils/link'
import ErrorBoundary from '../ErrorBoundary'
import LoadingSpinner from '../LoadingSpinner'

type Props = {
  file: string
  type?: 'img' | 'image'
  alt?: string
  withoutTheme?: boolean
  classes?: string
  external?: boolean
  loadinWidth?: number
  loadingHeight?: number
  noEmpty?: true
  loaderRelative?: boolean
  width?: number | string
  height?: number | string
  title?: string
}

const Asset = (props: Props) => {
  const channel = useContext(ChannelContext)
  const { type, file, alt, withoutTheme, classes, width, height, noEmpty, external, title } = props
  const assetUrl = withoutTheme ? '/' : `/themes/${channel.main.theme}/`
  const url = external ? file : path.join(assetUrl, file)
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const preloadMedia = [{ type: MediaType.Image, url }]

  let content = null
  switch (type) {
    case 'img':
    case 'image':
      content = <img src={url} alt={alt} title={title} className={classes} width={width} height={height} /> //onLoad={onImageLoaded}
      break

    default:
      content = url
      break
  }
  return (
    <ErrorBoundary>
      {loaded ? (
        content
      ) : (
        <PreloadMedia media={preloadMedia} onFinished={() => setLoaded(true)}>
          <LoadingSpinner imageLoader relative={props.loaderRelative} />
        </PreloadMedia>
      )}
    </ErrorBoundary>
  )
}

export default Asset
