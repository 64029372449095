import React, { ReactNode } from 'react'
import styles from './SizeGuideContainer.module.scss'
import FormattedMessage from '../../FormattedMessage'

type Props = {
  children: ReactNode
}

const SizeGuideContainer = (props: Props) => {
  return (
    <div className={styles['size-guide']}>
      <h1 className={styles['header']}>
        <FormattedMessage id="SizeGuide.Heading" description="SizeGuide.Heading" defaultMessage="Size Guide" />
      </h1>
      <div className={styles['container']}>
        <article className={styles['container__article']}>{props.children}</article>
      </div>
    </div>
  )
}

export default SizeGuideContainer
