import validationMessages from '../../app/i18n/validationMessages'
import { intl } from '../../utils/translations'
import Yup from '../../utils/yup'

const schema = Yup.object().shape({
  email: Yup.string()
    .ensure()
    .required(intl?.formatMessage(validationMessages.schemaFieldRequired))
    .email(intl?.formatMessage(validationMessages.schemaWrongEmail))
    .trim(),
})

export default schema
