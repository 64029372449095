import axiosLib, { AxiosError as _AxiosError, AxiosResponse as _AxiosResponse } from 'axios'
import _ from 'lodash'
import { getHostname } from './link'
import store from '../services/store'

export type AxiosResponse = _AxiosResponse //| undefined
export type AxiosError = _AxiosError
export type AxiosSuccessCallback = (response: AxiosResponse) => void
export type AxiosErrorCallback = (response: AxiosError) => void
export const AXIOS_ERR_NETWORK = 'ERR_NETWORK'
export const AXIOS_CODE_BAD_REQ = 400
export const AXIOS_CODE_NOT_ACCEPT = 406
export const AXIOS_CODE_FORRBIDDEN = 403
export const AXIOS_CODE_UNPROCESSABLE = 422
export const AXIOS_CODE_NOT_FOUND = 404
export const AXIOS_CODE_TOO_MANY_REQUESTS = 429

//add own prop to config
declare module 'axios' {
  export interface AxiosRequestConfig {
    panelApi?: boolean
  }
}

//console.log('axios', process.env.REACT_APP_API_URL)
const axios = axiosLib.create({
  //TODO: url from ENV
  baseURL: process.env.REACT_APP_API_URL?.length! > 0 ? process.env.REACT_APP_API_URL : `${getHostname()}/api`,
  timeout: 120000,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

const encodePram = (param: string) => {
  //prevent double encoding
  return encodeURIComponent(decodeURIComponent(param))
}

axios.defaults.paramsSerializer = params => {
  let result = ''
  Object.keys(params).forEach(key => {
    result += `${key}=${encodePram(params[key])}&`
  })
  return result.substr(0, result.length - 1)
}

axios.interceptors.request.use(config => {
  //console.log({ config }, process.env.REACT_APP_API_URL)
  const currentChannel = store.getState().global?.channels?.current!
  if (!config.params) config.params = {}
  if (currentChannel) config.params.channel = currentChannel?.id!
  if (config.panelApi) {
    config.baseURL = process.env.REACT_APP_PANEL_API_URL
  }
  return config
})

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (_.includes([403], _.get(error, 'response.status', ''))) {
      console.log('UNAUTH ' + error.response.status)
      return Promise.resolve(error)
    }

    return Promise.reject(error)
  },
)

export default axios
