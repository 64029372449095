import classNames from 'classnames'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styles from './MobileMenu.module.scss'
import { MenuItemInterface } from '../../../interfaces/MenuInterface'
import Link from '../../Link'

type Classes = {
  root?: string
}

type Props = {
  classes?: Classes
  items: MenuItemInterface[]
  parentItem: MenuItemInterface | null
  activeItem: MenuItemInterface | null
  isBackClicked: boolean
  onItemClick: (event: React.SyntheticEvent) => void
  onBackClick: () => void
  onAnimExited: () => void
}

const MobileMenu = (props: Props) => {
  const parentId = props.parentItem != null ? props.parentItem.id : null
  const animClassType = props.isBackClicked ? 'prev' : 'next'
  const animClasses = {
    enter: styles[`goto-${animClassType}-enter`],
    exit: styles[`goto-${animClassType}-exit`],
    enterActive: styles[`goto-${animClassType}-enter-ctive`],
    enterDone: styles[`goto-${animClassType}-enter-done`],
    exitActive: styles[`goto-${animClassType}-exit-active`],
    exitDone: styles[`goto-${animClassType}-exit-done`],
  }

  const menu = props.items
    .filter(item => item.parentId === parentId)
    .map(item => {
      const labelClasses = classNames(styles['mobile-menu__label'], {
        [styles['mobile-menu__label--active']]: props.activeItem != null && item.link === props.activeItem.link,
      })

      return (
        <li key={item.link} className={styles['mobile-menu__item']}>
          <Link to={item.link} className={styles['mobile-menu__link']}>
            {item.image != null ? (
              <img alt="Menu" data-link={item.link} onClick={props.onItemClick} src={item.image} className={styles['mobile-menu__image']} />
            ) : (
              <span data-link={item.link} onClick={props.onItemClick} className={labelClasses}>
                {item.anchor}
              </span>
            )}
          </Link>
        </li>
      )
    })

  if (parentId !== null) {
    menu.unshift(
      <li key="back" className={classNames(styles['mobile-menu__item'], styles['mobile-menu__back'])}>
        <a onClick={props.onBackClick} className={styles['mobile-menu__link']}>
          <span className={classNames(styles['mobile-menu__label'], styles['mobile-menu__label--back'])}>Back</span>
        </a>
      </li>,
    )
  }

  return (
    <TransitionGroup component="nav" className={classNames(props.classes?.root)}>
      <CSSTransition key={parentId} timeout={200} classNames={animClasses} onExited={props.onAnimExited}>
        <ul className={styles['mobile-menu']}>{menu}</ul>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default MobileMenu
