/*eslint-disable*/
//disable-sort-imports
//@ts-nocheck

import { To } from 'Link.types'

const cache = {}

function resolveTo(to: To): string {
  if (typeof to === 'string') {
    return to
  }

  if (!to.name) {
    if (!to.pathname) {
      throw new Error('Fill in "name" or "pathname"')
    }

    return to.pathname
  }

  if (to.pathname) {
    throw new Error('Cannot specify both "name" and "pathname"')
  }

  return to.name
}

function escapeParamsDescriptors(route: string): string {
  return route.replace(/(\([^)]+\))/g, '')
}

function resolveRoute(route: string, params: object): string {
  if (route in cache) {
    return cache[route](params)
  }
  const path = escapeParamsDescriptors(route)
  cache[route] = function (params: object) {
    let result = path

    Object.keys(params).forEach(key => {
      const regExp = new RegExp(`:${key}`, 'g')
      result = result.replace(regExp, params[key])
    })
    return result
  }
  return cache[route](params)
}

export { resolveTo, resolveRoute, escapeParamsDescriptors }
