import _ from 'lodash'
import React from 'react'
import { FormattedMessage as FormattedMessageIntl, useIntl } from 'react-intl'

interface Props {
  id?: string
  oldId?: string
  className?: string
  tag?: React.ElementType<any>
  values?: { [key: string]: any }
  ourId?: string
  description?: string
  defaultMessage?: string
  onClick?: () => void
  onMouseOver?: () => void
  onMouseLeave?: () => void
}

const FormattedMessage = React.forwardRef((props: Props, ref?: React.Ref<React.ComponentType<Props>>) => {
  const intl = useIntl()
  const tag = props.tag || 'div'
  const id = props.ourId || props.id || props.description
  const { description, defaultMessage } = props

  const msg = intl.formatMessage(
    {
      id,
      description,
      defaultMessage,
    },
    props.values,
  )
  const opt = { ref, ..._.omit(props, ['id', 'tag', 'description', 'defaultMessage', 'oldId', 'ourId', 'values']) }
  return React.createElement(tag, opt, [msg])
  return (
    <FormattedMessageIntl
      description={props.description}
      defaultMessage={props.defaultMessage}
      id={id}
      tagName={tag}
      values={props.values!}
    />
  )
})

export default FormattedMessage
