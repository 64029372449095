/* eslint-disable global-require */

export function loadLocaleData(locale: string): Record<string, string> {
  switch (locale) {
    case 'pl-PL':
      return require('../i18n/compiled/pl.json')
    // case 'en-AU':
    //   return require('../i18n/compiled/en-AU.json')
    default:
      return require('../i18n/compiled/en.json')
  }
}
