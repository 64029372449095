//COPY from backend
export enum TranslationStatus {
  'INACTIVE' = 0,
  'ACTIVE' = 1,
}

export enum TranslationModule {
  'CMS' = 0,
  'BLOG' = 1,
}

export enum TranslationType {
  'TITLE' = 0,
  'SEO_TITLE' = 1,
  'SEO_DESCRIPTION' = 2,
  'SLUG' = 3,
  'CONTENT' = 4,
}

export interface TranslationInterface {
  id?: number
  referenceId: number
  module: TranslationModule
  status: TranslationStatus
  type: TranslationType
  language: string
  content: string
  deletedAt?: Date
  createdAt?: Date
  updatedAt?: Date
}
// END

export interface CMSPageInterface {
  id: number
  content: string
  authorId: number | null
  language: string
  name: string
  seoDescripton: string
  seoTitle: string
  slug: string
  title: string | null
  createdAt: string | null
  updatedAt: string | null
  deletedAt: string | null
  translations: TranslationInterface[]
}

export default CMSPageInterface
