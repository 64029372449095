import React, { useContext, useEffect } from 'react'
import UpSellDesktopModal from './UpSellDesktopModal/UpSellDesktopModal'
import UpSellMobileModal from './UpSellMobileModal/UpSellMobileModal'
import UpSellContext from '../../../context/UpSellContext'
import useMatchMedia from '../../../hooks/matchMedia/matchMedia'
import { CatalogListItemInterface } from '../../../interfaces/CatalogInterface'

interface Props {
  promotionProducts?: CatalogListItemInterface[]
}

const UpSellModal: React.FC<Props> = (props: Props) => {
  const { promotionProducts } = props
  const upSell = useContext(UpSellContext)
  const { isDesktop } = useMatchMedia()

  useEffect(() => {
    if (upSell.showModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [upSell.showModal])

  return isDesktop ? <UpSellDesktopModal promotionProducts={promotionProducts} /> : <UpSellMobileModal />
}

export default UpSellModal
