import ClubInterface from '../../interfaces/ClubInterface'

export interface ClubCacheData {
  club: ClubInterface | null
  isAuthenticated: boolean
}

const CLUB_STORAGE_KEY = 'ClubCache'

const emptyClubCache: ClubCacheData = {
  club: null,
  isAuthenticated: false,
}

const ClubCacheManager = {
  save: (data: ClubCacheData) => {
    try {
      const serializedData = JSON.stringify(data)
      localStorage.setItem(CLUB_STORAGE_KEY, serializedData)
    } catch (err) {
      console.error('Error while saving data to localStorage', err)
    }
  },

  load: (): ClubCacheData => {
    try {
      const serializedData = localStorage.getItem(CLUB_STORAGE_KEY)

      if (serializedData === null) {
        return emptyClubCache
      }

      const parsedData = JSON.parse(serializedData)

      if (
        typeof parsedData === 'object' &&
        typeof parsedData.isAuthenticated === 'boolean' &&
        (parsedData.club === null || typeof parsedData.club === 'object')
      ) {
        return parsedData as ClubCacheData
      }

      throw new Error('Invalid data structure')
    } catch (err) {
      console.log('Error while loading data from localStorage', err)
    }

    return emptyClubCache
  },

  remove: () => {
    localStorage.removeItem(CLUB_STORAGE_KEY)
  },
}

export default ClubCacheManager
