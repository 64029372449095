import classNames from 'classnames'
import React, { ReactNode } from 'react'
import styles from './ModalButton.module.scss'

type Props = {
  leftIcon?: ReactNode
  children: ReactNode
  classes?: {
    root?: string
  }
  onClick?: () => void
  active?: boolean
}

const ModalButton = (props: Props) => {
  const { classes, onClick, active, leftIcon, children } = props
  const btnClass = {
    [styles['modal-button--clickable']]: onClick,
    [styles['modal-button--active']]: active,
    [styles['modal-button--inactive']]: !active,
  }
  return (
    <button type="button" className={classNames(styles['modal-button'], classes?.root!, btnClass)} onClick={onClick}>
      {leftIcon}
      <span className={styles['modal-button__content']}>{children}</span>
    </button>
  )
}

export default ModalButton
