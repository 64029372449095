import { combineReducers } from 'redux'
import app, { State as AppState } from './app/app.reducer'
import cart, { State as CartState } from './cart/cart.reducer'
import global, { StateInterface as GlobalState } from './global/global.reducer'
import locale, { StateInterface as LocaleState } from './locale/locale.reducer'
import notification from './notification/notification.reducer'
import { NotificationInterface } from './notification/notification.types'
import user, { State as UserState } from './user/user.reducer'

export default combineReducers({
  user,
  cart,
  notification,
  global,
  locale,
  app,
})

export interface State {
  global: GlobalState
  user: UserState
  cart: CartState
  locale: LocaleState
  notification: NotificationInterface
  app: AppState
}
