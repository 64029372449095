import { defineMessages } from 'react-intl'

const messages = defineMessages({
  newPassword: {
    id: 'SetNewPassword.NewPassword',
    defaultMessage: 'New password',
  },
  newPasswordConfirm: {
    id: 'SetNewPassword.RepeatPassword',
    defaultMessage: 'Confirm new password',
  },
})

export default messages
