import { AddressFormatInterface, AddressFormatMapInterface } from '../../interfaces/AddressInterface'
import { CurrencyInterface, CurrencyMapInterface } from '../../interfaces/CurrencyInterface'
import { LanguageInterface, LanguageMapInterface } from '../../interfaces/LanguageInterface'

export const ADDRESS_FORMAT_FALLBACK_KEY = 'DEFAULT'

export interface LocaleInterface {
  currencies: CurrencyMapInterface
  languages: LanguageMapInterface
  language: LanguageInterface
  currency: CurrencyInterface
  addressFormats: AddressFormatMapInterface
}

export enum types {
  UPDATE_LANGUAGE_REQUEST = 'LOCALE_UPDATE_LANGUAGE_REQUEST',
  UPDATE_LANGUAGE_SUCCESS = 'LOCALE_UPDATE_LANGUAGE_SUCCESS',
  UPDATE_CURRENCY_REQUEST = 'LOCALE_UPDATE_CURRENCY_REQUEST',
  UPDATE_CURRENCY_SUCCESS = 'LOCALE_UPDATE_CURRENCY_SUCCESS',
  READ_CONFIG_SUCCESS = 'LOCALE_READ_CONFIG_SUCCESS',
  READ_ADDRESS_FORMATS_SUCCESS = 'LOCALE_READ_ADDRESS_FORMATS_SUCCESS',
}

export interface LanguageUpdateRquestInterface {
  type: types.UPDATE_LANGUAGE_REQUEST
  payload: LanguageInterface
}

export interface LanguageUpdateSuccessInterface {
  type: types.UPDATE_LANGUAGE_SUCCESS
  payload: LanguageInterface
}

export interface CurrencyUpdateRequestInterface {
  type: types.UPDATE_CURRENCY_REQUEST
  payload: CurrencyInterface
}

export interface CurrencyUpdateSuccessInterface {
  type: types.UPDATE_CURRENCY_SUCCESS
  payload: CurrencyInterface
}

export interface ConfigReadSuccessInterface {
  type: types.READ_CONFIG_SUCCESS
  payload: LocaleInterface
}

export interface AddressFormatsReadSuccessInterface {
  type: types.READ_ADDRESS_FORMATS_SUCCESS
  payload: AddressFormatMapInterface
}

export type ActionTypes =
  | LanguageUpdateRquestInterface
  | LanguageUpdateSuccessInterface
  | CurrencyUpdateRequestInterface
  | CurrencyUpdateSuccessInterface
  | ConfigReadSuccessInterface
  | AddressFormatsReadSuccessInterface

//TODO: update readme convetion, this is right one
