import { defineMessages } from 'react-intl'

const messages = defineMessages({
  wrongQuantity: {
    id: 'Cart.GivenQuantityCannotBeProcessed',
    defaultMessage: 'Given quantity cannot be processed.',
  },
  productAdded: {
    id: 'Cart.ProductWasAddedToCart',
    defaultMessage: 'Product was added to cart.',
  },
  productAdjusted: {
    id: 'Cart.ProductQuantityWasChanged',
    defaultMessage: 'Product quantity was changed.',
  },
  productRemoved: {
    id: 'Cart.ProductWasRemoved',
    defaultMessage: 'Product was removed from cart.',
  },
  productAdjustedError: {
    id: 'Cart.ProductQuantityWasChangedError',
    defaultMessage: "Hurry! Only {quantity} of the {productName} available to order. Don't miss out!",
  },
})

export default messages
