import React from 'react'
import ClubInterface from '../interfaces/ClubInterface'

interface ClubContextType {
  club: ClubInterface | null
  isInClubFlow: boolean
  isAuthenticated: boolean
  isLoading: boolean
  shouldDisplayLoginForm: boolean
  loginError: string | null
  authenticate: (password: string) => Promise<void>
  logout: () => void
}

const defaultClubContextValue: ClubContextType = {
  club: null,
  isInClubFlow: false,
  isAuthenticated: false,
  isLoading: false,
  shouldDisplayLoginForm: false,
  loginError: null,
  authenticate: async () => {},
  logout: () => {},
}

const ClubContext = React.createContext<ClubContextType>(defaultClubContextValue)

export default ClubContext
