import React from 'react'
import styles from './SidebarTabLocalization.module.scss'
import SwitchPanel, { DISPLAY_MODE } from '../../Localization/SwitchPanel'

type Props = {
  onChange: () => void
}

const SidebarTabLocalization = (props: Props) => {
  return (
    <div className={styles['container']}>
      <SwitchPanel type={DISPLAY_MODE.MOBILE} onChange={props.onChange} />
    </div>
  )
}

export default SidebarTabLocalization
