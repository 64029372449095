import { AppInterface } from '../../interfaces/AppInterface'

export enum types {
  READ_APP_STATE_REQUEST = 'APP_READ_APP_STATE_REQUEST',
  READ_APP_STATE_SUCCESS = 'APP_READ_APP_STATE_SUCCESS',
}

export interface AppStateReadRquestInterface {
  type: types.READ_APP_STATE_REQUEST
  payload: {
    languageIsoCode?: string
  }
}

export interface AppStateReadSuccessInterface {
  type: types.READ_APP_STATE_SUCCESS
  payload: AppInterface
}

export type ActionTypes = AppStateReadRquestInterface | AppStateReadSuccessInterface
