import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import styles from './MegaMenuView.module.scss'
import { MenuItemInterface } from '../../../../interfaces/MenuInterface'
import ErrorBoundary from '../../../ErrorBoundary'
import Search from '../../../Search'
import MegaMenuItems from '../MegaMenuItems'

type Props = {
  className: string
  items: MenuItemInterface[]
  activeItem: MenuItemInterface | null
  activeItemIds: number[]
  shownLink: string | null
  isSearchBarVisible?: boolean
  handleItemClick: (event: React.MouseEvent<HTMLElement>) => void
  handleMenuClose: () => void
  handleMenuOpen: (event: React.MouseEvent<HTMLLIElement>) => void
  handleSearchClose: () => void
  handleSearchIconClick: () => void
  selfRef?: React.RefObject<HTMLDivElement>
}

const MegaMenuView = (props: Props) => {
  const {
    className,
    items,
    activeItem,
    activeItemIds,
    handleItemClick,
    handleMenuClose,
    handleMenuOpen,
    shownLink,
    isSearchBarVisible,
    handleSearchClose,
    handleSearchIconClick,
    selfRef,
  } = props
  const intl = useIntl()
  const closeIconClasses = classNames(styles['btn-icon'], styles['btn-icon--clickable'], styles['btn-search-close'])
  const searchIconClasses = classNames(styles['btn-icon'], styles['search-icon'], styles['btn-icon--clickable'])
  const searchBarClasses = classNames(styles['search-bar'], {
    [`${styles['search-bar--active']}`]: isSearchBarVisible,
  })

  return (
    <nav className={classNames(styles['mega-menu'], 'desktop--visible', className)} ref={selfRef!}>
      <ul className={styles['mega-menu__list']}>
        <MegaMenuItems
          items={items}
          activeItem={activeItem}
          activeItemIds={activeItemIds}
          handleItemClick={handleItemClick}
          handleMenuClose={handleMenuClose}
          handleMenuOpen={handleMenuOpen}
          shownLink={shownLink}
        />
        <ErrorBoundary show={false}>
          <li className={searchBarClasses}>
            <div className={styles['search-bar__container']}>
              <div className={styles['btn-icon']}>{isSearchBarVisible ? null : <SearchIcon />}</div>
              <label htmlFor="search-bar" className={styles['search-bar__label']}>
                {intl.formatMessage({ id: 'MegaMenu.Search', defaultMessage: 'Search' })}
              </label>
              <Search
                classes={{ root: styles['search'], input: styles['search__input'] }}
                onClose={handleSearchClose}
                isVisible={isSearchBarVisible}
              />
              <div className={closeIconClasses} onClick={handleSearchClose}>
                <CloseIcon classes={{ root: styles['close-icon'] }} />
              </div>
            </div>
          </li>
          {isSearchBarVisible ? null : (
            <li className={searchIconClasses} onClick={handleSearchIconClick}>
              <SearchIcon />
            </li>
          )}
        </ErrorBoundary>
      </ul>
    </nav>
  )
}

export default MegaMenuView
