import { Dialog, DialogTitle } from '@mui/material'
import { useFormik } from 'formik'
import * as React from 'react'
import ChannelContext from '../../../context/ChannelContext'
import usePrivacyConsent from '../../../hooks/privacyConsent/privacyConsent'
import PrivacyConsentInterface from '../../../interfaces/PrivacyConsentInterface'
import FormattedMessage from '../../FormattedMessage/FormattedMessage'
import PrivacyConsentDescriptionDialogView from '../PrivacyConsentDescriptionDialogView/PrivacyConsentDescriptionDialogView'
import PrivacyConsentDetailsView from '../PrivacyConsentDetailsDialogView/PrivacyConsentDetailsDialogView'

enum PrivacyConsentView {
  DESCRIPTION,
  DETAILS,
}

const PrivacyConsentDialog = () => {
  const channel = React.useContext(ChannelContext).current
  const [view, setView] = React.useState<PrivacyConsentView>(PrivacyConsentView.DESCRIPTION)
  const [open, setOpen] = React.useState<boolean>(false)
  const privacyConsents = usePrivacyConsent()

  React.useEffect(() => {
    if (privacyConsents.isPrivacyConsentRequired && !privacyConsents.privacyConsents) {
      setOpen(true)
    }
  }, [privacyConsents.isPrivacyConsentRequired, privacyConsents.privacyConsents])

  const formik = useFormik({
    initialValues: privacyConsents.default,
    onSubmit: (values: PrivacyConsentInterface) => {
      privacyConsents.saveConsents(values)
      setOpen(false)
    },
  })

  const handleAccept = () => {
    privacyConsents.saveConsents(privacyConsents.default)
    setOpen(false)
  }

  const handleSave = () => {
    formik.submitForm()
  }

  const showMoreInformation = () => {
    setView(PrivacyConsentView.DETAILS)
  }

  let content
  switch (view) {
    case PrivacyConsentView.DETAILS:
      content = <PrivacyConsentDetailsView formik={formik} onSubmit={handleSave} />
      break
    case PrivacyConsentView.DESCRIPTION:
    default:
      content = <PrivacyConsentDescriptionDialogView onAccept={handleAccept} onShowMoreInformation={showMoreInformation} />
      break
  }

  return (
    <Dialog open={open} disableEscapeKeyDown fullWidth className={`${channel.theme}-theme`}>
      <DialogTitle>
        <FormattedMessage id="PrivacyConsentDialog.Title" defaultMessage="Your Cookie Settings" />
      </DialogTitle>
      {content}
    </Dialog>
  )
}

export default PrivacyConsentDialog
