import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import classNames from 'classnames'
import { FormikHelpers, useFormik } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import styles from './NewsletterFormView.module.scss'
import useMatchMedia from '../../../hooks/matchMedia'
import FormattedMessage from '../../FormattedMessage'
import FormTextField from '../../FormTextField'
import validationSchema from '../NewsletterForm.schema'

type Props = {
  expanded: boolean
  closed: boolean
  onExpand: () => void
  onClose: () => void
  onFormSubmit: (values: FormikValues, formik: FormikHelpers<FormikValues>) => void
}

export type FormikValues = {
  email: string
}

const GTM_CLASS_SUBSCRIBE = 'gtm-subscribe'

const NewsletterFormView = (props: Props) => {
  const intl = useIntl()
  const { isDesktop } = useMatchMedia()
  const { expanded, closed, onExpand, onClose, onFormSubmit } = props
  const newsletterClass = classNames(styles['newsletter'], {
    [`${styles['newsletter--fixed']}`]: !closed && !isDesktop,
  })
  const formClass = classNames(styles['newsletter__form'], {
    [styles['newsletter__form--expanded']]: expanded,
  })
  const iconButtonCssClass = classNames(styles['newsletter__close-icon'], {
    [styles['newsletter__close-icon--visible']]: !expanded && !closed,
  })
  const newsletterTitleCssClass = classNames(styles['newsletter__title'], {
    [styles['newsletter__title--expanded']]: expanded,
  })

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values: FormikValues, formikBag: FormikHelpers<FormikValues>) => onFormSubmit(values, formikBag),
    validationSchema: validationSchema(intl),
    validateOnBlur: false,
  })

  return (
    <div className={newsletterClass}>
      <span onClick={onExpand} className={newsletterTitleCssClass}>
        <FormattedMessage id="Newsletter.Subscribe" description="Newsletter.Subscribe" defaultMessage="Subscribe" />
      </span>
      <IconButton onClick={onClose} className={iconButtonCssClass} size="large">
        <CloseIcon />
      </IconButton>
      <form
        className={formClass}
        autoComplete="off"
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event?.preventDefault()
          event?.stopPropagation()
          formik.submitForm()
        }}
      >
        <FormattedMessage
          id="Newsletter.WhySubscribe"
          description="Newsletter.WhySubscribe"
          defaultMessage="Sign up to get latest news."
          className={styles['newsletter__label']}
        />
        <FormTextField
          label={
            <FormattedMessage id="Newsletter.EmailAddress" description="Newsletter.Label.EmailAddress" defaultMessage="E-mail address" />
          }
          form={formik}
          formControlProps={{
            classes: {
              root: styles['newsletter__email'],
            },
          }}
          field={{
            name: 'email',
            value: formik.values.email,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          }}
        />
        <IconButton type="submit" className={classNames(styles['newsletter__button'], GTM_CLASS_SUBSCRIBE)} size="large">
          <ChevronRightIcon />
        </IconButton>
      </form>
    </div>
  )
}

export default NewsletterFormView
