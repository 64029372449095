import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material'
import { FormControlProps } from '@mui/material/FormControl'
import { SelectProps } from '@mui/material/Select'
import _ from 'lodash'
import * as React from 'react'

type Props = SelectProps & {
  formControlProps?: FormControlProps
  helperText?: any
  classes?: {
    label?: string
    select?: string
  }
}
const SelectField: React.FC<Props> = (props: any) => {
  return (
    <FormControl {...props.formControlProps}>
      <InputLabel htmlFor={props.name} classes={props.classes?.label} variant="outlined">
        {props.label}
      </InputLabel>
      <Select
        classes={{ select: props.classes?.select }}
        MenuProps={{
          container: () => document.querySelector('#app > .themed'),
          disablePortal: true,
        }}
        {..._.omit(props, 'formControlProps', 'helperText')}
        variant="outlined"
      />
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </FormControl>
  )
}
export default SelectField
