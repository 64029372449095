import { AxiosPromise } from 'axios'
import axios from '../../utils/axios'

export function readAppConfig() {
  return axios({
    method: 'GET',
    url: '/init.json',
  })
}

export function readRedirectFrom(from: string, domain: string) {
  return axios({
    panelApi: true,
    method: 'POST',

    url: '/api/redirects/search',
    data: {
      from,
      domain,
    },
  })
}
