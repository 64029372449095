import { SelectChangeEvent } from '@mui/material'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './CurrencySwitcher.module.scss'
import * as actions from '../../../services/locale/locale.actions'
import { State } from '../../../services/reducers'
import { getCurrencyByCode } from '../../../utils/locale'
import FormattedMessage from '../../FormattedMessage/FormattedMessage'
import SwitcherBase from '../SwitcherBase/SwitcherBase'

type Props = {
  onChange?: () => void
  classes?: {
    root?: string
  }
}

const CurrencySwitcher = (props: Props) => {
  const { classes } = props
  const dispatch = useDispatch()
  const currency = useSelector((state: State) => state.locale.currency)
  const currencies = useSelector((state: State) => state.locale.currencies)
  const onChange = (event: SelectChangeEvent<any>) => {
    const isoCode = event.target.value as string
    dispatch(actions.updateCurrencyRequest(getCurrencyByCode(isoCode)))
    props.onChange ? props.onChange() : _.noop()
  }

  return (
    <SwitcherBase
      classes={{
        root: classNames(styles['currency'], classes?.root!),
      }}
      onSelected={onChange}
      items={Object.values(currencies)}
      value={currency.isoCode}
      itemValueKey="isoCode"
      itemNameKey="name"
      name="currency-switcher"
      label={<FormattedMessage id="CurrencySwitcher.Label" description="CurrencySwitcher.Label" defaultMessage="Currency" />}
      readOnly
    />
  )
}

export default CurrencySwitcher
