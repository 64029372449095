import {
  ActionTypes,
  types,
  NotificationCreateRequestInterface,
  NotificationInterface,
  NotifyOptionsInterface,
  NotifyElement,
} from './notification.types'

export function notificationCreateRequest(
  id: number | string,
  type: string,
  content: NotifyElement,
  options: NotifyOptionsInterface,
): ActionTypes {
  return <NotificationCreateRequestInterface>{
    type: types.NOTIFICATION_CREATE_REQUEST,
    payload: {
      id,
      type,
      content,
      options,
    },
  }
}

export function notificationDeleteRequest(id: number) {
  return {
    type: types.NOTIFICATION_DELETE_REQUEST,
    payload: id,
  }
}

export function notificationsDeleteRequest() {
  return {
    type: types.NOTIFICATIONS_DELETE_REQUEST,
  }
}
