export const getConfirmation = (win: Window, message: string, callback: (confirmed: boolean) => {}) => callback(win.confirm(message))

export const isExtraneousPopstateEvent = (nav: Navigator, event: any) => event.state === undefined && nav.userAgent.indexOf('CriOS') === -1

/**
 * Returns true if the HTML5 history API is supported. Taken from Modernizr.
 *
 * https://github.com/Modernizr/Modernizr/blob/master/LICENSE
 * https://github.com/Modernizr/Modernizr/blob/master/feature-detects/history.js
 * changed to avoid false negatives for Windows Phones: https://github.com/reactjs/react-router/issues/586
 */
export const supportsHistory = (win: Window) => {
  const ua = win.navigator.userAgent

  if (
    (ua.indexOf('Android 2.') !== -1 || ua.indexOf('Android 4.0') !== -1) &&
    ua.indexOf('Mobile Safari') !== -1 &&
    ua.indexOf('Chrome') === -1 &&
    ua.indexOf('Windows Phone') === -1
  ) {
    return false
  }

  return win.history && 'pushState' in win.history
}

/**
 * Returns true if browser fires popstate on hash change.
 * IE10 and IE11 do not.
 */
export const supportsPopStateOnHashChange = (nav: Navigator) => nav.userAgent.indexOf('Trident') === -1
