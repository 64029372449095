import _ from 'lodash'
import ChannelInterface from '../../../interfaces/ChannelInterface'
import store from '../../../services/store'
/**
 * mainChannel - always main channel for main domain with path /
 * oppositeChannel -always second channel for main domain but with prefix in path
 * currentChannel - channel currently active, it ma by mainChannel or oppositeChannel
 * alteranteChannel - another one, diffrent from currentChannel
 */

const CHANNEL_PATH_FK = '/fk'
const CHANNEL_PATH_FT = '/ft'
const CHANNEL_PATH_MAIN = '/'

class ChannelResolver {
  private readonly channels: ChannelInterface[] = []

  constructor(_channels: ChannelInterface[]) {
    this.channels = _channels
  }

  getCurrent(): ChannelInterface | null {
    const host = window?.location?.hostname!
    const location = window?.location!
    let currentChannel: ChannelInterface | null = null

    this.channels?.forEach(channel => {
      if (!channel.active || channel.domain !== host) return
      if (!channel.active || !host.startsWith(channel.domain)) return
      if (
        channel.path === CHANNEL_PATH_MAIN &&
        !location.pathname.startsWith(CHANNEL_PATH_FT) &&
        !location.pathname.startsWith(CHANNEL_PATH_FK)
      ) {
        currentChannel = channel
      } else if (channel.path !== CHANNEL_PATH_MAIN && location.pathname.startsWith(channel.path)) {
        currentChannel = channel
      }
    })

    return currentChannel
  }

  getAlternate(): ChannelInterface | null {
    const main = this.getMain()
    const opposite = this.getOpposite()
    const current = this.getCurrent()

    if (!current || !main || !opposite) return null

    return current.id === main.id ? opposite : main
  }

  getMain(): ChannelInterface | null {
    const host = window?.location?.host!
    let mainChannel: ChannelInterface | null = null

    this.channels?.forEach(channel => {
      if (!channel.active) return
      if (host.startsWith(channel.domain) && channel.path === CHANNEL_PATH_MAIN) {
        mainChannel = channel
      }
    })

    return mainChannel
  }

  getOpposite(): ChannelInterface | null {
    const host = window?.location?.host!
    let oppositeChannel: ChannelInterface | null = null

    this.channels?.forEach(channel => {
      if (!channel.active) return
      if (host.startsWith(channel.domain) && channel.path !== CHANNEL_PATH_MAIN) {
        oppositeChannel = channel
      }
    })

    return oppositeChannel
  }
}

export default ChannelResolver
