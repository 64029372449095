import axios from '../../utils/axios'

export function readProduct(id: number) {
  return axios({
    method: 'GET',
    url: `/product/${id}.json`,
  })
}

export function readCrossProducts(id: number) {
  return axios({
    method: 'GET',
    url: `/product/${id}/cross-sell.json`,
  })
}

export function readUpSellProducts(id: number) {
  return axios({
    method: 'GET',
    url: `/product/${id}/up-sell.json`,
  })
}

export function readTopSellProducts() {
  return axios({
    method: 'GET',
    url: '/product/topsell.json',
  })
}
