import CategoryInterface from './CategoryInterface'
import { DescriptionInterface } from './DescriptionInterface'
import { ProductFiltersInterface } from './FiltersInterface'
import { PhotosInterface } from './PhotoInterface'
import { PriceInterface, ProductInterface } from './ProductInterface'
import { TranslationsMapInterface } from './TranslationInterface'
import { To } from '../components/Link/Link.types'

export enum CatalogItemType {
  Product = 'product',
  Category = 'category',
}

export interface CatalogInterface {
  items: CatalogListItemInterface[]
  pager: {
    itemCount: number
    pageCount: number
    currentPage: number
    perPage: number
  }
  filters: ProductFiltersInterface
}

export interface CatalogListItemInterface extends ProductInterface {
  id: number
  description: DescriptionInterface
  to?: To
  itemType: CatalogItemType
  name?: string
  translations: TranslationsMapInterface
  breadcrumbs: CategoryInterface[]
  slug?: TranslationsMapInterface
}

export interface CatalogListProductPriceInterface {
  currency: string
  grossPrice: string
}

export interface CatalogListProductInterface extends CatalogListItemInterface {
  pricing: PriceInterface[]
  variants: ProductInterface[]
  photos?: PhotosInterface
}

export interface CatalogListSubcategoryInterface extends CatalogListItemInterface {}

export default CatalogInterface
