import { Button } from '@mui/material'
import { getName } from 'i18n-iso-countries'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import styles from './RecommendChannel.module.scss'
import { State } from '../../services/reducers'

const STORAGE_RECOMMEND_CHANNEL_HIDDEN = 'STORAGE_RECOMMEND_CHANNEL_HIDDEN'

export const RecommendChannel = () => {
  const language = useSelector((store: State) => store.locale?.language)
  const countryByIp = useSelector((store: State) => store.global.countryByIp)
  const recommendedChannel = useSelector((store: State) => store.global.recommendedChannel)
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_RECOMMEND_CHANNEL_HIDDEN) && countryByIp && recommendedChannel) {
      setShowModal(true)
    }
  }, [])

  if (!countryByIp || !recommendedChannel || !showModal) {
    return null
  }

  const onClose = () => {
    setShowModal(false)
    localStorage.setItem(STORAGE_RECOMMEND_CHANNEL_HIDDEN, 'true')
  }

  const onProceed = () => {
    window.location.href = recommendedChannel.url
  }

  return (
    <div className={styles['recommend_channel__dialog']}>
      <div className={styles['dialog__message']}>
        <FormattedMessage
          id="RecommendChannel.Message"
          defaultMessage="It appears you are located in {country}, do you want to switch to our {name} store? 
            We offer currency and shipping methods specific to each store."
          values={{
            country: getName(countryByIp, language.shortIsoCode),
          }}
        />
      </div>
      <div className={styles['dialog__actions']}>
        <Button onClick={onClose} color="primary" variant="contained" className={styles['actions__button']}>
          <FormattedMessage id="RecommendChannel.StayHere" defaultMessage="Stay here" />
        </Button>
        <Button onClick={onProceed} color="info" variant="contained" className={styles['actions__button']}>
          <FormattedMessage
            id="RecommendChannel.ProceedThere"
            defaultMessage="Proceed to {link} shop"
            values={{
              link: recommendedChannel.name,
            }}
          />
        </Button>
      </div>
    </div>
  )
}

export default RecommendChannel
