import React, { useContext } from 'react'
import PageLayout from '../../../components/PageLayout/PageLayout'
import SizeGuideContainer from '../../../components/SizeGuide/SizeGuideContainer/SizeGuideContainer'
import SizeGuideMobileTabs from '../../../components/SizeGuide/SizeGuideMobileTabs/SizeGuideMobileTabs'
import SizeGuideTabs from '../../../components/SizeGuide/SizeGuideTabs/SizeGuideTabs'
import ChannelContext from '../../../context/ChannelContext'
import useMatchMedia from '../../../hooks/matchMedia/matchMedia'
import useSizeGuide from '../../../hooks/sizeGuide/sizeGuide'
import { menOrder, womenOrder } from '../../../hooks/sizeGuide/sizeGuideConstants'

const SizeGuide = () => {
  const { isDesktop } = useMatchMedia()
  const channels = useContext(ChannelContext)
  const sizeGuide = useSizeGuide()

  if (!sizeGuide.sizes) return null

  const sizesByOrder = channels.current.isWomenShop ? womenOrder : menOrder

  return (
    <PageLayout>
      <SizeGuideContainer>
        {isDesktop ? (
          <SizeGuideTabs sizesByOrder={sizesByOrder} sizes={sizeGuide.sizes} />
        ) : (
          <SizeGuideMobileTabs sizesByOrder={sizesByOrder} />
        )}
      </SizeGuideContainer>
    </PageLayout>
  )
}

export default SizeGuide
