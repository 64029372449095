import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material'
import { CheckboxProps } from '@mui/material/Checkbox'
import { FormControlProps } from '@mui/material/FormControl'
import _ from 'lodash'
import React from 'react'

type Props = CheckboxProps & {
  value: boolean
  label?: React.ReactElement | string
  formControlProps?: FormControlProps
  error?: boolean
  helperText?: any
  classes?: {
    root?: string
    labelRoot?: string
    labelText?: string
  }
}

const CheckboxField: React.FC<Props> = (props: Props) => {
  const errorHelper = props.error ? <FormHelperText error>{props.helperText}</FormHelperText> : null
  return (
    <FormControl {...props.formControlProps}>
      <FormControlLabel
        label={props.label!}
        classes={{ root: props.classes?.labelRoot!, label: props.classes?.labelText }}
        control={
          <Checkbox
            color="primary"
            name={props.name}
            value={props.value.toString()}
            checked={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            {..._.omit(props, ['classes', 'value', 'label', 'formControlProps', 'error', 'helperText'])}
            classes={{
              root: props.classes?.root!,
            }}
            id={props.name}
          />
        }
      />
      {errorHelper}
    </FormControl>
  )
}

export default CheckboxField
