import classNames from 'classnames'
import _ from 'lodash'
import React, { forwardRef } from 'react'
import { Link as RouterLink, LinkProps, useLocation } from 'react-router-dom'
import { To } from './Link.types'
import { resolveRoute, resolveTo } from './linkResolver'
import ChannelContext from '../../context/ChannelContext'
import HistoryContext from '../../context/HistoryContext'
import ChannelInterface from '../../interfaces/ChannelInterface'
import HistoryInterface from '../../interfaces/HistoryInterface'
import isDevMode from '../../utils/isDevMode'
import { fixPath } from '../../utils/path'

interface Props extends Partial<LinkProps> {
  to?: To
  classes?: {
    root?: string
  }
  className?: string
  /**
   * only for external links
   */
  newWindow?: boolean
  disabled?: boolean
}

const getLocationPath = (channel: ChannelInterface, history: HistoryInterface, to: string): string => {
  const foundPath =
    window.location.pathname.startsWith(channel.path) || window.location.href.search(`${channel.domain}${channel.path}`) !== -1
  return fixPath(`${channel.path.length > 1 && history.getChannelPath() !== channel.path ? channel.path : ''}${to}`)
}

//const Link: React.FC<Props> = props => {
const Link = forwardRef((props: Props, ref?: React.Ref<React.ComponentType<Props>>) => {
  const toProp: To | undefined = props.to
  const history = React.useContext(HistoryContext)

  const location = useLocation()
  const toParams = typeof toProp === 'object' ? toProp.params || {} : {}
  const isExternal = typeof toProp === 'string' && (toProp.startsWith('http:') || toProp.startsWith('https:'))
  const channel = React.useContext(ChannelContext)
  const classes = classNames(props.classes?.root, props.className!)
  let to: string = typeof toProp === 'string' ? toProp : ''

  try {
    if (to === '' && toProp) {
      const route = resolveTo(toProp)
      to = resolveRoute(route, toParams)
    }
  } catch (error: any) {
    if (isDevMode) {
      throw error
    } else {
      console.warn(error)
    }
  }

  if (props.disabled) {
    return <div className={classes}>{props.children}</div>
  }

  if (!toProp) {
    return (
      <a className={classes} rel="noreferrer">
        {props.children}
      </a>
    )
  }
  return isExternal ? (
    <a href={to} className={classes} data-test="custom" target={props.newWindow ? '_blank' : '_self'} rel="noreferrer">
      {props.children}
    </a>
  ) : (
    <RouterLink {..._.omit(props, ['classes'])} to={getLocationPath(channel.current, history, to)} className={classes!} />
  )
})

export default Link
