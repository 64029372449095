export enum CHANNEL_PARAMS {
  FUNKITA_CONTINUE_SHOPPING_URL = 'funkitaContinueShoppingUrl',
  FUNKITA_SWIMWEAR_URL = 'funkitaSwimwearUrl',
  FUNKITA_GEAR_URL = 'funkitaGearUrl',
  FUNKITA_OUTLET_URL = 'funkitaOutletUrl',
  FUNKITA_KOREA_ORDER_VALUE = 'koreaOrderValue',
  FUNKITA_HEADER_TEXT_LEFT = 'headerTextLeft',
  FUNKITA_HEADER_TEXT_RIGHT = 'headerTextRight',
  FUNKITA_ALLOW_FORCED_SHIPPING_COUNTRY = 'allowForcedShippingCountry',
  FUNKITA_CONSENT_BOX_POPUP = 'consentBoxPopup',
  FUNKITA_US_ORDER_VALUE = 'usOrderValue',
  FUNKITA_CREDIT_CARD_LOGO_TYPE = 'creditCardLogoType',
  FUNKYTRUNKS_CONTINUE_SHOPPING_URL = 'funkytrunksContinueShoppingUrl',
  FUNKYTRUNKS_SWIMWEAR_URL = 'funkytrunksSwimwearUrl',
  FUNKYTRUNKS_GEAR_URL = 'funkytrunksGearUrl',
  FUNKYTRUNKS_OUTLET_URL = 'funkytrunksOutletUrl',
  FUNKYTRUNKS_KOREA_ORDER_VALUE = 'koreaOrderValue',
  FUNKYTRUNKS_HEADER_TEXT_LEFT = 'headerTextLeft',
  FUNKYTRUNKS_HEADER_TEXT_RIGHT = 'headerTextRight',
  FUNKYTRUNKS_ALLOW_FORCED_SHIPPING_COUNTRY = 'allowForcedShippingCountry',
  FUNKYTRUNKS_CONSENT_BOX_POPUP = 'consentBoxPopup',
  FUNKYTRUNKS_US_ORDER_VALUE = 'usOrderValue',
  FUNKYTRUNKS_CREDIT_CARD_LOGO_TYPE = 'creditCardLogoType',
}

export enum CREDIT_CARD_LOGO_TYPE {
  NONE,
  NON_AU,
  AU,
}

export type ChannelConfig = {
  continueShoppingUrl: string
  swimwearUrl: string
  gearUrl: string
  outletUrl: string
  koreaOrderValue: string
  headerTextLeft: string
  headerTextRight: string
  allowForcedShippingCountry: boolean
  consentBoxPopup: boolean
  usOrderValue: string
  creditCardLogoType: CREDIT_CARD_LOGO_TYPE
}
