import ls from '@livesession/sdk'
import qs from 'qs'
import { useContext, useEffect, useRef, useState } from 'react'
import ChannelContext from '../../context/ChannelContext'
import { CatalogInterface, CatalogListItemInterface, CatalogListProductInterface } from '../../interfaces/CatalogInterface'
import PagerInterface from '../../interfaces/PagerInterface'
import {
  SEARCH_RESULTS_KIND,
  SearchHintInterface,
  SearchHintProductInterface,
  SearchProductsInterface
} from '../../interfaces/SearchInterface'
import * as searchApi from '../../services/search/search.api'
import { toCatalogProducts } from '../../transformers/CatalogTransformer'
import { handleErrorResponse } from '../../utils/formErrorHelper'
import { getOppositeShopProductRoute, getProductLink } from '../../utils/link'
import { _at } from '../../utils/translations'

export const RESULTS_PER_PAGE = 12

export const useSearch = () => {
  const [searchResults, setSearchResults] = useState<CatalogListItemInterface[]>([])
  const [searchHints, setSearchHints] = useState<SearchHintInterface[]>([])
  const query = useRef<string>('')
  const { alternate } = useContext(ChannelContext)
  const [cursor, setCursor] = useState<number>(-1)
  const [kind, setKind] = useState<SEARCH_RESULTS_KIND>(SEARCH_RESULTS_KIND.DEFAULT)
  const [pager, setPager] = useState<PagerInterface>()

  useEffect(() => {
    setCursor(-1)
  }, [query.current])

  const productsCallback = (data: SearchProductsInterface) => {
    setSearchResults(data.items)
    setKind(data.type)
    //TODO:? optional add storable results in redux qith unique ID
    if (query.current) {
      ls.track('Search Full', {
        query_str: query.current,
        resultCount_int: data.items.length,
      })
    }
  }

  const transformProducts = (input: SearchProductsInterface) => {
    const items = toCatalogProducts(input.items)
    const results: CatalogListProductInterface[] = items.map((item: CatalogListProductInterface) => {
      const itemTo =
        input.type === SEARCH_RESULTS_KIND.ALTERNATIVE
          ? getOppositeShopProductRoute(alternate?.canonicalDomain!, getProductLink(item.id, _at(false, item.slug!) || 'product'))
          : getProductLink(item.id, _at(false, item.slug!) || 'product')
      return {
        ...item,
        to: itemTo,
      }
    })
    return {
      items: results,
      pager: input.pager,
    }
  }

  const getProducts = async (page: number, perPage: number = RESULTS_PER_PAGE, fetchAllPages: boolean = false, rest?: object) => {
    setPager({ currentPage: 0, itemCount: 0, pageCount: 0, perPage: 0 })
    //@ts-ignore
    const q = rest.query
    query.current = q || query.current
    //@ts-ignore
    return searchApi.searchFull(query.current, page, perPage, fetchAllPages)
  }

  const getHints = async (_query?: string) => {
    if (!_query || _query?.length === 0) {
      setSearchHints([])
      query.current = ''
      return
    }

    try {
      const response = await searchApi.searchHints(_query || query.current)
      const items = response.data.items
      setPager(response.data.pager)
      query.current = _query || query.current

      const results: SearchHintInterface[] = items.map((hint: SearchHintInterface) => {
        const item = hint
        const productLink = getProductLink(item.id, _at(false, item.slug) || 'product') //TODO: delete  alternative after backend change
        const to =
          response.data.type === SEARCH_RESULTS_KIND.ALTERNATIVE
            ? getOppositeShopProductRoute(alternate?.canonicalDomain!, productLink)
            : productLink
        return { ...item, to }
        // for future with many types
        // return {
        //   type: hint.type,
        //   object: { ...item, to },
        // }
      })
      // optional add storable results in redux qith unique ID
      setSearchHints(results)
      setKind(response.data.type)

      if (query.current) {
        ls.track('Search Hints (quick)', {
          query_str: query.current,
          resultCount_int: results.length,
        })
      }
    } catch (error: any) {
      handleErrorResponse(error)
    }
  }

  return {
    getProducts,
    getHints,
    searchResults,
    searchHints,
    query,
    cursor,
    setCursor,
    kind,
    pager,
    productsCallback,
    transformProducts,
  }
}

export default useSearch
