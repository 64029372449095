import classNames from 'classnames'
import React, { useContext } from 'react'
import { useLocation } from 'react-router'
import styles from './AppFooter.module.scss'
import Blocks from './Blocks'
import ChannelContext from '../../context/ChannelContext'
import { _at } from '../../utils/translations'
import NewsletterForm from '../NewsletterForm'
import SocialIcons from '../SocialIcons'

type Props = {}

const AppFooter = (props: Props) => {
  const channel = useContext(ChannelContext).current
  const [newsletterFixed, setNewsletterFixed] = React.useState<boolean>(false)
  const location = useLocation()
  const bottomClass = classNames(styles['footer__bottom'], {
    [styles['footer__bottom--margin']]: newsletterFixed,
  })
  return (
    <footer className={styles['footer']}>
      <Blocks categories={channel.footerCategories} />
      <NewsletterForm setFixed={setNewsletterFixed} />
      <SocialIcons classes={{ root: styles['footer__social'] }} />
      {channel.category && location.pathname == '/' ? (
        <div
          className={styles['footer__seo']}
          dangerouslySetInnerHTML={{ __html: _at('description', channel.category.description?.translations) || '' }}
        />
      ) : null}
      <div className={bottomClass} />
    </footer>
  )
}

export default AppFooter
