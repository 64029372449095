import { CatalogInterface, CatalogListItemInterface } from './CatalogInterface'
import { TranslationsMapInterface } from './TranslationInterface'
//not used yet
export const QUERY_DEFAULT_MIN_LENGTH: number = 2

export enum SEARCH_ITEM_KIND {
  PRODUCT = 'product',
}

export enum SEARCH_RESULTS_KIND {
  DEFAULT = 'default',
  ALTERNATIVE = 'alternative',
  TOP = 'topProducts',
}

// export interface SearchHintInterface {
//   type: SEARCH_ITEM_KIND
//   object: SearchHintProductInterface
// }
export interface SearchHintInterface extends SearchHintProductInterface {}

export interface SearchHintProductInterface {
  id: number
  skuCode: string
  type: number
  translations: TranslationsMapInterface
  slug: TranslationsMapInterface
  to?: string
  //TODO: photo (one!) - wrong data on backend
}

export interface SearchProductResultInterface extends CatalogListItemInterface {}

export interface SearchProductsInterface extends CatalogInterface {
  type: SEARCH_RESULTS_KIND
}
