import { FormControlProps } from '@mui/material/FormControl'
import { FormikProps } from 'formik'
import _ from 'lodash'
import * as React from 'react'
import { IconSizeType } from '../../types/material.types'
import DateField from '../DateField'

type Props = {
  languageIsoCode: string
  formControlProps?: FormControlProps
  pickerProps?: Object
  dateFormat?: string
  form: FormikProps<any>
  field: {
    name: string
    value: any
    onChange: (event: React.FocusEvent<HTMLInputElement>) => void
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  }
  label?: string
}
//TODO: fix warnings eslint
const FormDateField = (props: Props) => {
  const { formControlProps, label, field, form } = props
  return (
    <DateField
      languageIsoCode={props.languageIsoCode}
      textFieldProps={{
        ...field,
        label,
        variant: 'outlined',
        error: Boolean(_.has(form.touched, field.name as string) && _.has(form.errors, field.name as string)),
        helperText:
          _.has(form.touched, field.name as string) && _.has(form.errors, field.name as string)
            ? _.get(form.errors, field.name as string)
            : '',
      }}
      form={props.form}
      pickerProps={props.pickerProps}
      dateFormat={props.dateFormat}
      formControlProps={formControlProps}
    />
  )
}

export default FormDateField
