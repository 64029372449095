import classNames from 'classnames'
import React, { ReactNode } from 'react'
import styles from './ModalContent.module.scss'

interface Props {
  className?: string
  children: ReactNode
}

const ModalContent: React.FC<Props> = (props: Props) => (
  <div className={classNames(styles['modal-content'], props.className)}>
    <>{props.children}</>
  </div>
)

export default ModalContent
