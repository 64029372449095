import rawStates from './data/states.json'

type CountryStateType = {
  id: number
  name: string
  countryCode: string
  isoCode: string | number
  type: string | null
  lat: string | null
  lng: string | null
}

//transform raw data
const states = rawStates.map(item => {
  return {
    id: item.id,
    name: item.name,
    countryCode: item.country_code,
    isoCode: item.state_code,
    type: item.type,
    lat: item.latitude,
    lng: item.longitude,
  }
})

function getStates(countryCode: string): CountryStateType[] {
  return states.filter((item: CountryStateType) => item.countryCode == countryCode)
}

function getStateByName(countryCode: string, name: string): CountryStateType | undefined {
  return states.find((item: CountryStateType) => item.countryCode === countryCode && item.name == name)
}

function getStateStartingByName(countryCode: string, name: string): CountryStateType | undefined {
  return states.find((item: CountryStateType) => item.countryCode === countryCode && item.name.normalize() === name.normalize())
}

function getStatesNames(countryCode: string) {
  return getStates(countryCode).map(item => {
    return item.name
  })
}
function getStateShort(state: string) {
  const stateObj = states.find(item => item.name === state)
  return stateObj && stateObj.isoCode
}

function getStateByShort(short: string) {
  const stateObj = states.find(item => item.isoCode === short)
  return stateObj && stateObj.name
}

function useSubrubAsCity(countryCode: string) {
  const exceptions = ['AU', 'NZ']
  return exceptions.includes(countryCode)
}

export { getStatesNames, getStateByShort, getStateShort, getStates, getStateByName, getStateStartingByName, useSubrubAsCity }
export default getStates
