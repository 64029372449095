import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import * as React from 'react'
import { create } from 'zustand'
import FormattedMessage from '../FormattedMessage'

export interface ConfirmationDialogStore {
  title?: React.ReactNode
  content?: React.ReactNode
  confirmLabel?: React.ReactNode
  cancelLabel?: React.ReactNode
  closeOnError?: boolean
  onSubmit?: (state: ConfirmationDialogStore) => Promise<void> | void
  onError?: (error: any) => void
  onClose?: () => void
  close: () => void
}

const defaultState: Omit<ConfirmationDialogStore, 'close'> = {
  title: undefined,
  content: undefined,
  confirmLabel: undefined,
  cancelLabel: undefined,
  closeOnError: false,
  onSubmit: undefined,
  onError: undefined,
  onClose: undefined,
}

const useStore = create<ConfirmationDialogStore>(set => ({
  ...defaultState,
  close: () => set({ onSubmit: undefined }),
}))

export const confirmDialog = (params: Omit<ConfirmationDialogStore, 'close'>) => {
  useStore.setState({
    ...defaultState,
    ...params,
  })
}

const ConfirmationDialog = () => {
  const state = useStore()
  const [loading, setLoading] = React.useState(false)

  const handleConfirmClick = async () => {
    if (state.onSubmit) {
      setLoading(true)

      try {
        await state.onSubmit(state)
        state.close()
      } catch (err: any) {
        if (state?.onError) {
          state.onError(err)
        } else {
          throw err
        }

        if (state.closeOnError) {
          state.close()
        }
      } finally {
        setLoading(false)
      }
    }
  }

  const handleClose = () => {
    if (state.onClose) {
      state.onClose()
    }

    state.close()
  }

  return (
    <Dialog open={Boolean(state.onSubmit)} onClose={handleClose} disableEscapeKeyDown fullWidth>
      <DialogTitle>
        {state.title ?? <FormattedMessage id="ConfirmationDialog.Heading" defaultMessage="Are you sure you want to perform this action?" />}
      </DialogTitle>
      <DialogContent>{state.content ?? null}</DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color="primary" variant="contained">
          {state.cancelLabel ?? <FormattedMessage id="ConfirmationDialog.Cancel" defaultMessage="Cancel" />}
        </Button>
        <Button disabled={loading} onClick={handleConfirmClick} color="info" variant="contained">
          {state.confirmLabel ?? <FormattedMessage id="ConfirmationDialog.Confirm" defaultMessage="Yes" />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
