import { AxiosError, AxiosResponse } from 'axios'
import { FormikHelpers } from 'formik'
import _ from 'lodash'
import React, { useState } from 'react'
import NewsletterFormView, { FormikValues } from './NewsletterFormView'
import useMatchMedia from '../../hooks/matchMedia'
import { useNewsletter } from '../../hooks/newsletter'
import FormattedMessage from '../FormattedMessage'
import notify from '../Notification/notify'

type Props = {
  setFixed: React.Dispatch<React.SetStateAction<boolean>>
}

const NewsletterForm = (props: Props) => {
  const { signToNewsletter } = useNewsletter()
  const [expanded, setExpanded] = useState<boolean>(false)
  const [closed, setClosed] = useState<boolean>(false)
  const { isDesktop } = useMatchMedia()

  React.useEffect(() => {
    props.setFixed(!closed && !isDesktop)
  }, [isDesktop, closed])

  const handleExpand = () => {
    setExpanded(prevExpanded => !prevExpanded)
  }

  const handleClose = () => {
    setClosed(prevClosed => !prevClosed)
  }

  const handleResponse = (res: AxiosResponse) => {
    let message: string | null = null
    if (res.data.errorCode! == 'form_error') {
      message = res.data.fields.email || (
        <FormattedMessage id="Newsletter.Alert.CreateNewsletterErrorOther" defaultMessage="Error on signed to Newsletter" />
      )
      notify.error(message)
    } else if (res.status === 200) {
      notify.success(<FormattedMessage id="Newsletter.Alert.CreateNewsletterSuccess" defaultMessage="Email was added to newsletter" />)
    }
  }

  const handleErrorResponse = (errorResponse: AxiosResponse) => {
    if (errorResponse.status! === 406) {
      notify.error(
        <FormattedMessage id="Newsletter.Alert.CreateNewsletterErrorExist" defaultMessage="Email already signed to Newsletter" />,
      )
    } else {
      notify.error(<FormattedMessage id="Newsletter.Alert.CreateNewsletterErrorOther" defaultMessage="Error on signed to Newsletter" />)
    }
  }

  const handleFormSubmit = (values: FormikValues, formik: FormikHelpers<FormikValues>) => {
    if (values.email !== null) {
      signToNewsletter(values.email)
        .then((response: AxiosResponse) => {
          handleResponse(response)
          formik.resetForm()
          handleClose()
        })
        .catch((error: AxiosError) => {
          if (error.response) {
            handleErrorResponse(error.response)
          }
        })
    }
  }

  return (
    <NewsletterFormView
      //setFixed={props.setFixed}
      expanded={expanded}
      closed={closed}
      onExpand={handleExpand}
      onClose={handleClose}
      onFormSubmit={handleFormSubmit}
    />
  )
}

export default NewsletterForm
