import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import { Collapse } from '@mui/material'
import classNames from 'classnames'
import React, { useState } from 'react'
import styles from './SizeGuideMobileTabs.module.scss'
import { PresetInterface } from '../../../interfaces/SizeGuideInterface'
import SizeGuide from '../SizeGuideContent'

type Props = {
  sizesByOrder: PresetInterface[]
}

const SizeGuideMobileTabs = (props: Props) => {
  const { sizesByOrder } = props

  const [openedTab, setOpenedTab] = useState<number | null>(null)

  const handleOpen = (idx: number) => {
    setOpenedTab(idx)
  }

  const handleClose = () => {
    setOpenedTab(null)
  }

  return (
    <>
      {sizesByOrder.map((preset, idx) => {
        const openItem = openedTab === idx

        const mobileTabClass = classNames(styles['mobile-tab'], {
          [styles['mobile-tab--open']]: openItem,
        })

        const arrowDownClass = classNames(styles['mobile-tab__arrow'], {
          [styles['mobile-tab__arrow--hide']]: openItem,
        })

        const arrowUpClass = classNames(styles['mobile-tab__arrow'], {
          [styles['mobile-tab__arrow--hide']]: !openItem,
        })

        return (
          <div key={`guide-tab-${preset.type}`}>
            <p className={mobileTabClass} onClick={openItem ? handleClose : () => handleOpen(idx)}>
              <span className={styles['mobile-tab__label']}>{preset.label}</span>
              <ArrowDropDown className={arrowDownClass} />
              <ArrowDropUp className={arrowUpClass} />
            </p>
            <Collapse in={openItem}>
              <SizeGuide preset={preset} />
            </Collapse>
          </div>
        )
      })}
    </>
  )
}

export default SizeGuideMobileTabs
