import { List, ListItem } from '@mui/material'
import React, { useContext } from 'react'
import styles from './CartItems.module.scss'
import ChannelContext from '../../../context/ChannelContext'
import { CartInterface, CartItemGiftInterface } from '../../../interfaces/CartInterface'
import CartItem from '../CartItem'

interface Props {
  cart: CartInterface
  gifts: CartItemGiftInterface[] | null
  className?: string
  readOnly?: boolean
  compactLayout?: boolean
}

const LADIES_CATEGORY_ID = 3
const MEN_CATEGORY_ID = 74

const CartItems: React.FC<Props> = (props: Props) => {
  const channel = useContext(ChannelContext).current
  const categoryId = channel.isWomenShop ? LADIES_CATEGORY_ID : MEN_CATEGORY_ID
  const categoryToLink = channel.categories.find(category => category.id === categoryId)

  return (
    <List
      classes={{
        root: props.className,
      }}
    >
      {props.cart.items.map(item => (
        <ListItem
          key={item.id}
          classes={{
            root: styles['item'],
          }}
        >
          <CartItem
            item={item}
            key={item.id}
            currencyCode={props.cart.currency}
            gifts={props.gifts}
            readOnly={props.readOnly}
            compactLayout={props.compactLayout}
          />
        </ListItem>
      ))}
    </List>
  )
}

export default CartItems
