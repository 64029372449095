import Button from '@mui/material/Button'
import classNames from 'classnames'
import React from 'react'
import { FormattedPlural } from 'react-intl'
import { useSelector } from 'react-redux'
import styles from './MiniCartTotal.module.scss'
import routes from '../../../app/routes'
import { State } from '../../../services/reducers'
import FormattedMessage from '../../FormattedMessage/FormattedMessage'
import Link from '../../Link/Link'
import Price from '../../Price/Price'

type Props = {
  continueShoppingBtn?: boolean
  myBagBtn?: boolean
  onButtonClick?: () => void
  onClose?: () => void
  classes?: {
    root?: string
    summaryText?: string
  }
}

const MiniCartTotal = (props: Props) => {
  const cart = useSelector((state: State) => state.cart)
  const giftCounter = cart.gifts?.length || 0
  const itemCount = Number.isInteger(cart.itemCount) ? (cart.itemCount as number) : 0
  const cartItemsCounter = giftCounter > 0 ? itemCount - 1 + giftCounter : itemCount

  const summaryTextClasses = classNames(styles['mini-cart-total__summary-text'], props.classes?.summaryText)

  return (
    <div className={classNames(styles['mini-cart-total__summary'], props.classes?.root)}>
      <span className={summaryTextClasses}>
        <FormattedPlural
          one={
            <FormattedMessage
              id="Cart.SummaryItem"
              description="Cart.SummaryItem"
              defaultMessage="{count} item"
              values={{ count: cartItemsCounter }}
            />
          }
          few={<FormattedMessage id="Cart.SummaryItemFew" defaultMessage="{count} items" values={{ count: cartItemsCounter }} />}
          many={<FormattedMessage id="Cart.SummaryItemsMany" defaultMessage="{count} items" values={{ count: cartItemsCounter }} />}
          other={
            <FormattedMessage
              id="Cart.SummaryItems"
              description="Cart.SummaryItems"
              defaultMessage="{count} items"
              values={{ count: cartItemsCounter }}
            />
          }
          value={cartItemsCounter}
        />
      </span>
      <span className={summaryTextClasses}>
        <FormattedMessage
          id="Cart.Subtotal"
          description="Cart.Subtotal"
          defaultMessage="Subtotal"
          className={styles['mini-cart-total__subtotal']}
        />
        <Price value={cart.totalItemsGross} currency={cart.currency} className={styles['mini-cart-total__price']} />
      </span>
      <div className={styles['mini-cart-total__buttons']}>
        {props.continueShoppingBtn ? (
          <Button
            fullWidth
            size="large"
            color="info"
            variant="contained"
            role="button"
            className={styles['buttons__button']}
            onClick={props.onClose}
          >
            <FormattedMessage id="Cart.ContinueShopping" description="Cart.ContinueShopping" defaultMessage="Continue Shopping" />
          </Button>
        ) : null}
        {props.myBagBtn ? (
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            component={Link}
            role="button"
            to={routes.cart}
            className={classNames(styles['buttons__button'], styles['buttons__go-to-cart'])}
            onClick={props.onButtonClick}
          >
            <FormattedMessage id="Cart.GoToCart" description="Cart.GoToCart" defaultMessage="My Bag" />
          </Button>
        ) : null}
      </div>
    </div>
  )
}
export default MiniCartTotal
