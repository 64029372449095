import _ from 'lodash'
import { ActionTypes, types } from './app.types'
import AppInterface from '../../interfaces/AppInterface'

export interface State extends AppInterface {}

const initialState = {} as State

export default function appReducer(state = initialState, action: ActionTypes) {
  switch (action.type) {
    case types.READ_APP_STATE_SUCCESS: {
      const newState = _.cloneDeep(action.payload)
      //const blogTags = newState.blogTags.filter(item => item.priority > 0)

      //TODO: change afer init refactor
      //TODO ugly but for while
      // window.APP_STATE = {
      //   ...newState,
      //   blogTags,
      //   channels: newState.channels.map(channel => {
      //     channel.blogTags = _.cloneDeep(blogTags)
      //     return channel
      //   }),
      //   allChannels: newState.allChannels.map(channel => {
      //     channel.blogTags = _.cloneDeep(blogTags)
      //     return channel
      //   }),
      // }

      return newState
    }

    default: {
      return state
    }
  }
}
