import { Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import styles from './CatalogNoProducts.module.scss'
import HistoryContext from '../../../context/HistoryContext'
import useChannelConfig from '../../../hooks/channelConfig/channelConfig'
import { CatalogListItemInterface } from '../../../interfaces/CatalogInterface'
import * as api from '../../../services/product/product.api'
import { toCatalogProducts } from '../../../transformers/CatalogTransformer'
import FormattedMessage from '../../FormattedMessage/FormattedMessage'
import CatalogListItem from '../CatalogListItem/CatalogListItem'

const MAX_TOP_SELL_PRODUCTS = 8

const CatalogNoProducts = () => {
  const [topSellProducts, setTopSellProducts] = useState<CatalogListItemInterface[]>(Array(MAX_TOP_SELL_PRODUCTS).fill(undefined))
  const history = useContext(HistoryContext)
  const channelConfig = useChannelConfig()

  useEffect(() => {
    const fetchTopSellProducts = async () => {
      try {
        const response = await api.readTopSellProducts()
        setTopSellProducts(toCatalogProducts(response.data))
      } catch (error: any) {
        console.error(error)
      }
    }

    fetchTopSellProducts()
  }, [])

  const handleSwimwearClick = () => {
    history.push(channelConfig.current.swimwearUrl)
  }

  const handleOutletClick = () => {
    history.push(channelConfig.current.outletUrl)
  }

  const handleGearClick = () => {
    history.push(channelConfig.current.gearUrl)
  }

  return (
    <div className={styles['no-products__container']}>
      <FormattedMessage
        id="Catalog.NoProducts.Label"
        description="Catalog.NoProducts.Label"
        className={styles['container__label']}
        defaultMessage="Sorry! There are currently no products available but here are some of our most popular items"
      />
      <div className={styles['container__links-container']}>
        <Button variant="contained" size="large" className={styles['links-container__button']} onClick={handleSwimwearClick}>
          <FormattedMessage id="Catalog.NoProducts.Swimwear" description="Catalog.NoProducts.Swimwear" defaultMessage="Swimwear" />
        </Button>
        <Button variant="contained" size="large" className={styles['links-container__button']} onClick={handleGearClick}>
          <FormattedMessage id="Catalog.NoProducts.Gear" description="Catalog.NoProducts.Gear" defaultMessage="Gear & Equipment" />
        </Button>
        <Button variant="contained" size="large" className={styles['links-container__button']} onClick={handleOutletClick}>
          <FormattedMessage id="Catalog.NoProducts.Outlet" description="Catalog.NoProducts.Outlet" defaultMessage="Outlet" />
        </Button>
      </div>
      {topSellProducts.length > 0 ? (
        <div className={styles['top-sell__container']}>
          {topSellProducts.map((item, idx) => (
            <CatalogListItem item={item} key={item?.id || idx} />
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default CatalogNoProducts
