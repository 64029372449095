import _ from 'lodash'
import NewsletterInterface, { NewsletterFlatInterface } from '../../interfaces/NewsletterInterface'
import UserInterface from '../../interfaces/UserInterface'
import axios from '../../utils/axios'
import DateHelper from '../../utils/date'

export function updateUser(user: UserInterface) {
  const newUser = _.cloneDeep(user)
  if (!_.isEmpty(user.birthday)) {
    const bDate = DateHelper.dateToUnixTimestamp(user.birthday.toString(), true)
    newUser.birthday = bDate === false ? '' : bDate
  }

  if (!_.isEmpty(user.newsletterOptions) && _.isArray(user.newsletterOptions)) {
    //@ts-ignore TODO: fix types
    newUser.newsletterOptions = user.newsletterOptions.map((item: NewsletterInterface | NewsletterFlatInterface) => {
      const newsletterOption = _.isObject(item.newsletterOption) ? item.newsletterOption.id : item.newsletterOption
      const active = item.active

      return {
        id: item.id!,
        newsletterOption,
        active,
      }
    })
  }

  return axios({
    method: 'PUT',
    url: '/profile.json',
    data: newUser,
  })
}

export function updateResetPassword(token: string) {
  return axios({
    method: 'POST',
    url: '/security/password/confirm-reset.json',
    data: {
      token,
    },
  })
}

export function createResetPassword(email: string, from: number) {
  return axios({
    method: 'POST',
    url: '/security/password/reset.json',
    data: {
      from,
      email,
    },
  })
}

export function updatePassword(currentPassword: string, newPassword: string) {
  return axios({
    method: 'POST',
    //z konta
    url: '/security/password/change.json',
    data: {
      currentPassword,
      newPassword,
    },
  })
}

//set-pass  -na reset z tokena
export function updateSetPassword(newPassword: string, token: string) {
  return axios({
    method: 'POST',
    url: '/security/password/set.json',
    data: {
      newPassword,
      token,
    },
  })
}

export function searchOrders(page: number = 1, perPage: number = 2) {
  return axios({
    method: 'GET',
    url: `/order.json?page=${page}&perPage=${perPage}`,
  })
}

export function readLoyaltyHistory(page: number = 1, perPage: number = 5, sort: 'ASC' | 'DESC' = 'DESC') {
  return axios({
    method: 'GET',
    url: `/profile/loyalty/history.json?page=${page}&perPage=${perPage}&sorting[id]=${sort}`,
  })
}

export function searchRefunds(page: number = 1, perPage: number = 2, sort: 'ASC' | 'DESC' = 'DESC') {
  return axios({
    method: 'GET',
    url: `/order/refund.json?page=${page}&perPage=${perPage}&sorting[id]=${sort}`,
  })
}
