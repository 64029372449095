import React from 'react'

const defaultContext = Object.freeze({
  facebookAppId: '' as string,
})

type Props = typeof defaultContext

const SocialContext = React.createContext<Props>(defaultContext)

export default SocialContext
