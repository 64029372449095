import CloseIcon from '@mui/icons-material/Close'
import BasketIcon from '@mui/icons-material/ShoppingBasket'
import React, { useContext } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { FormattedMessage } from 'react-intl'
import styles from './UpSellMobileModal.module.scss'
import routes from '../../../../app/routes'
import HistoryContext from '../../../../context/HistoryContext'
import UpSellContext from '../../../../context/UpSellContext'
import useCart from '../../../../hooks/cart/cart'
import CartItems from '../../../Cart/CartItems/CartItems'
import MiniCartTotal from '../../../MiniCart/MiniCartTotal/MiniCartTotal'
import Modal, { ModalContent, ModalFooter, ModalHeader } from '../../../Modal'
import ModalButton from '../../../Modal/ModalButton/ModalButton'

const UpSellMobileModal: React.FC = () => {
  const history = useContext(HistoryContext)
  const upSell = useContext(UpSellContext)
  const { cart } = useCart()

  const onButtonClick = () => {
    history.push(routes.cart)
    upSell.handleClose()
  }

  return (
    <Modal open={upSell.showModal} onClose={upSell.handleClose}>
      <ModalHeader>
        <ModalButton
          leftIcon={<CloseIcon onClick={upSell.handleClose} />}
          classes={{ root: styles['up-sell-mobile-modal__modal_header__button'] }}
        >
          <FormattedMessage id="UpSell.YourCart" description="UpSell.YourCart" defaultMessage="Your Bag" />
        </ModalButton>
      </ModalHeader>
      <ModalContent className={styles['up-sell-mobile-modal__content']}>
        <Scrollbars hideTracksWhenNotNeeded={false}>
          <CartItems cart={cart} gifts={cart.gifts} className={styles['content__list']} compactLayout />
          <div className={styles['total__container']}>
            <MiniCartTotal
              classes={{
                root: styles['up-sell-mobile-modal__total'],
                summaryText: styles['up-sell-mobile-modal__total__item-counter'],
              }}
            />
          </div>
        </Scrollbars>
      </ModalContent>
      <ModalFooter>
        <ModalButton leftIcon={<BasketIcon />} active onClick={onButtonClick}>
          <FormattedMessage id="UpSell.GoToCart" description="UpSell.GoToCart" defaultMessage="My Bag" />
        </ModalButton>
        <ModalButton leftIcon={<CloseIcon />} onClick={upSell.handleClose}>
          <FormattedMessage id="UpSell.ContinueShopping" description="UpSell.ContinueShopping" defaultMessage="Continue Shopping" />
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export default UpSellMobileModal
