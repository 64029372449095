import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import styles from './SizeGuideTable.module.scss'
import { sizeLabels } from '../../../hooks/sizeGuide/sizeGuideConstants'
import { PresetInterface, SizeGuideInterface, SizesTableRow } from '../../../interfaces/SizeGuideInterface'

type Props = {
  sizes: SizeGuideInterface[]
  preset: PresetInterface
  columnCount: number
}

const SizeGuideTable = (props: Props) => {
  const { sizes, columnCount, preset } = props

  const tableClasses = {
    root: styles['table'],
  }

  const prepareRows = () => {
    let rows: SizesTableRow[] = []
    for (const label of sizeLabels) {
      rows.push({
        cells: [{ label: label.label, id: -1 }],
        isHeader: label.isCountryLabel,
        isFirst: rows.length === 0,
        key: label.key,
      })
    }
    for (const size of sizes) {
      if (preset.requiredLabels.find(l => !size[l])) {
        continue
      }
      sizeLabels.forEach((sizeLabel, idx) => {
        if (size) {
          rows[idx].cells.push({
            id: size.id ?? -idx,
            label: size[sizeLabel.key] ?? '',
          })
        }
      })
    }
    const _columnCount = sizes.length > 0 ? Math.min(columnCount, rows[0].cells.length) : columnCount
    let count = 0
    rows = rows.filter(r => r.cells.slice(1).find(c => c.label))
    rows
      .filter(r => r.cells.slice(1).find(c => c.label))
      .forEach((row, idx, arr) => {
        count = 0
        const cells = [...row.cells.slice(1)]
        const firstCell = row.cells[0]
        rows[idx].cells = [firstCell, ...cells.splice(0, _columnCount - 1)]
        while (cells.length) {
          count++
          rows[count * arr.length + idx] = {
            isHeader: row.isHeader,
            isFirst: row.isFirst,
            key: `${row.key}-count-${count}`,
            cells: [firstCell, ...cells.splice(0, _columnCount - 1)],
          }
        }
      })
    return rows
  }

  const table = prepareRows().map((row: SizesTableRow) => {
    const columnStyle = {
      width: `${100 / columnCount}%`,
    }
    const getTableCellClasses = (colIdx: number) => ({
      root: classNames(styles['table__cell'], {
        [styles['table__first-row']]: row.isFirst,
        [styles['table__cell__header--not-last']]: row.isHeader && row.cells.length !== colIdx - 1,
      }),
    })

    const tableLabelClass = classNames(styles['table__row'], {
      [styles['table__row--accent']]: row.isHeader,
    })

    return (
      <TableRow key={`r-${row.key}`}>
        {row.cells.map((cell, colIdx) => (
          <TableCell classes={getTableCellClasses(colIdx)} key={`r-${row.key}-c-${cell.id}`} style={columnStyle}>
            <span className={tableLabelClass}>{cell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    )
  })

  return (
    <Table classes={tableClasses}>
      <TableBody>{table}</TableBody>
    </Table>
  )
}

export default SizeGuideTable
