import axios from '../../utils/axios'

export function readPostCodes(postCode: string, countryCode: string) {
  return axios({
    method: 'GET',
    url: `post-code.json?postCode=${postCode}&country=${countryCode}`,
  })
}

export function readAddressFormats() {
  return axios({
    method: 'GET',
    url: '/init/address_format.json',
  })
}
