import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { menOrder, womenOrder } from './sizeGuideConstants'
import ChannelContext from '../../context/ChannelContext'
import { PresetInterface } from '../../interfaces/SizeGuideInterface'
import { State } from '../../services/reducers'

const DEFAULT_COLUMN_NAME = 'australiaLabel'

export type CountriesSizesType = {
  country: string
  size: string | number | boolean
}

export const useSizeGuide = () => {
  const channel = useContext(ChannelContext).current
  const sizes = useSelector((store: State) => store.global.sizeGuide)
  const columnName = channel.sizeGuideColumn || DEFAULT_COLUMN_NAME

  const getSizeLabel = (id: number) => {
    return getSizeById(id)?.[columnName]
  }

  const getPresetById = (id: number, presets: PresetInterface[]) => {
    const foundPreset = sizes?.find(s => s.presetId === id && presets.find(p => p.label === s.presetName))
    if (!foundPreset) {
      return
    }
    return presets.find(p => p.label === foundPreset.presetName)
  }

  const getSizeById = (id: number) => {
    return sizes?.find(s => s.productSizeId === id)
  }

  const getPresetByLabel = (name: string) => {
    return womenOrder.find(item => item.label === name) || menOrder.find(item => item.label === name)
  }

  const getSizesByPresetLabel = (label: string) => {
    return sizes?.filter(s => s.presetName === label) ?? []
  }

  const getPresetImageByPresetLabel = (label: string) => {
    const presetObject = getPresetByLabel(label)
    return presetObject != null && presetObject.image != null ? presetObject.image : null
  }

  return {
    sizes,
    getSizeLabel,
    getPresetById,
    getSizeById,
    getSizesByPresetLabel,
    getPresetImageByPresetLabel,
  }
}

export default useSizeGuide
