import { useContext } from 'react'
import ClubContext from '../../context/ClubContext'

const useClubContext = () => {
  const context = useContext(ClubContext)

  if (context === undefined) {
    throw new Error('useClubContext must be used within a ClubProvider')
  }

  return context
}

export default useClubContext
