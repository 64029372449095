import React from 'react'

export type ContextProps = {
  showModal: boolean
  handleOpen: (productId: number) => void
  handleClose: () => void
}

const UpSellContext = React.createContext<ContextProps>({
  showModal: false,
  handleOpen: () => {},
  handleClose: () => {},
})

export default UpSellContext
