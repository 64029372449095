import moment from 'moment'
import { IntlShape } from 'react-intl'
import guestSchema from './SignAsGuestSchema'
import validationMessages from '../../app/i18n/validationMessages'
import DateHelper from '../../utils/date'
import * as schemaConst from '../../utils/validationConst'
import Yup from '../../utils/yup'

const birthdayMinDate = moment().subtract(120, 'years')
const birthdayMaxDate = moment()
const schema = (intl: IntlShape) =>
  guestSchema(intl).shape({
    plainPassword: Yup.string()
      .ensure()
      .required(intl.formatMessage(validationMessages.schemaFieldRequired))
      .min(schemaConst.passwordMinChars, intl.formatMessage(validationMessages.schemaMinChar, { val: schemaConst.passwordMinChars })),
    newPassword: Yup.string()
      .ensure()
      .required(intl.formatMessage(validationMessages.schemaFieldRequired))
      .oneOf([Yup.ref('plainPassword')], intl.formatMessage(validationMessages.schemaPasswordsNotMatch)),
    birthday: Yup.string()
      .nullable()
      //@ts-ignore TODO: fix
      .isValidDateRange(
        birthdayMinDate,
        birthdayMaxDate,
        intl.formatMessage(validationMessages.schemaWrongDateFormat),
        intl.formatMessage(validationMessages.schemaWrongMinDateRangeFormat, {
          val: birthdayMinDate.format(DateHelper.getDateFormatPattern(moment())),
        }),
        intl.formatMessage(validationMessages.schemaWrongMaxDateRangeFormat, {
          val: birthdayMaxDate.format(DateHelper.getDateFormatPattern(moment())),
        }),
      ),
  })

export default schema
