import classNames from 'classnames'
import React, { ReactNode } from 'react'
import styles from './ModalFooter.module.scss'

interface Props {
  children: ReactNode
  className?: string
}

const ModalFooter: React.FC<Props> = (props: Props) => (
  <div className={classNames(styles['modal-footer'], props.className)}>{props.children}</div>
)

export default ModalFooter
