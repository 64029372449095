import _ from 'lodash'
import { ActionTypes, types } from './user.types'
import UserInterface from '../../interfaces/UserInterface'
import { ActionTypes as AuthActionTypes, types as authTypes } from '../auth/auth.types'

export interface AuthInterface {
  tokenId: string | undefined
  secret: string | undefined
}

export interface State {
  profile: undefined | UserInterface
  auth: AuthInterface
}

// const initialState: State = {
//   profile: window.APP_STATE?.user ? window.APP_STATE?.user : undefined,
//   auth: {
//     tokenId: window.APP_STATE?.user ? window.APP_STATE?.user.tokenId : undefined,
//     secret: window.APP_STATE?.user ? window.APP_STATE?.user.secret : undefined,
//   },
// }

const initialState: State = {
  profile: undefined,
  auth: {
    tokenId: undefined,
    secret: undefined,
  },
}

export default function userReducer(state: State = initialState, action: ActionTypes | AuthActionTypes) {
  switch (action.type) {
    case types.USER_PROFILE_READ: {
      const newState = _.clone(state)

      newState.profile = _.omit(action.payload, ['secret', 'tokenId'])
      return newState
    }

    case authTypes.LOG_OUT_SUCCESS: {
      const newState = _.clone(initialState)
      newState.profile = undefined
      newState.auth = {
        tokenId: undefined,
        secret: undefined,
      }
      return newState
    }

    case authTypes.SIGN_IN_SUCCESS: {
      const newState = _.clone(state)
      newState.auth.tokenId = action.payload.user.tokenId!
      newState.auth.secret = action.payload.user.secret!
      return newState
    }

    default: {
      return state
    }
  }

  return state
}
