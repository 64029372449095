import { Location, LocationState, Path } from 'history'
import createTransitionManager from './createTransitionManager'
import { getConfirmation, isExtraneousPopstateEvent, supportsHistory, supportsPopStateOnHashChange } from './dom-utils'
import { Prompt } from './interfaces'
import { createKey, createLocation } from './location-utils'
import { warning } from './log'
import { addLeadingSlash, createPath, hasBasename, stripBasename, stripTrailingSlash } from './path-utils'
import ChannelInterface from '../../../interfaces/ChannelInterface'
import HistoryInterface from '../../../interfaces/HistoryInterface'
import { fixPath } from '../../../utils/path'

export type CreateHistoryOptions = {
  basename: string
  currentUrl: string
  channels: ChannelInterface[]
  useGlobalHistory: boolean
  getUserConfirmation?: (message: string, callback: (confirmed: boolean) => {}) => {}
  forceRefresh?: boolean
  keyLength?: number
}

type NextState = {
  action: string
  location: Location
}

const PopStateEvent = 'popstate'
const HashChangeEvent = 'hashchange'

const createBrowserHistory = (win: Window, props: CreateHistoryOptions) => {
  let forceNextPop = false

  const globalHistory = win.history
  const globalLocation = win.location
  const globalNavigator = win.navigator
  const canUseHistory = supportsHistory(win)
  const needsHashChangeListener = !supportsPopStateOnHashChange(globalNavigator)

  const forceRefresh = props.forceRefresh != null ? props.forceRefresh : false
  const getUserConfirmation = props.getUserConfirmation != null ? props.getUserConfirmation : getConfirmation
  const keyLength = props.keyLength != null ? props.keyLength : 6

  const rawBasename = props.basename ? stripTrailingSlash(addLeadingSlash(props.basename)) : ''
  let channelPrefix = ''
  let basename: string = rawBasename + channelPrefix

  const getHistoryState = () => {
    try {
      return win.history.state || {}
    } catch (error: any) {
      // IE 11 sometimes throws when accessing window.history.state
      // See https://github.com/ReactTraining/history/pull/289
      return {}
    }
  }

  const getDOMLocation = (historyState: Location) => {
    const { key, state } = historyState || {}
    const { pathname, search, hash } = globalLocation
    let path = pathname + search + hash

    warning(
      !basename || hasBasename(path, basename),
      'You are attempting to use a basename on a page whose URL path does not begin ' +
        'with the basename. Expected path "' +
        path +
        '" to begin with "' +
        basename +
        '".',
    )

    if (basename) {
      path = stripBasename(path, basename)
    }

    if (rawBasename) {
      path = stripBasename(path, rawBasename)
    }
    return createLocation(path, state, key || createKey(keyLength))
  }

  const transitionManager = createTransitionManager()

  const setState = (nextState?: NextState) => {
    Object.assign(history, nextState)
    history.length = globalHistory.length
    transitionManager.notifyListeners(history.location, history.action)
  }

  const handlePopState = (event: any) => {
    //if (!isExtraneousPopstateEvent(globalNavigator, event)) {
    handlePop(getDOMLocation(event.state))
    // }
  }

  const handleHashChange = () => {
    handlePop(getDOMLocation(getHistoryState()))
  }

  const handlePop = (location: Location) => {
    if (forceNextPop) {
      forceNextPop = false
      setState()
    } else {
      const action = 'POP'

      transitionManager.confirmTransitionTo(location, action, getUserConfirmation, (ok: boolean) => {
        if (ok) {
          setState({ action, location })
        } else {
          revertPop(location)
        }
      })
    }
  }

  const revertPop = (fromLocation: Location) => {
    const toLocation = history.location

    let toIndex = allKeys.indexOf(toLocation.key)
    let fromIndex = allKeys.indexOf(fromLocation.key)

    if (toIndex === -1) {
      toIndex = 0
    }

    if (fromIndex === -1) {
      fromIndex = 0
    }

    const delta = toIndex - fromIndex

    if (delta) {
      forceNextPop = true
      go(delta)
    }
  }

  const initialLocation = getDOMLocation(getHistoryState())
  let allKeys = [initialLocation.key]
  let listenerCount = 0
  let isBlocked = false

  // Public interface

  const setChannelPath = (prefix: string) => {
    channelPrefix = prefix
    basename = fixPath(rawBasename + prefix)
  }

  const createHref = (location: Location) => {
    return fixPath(basename + createPath(location))
  }

  const createChannelHref = (location: Location): string => {
    return fixPath(rawBasename + createPath(location))
  }

  const push = (path: Path, state?: LocationState) => {
    warning(
      !(typeof path === 'object' && state !== undefined),
      'You should avoid providing a 2nd state argument to push when the 1st ' +
        'argument is a location-like object that already has state; it is ignored',
    )

    const action = 'PUSH'
    const location = createLocation(path, state, createKey(keyLength), history.location)

    transitionManager.confirmTransitionTo(location, action, getUserConfirmation, (ok: boolean) => {
      if (!ok) {
        return
      }

      const href = createHref(location)
      const { key } = location
      const inState = location.state
      // if (!inState) {
      //   inState = location.pathname
      // }
      if (canUseHistory) {
        globalHistory.pushState({ key, state: inState }, '', href)

        if (forceRefresh) {
          globalLocation.href = href
        } else {
          const prevIndex = allKeys.indexOf(history.location.key)
          const nextKeys = allKeys.slice(0, prevIndex === -1 ? 0 : prevIndex + 1)

          nextKeys.push(location.key)
          allKeys = nextKeys

          setState({ action, location })
        }
      } else {
        warning(inState === undefined, 'Browser history cannot push state in browsers that do not support HTML5 history')

        globalLocation.href = href
      }
    })
  }

  const replace = (path: Path, state?: LocationState) => {
    warning(
      !(typeof path === 'object' && state !== undefined),
      'You should avoid providing a 2nd state argument to replace when the 1st ' +
        'argument is a location-like object that already has state; it is ignored',
    )

    const action = 'REPLACE'
    const location = createLocation(path, state, createKey(keyLength), history.location)

    transitionManager.confirmTransitionTo(location, action, getUserConfirmation, (ok: boolean) => {
      if (!ok) {
        return
      }

      const href = createHref(location)
      const { key, state: inState } = location

      if (canUseHistory) {
        globalHistory.replaceState({ key, state: inState }, '', href)

        if (forceRefresh) {
          globalLocation.replace(href)
        } else {
          const prevIndex = allKeys.indexOf(history.location.key)

          if (prevIndex !== -1) {
            allKeys[prevIndex] = location.key
          }

          setState({ action, location })
        }
      } else {
        warning(inState === undefined, 'Browser history cannot replace state in browsers that do not support HTML5 history')

        globalLocation.replace(href)
      }
    })
  }

  const go = (n: number) => {
    globalHistory.go(n)
  }

  const goBack = () => go(-1)
  const goForward = () => go(1)

  const checkDOMListeners = (delta: number) => {
    listenerCount += delta

    if (listenerCount === 1) {
      win.addEventListener(PopStateEvent, handlePopState)

      if (needsHashChangeListener) {
        win.addEventListener(HashChangeEvent, handleHashChange)
      }
    } else if (listenerCount === 0) {
      win.removeEventListener(PopStateEvent, handlePopState)

      if (needsHashChangeListener) {
        win.removeEventListener(HashChangeEvent, handleHashChange)
      }
    }
  }

  const block = (prompt: string | Prompt = '') => {
    const unblock = transitionManager.setPrompt(prompt)

    if (!isBlocked) {
      checkDOMListeners(1)
      isBlocked = true
    }

    return () => {
      if (isBlocked) {
        isBlocked = false
        checkDOMListeners(-1)
      }

      return unblock()
    }
  }

  const listen = (listener: Function) => {
    const unlisten = transitionManager.appendListener(listener)
    checkDOMListeners(1)

    return () => {
      checkDOMListeners(-1)
      unlisten()
    }
  }

  const history: HistoryInterface = {
    length: globalHistory.length,
    action: 'POP',
    location: initialLocation,
    createHref,
    createChannelHref,
    push,
    replace,
    go,
    goBack,
    goForward,
    block,
    listen,
    win,
    setChannelPath,
    getChannelPath: () => channelPrefix,
    basename,
    rawBasename,
  }

  return history
}

export default createBrowserHistory
