import _ from 'lodash'
import routes from '../../app/routes'
import CategoryInterface from '../../interfaces/CategoryInterface'
import { MenuItemInterface } from '../../interfaces/MenuInterface'
import { _at } from '../../utils/translations'
import { resolveRoute } from '../Link/linkResolver'

type LinkBuilder = (category: CategoryInterface, item: MenuItemInterface<null>) => string

type MenuInput = {
  categories: CategoryInterface[]
  locale: string
  fallbackLocale: string
  linkBuilder?: LinkBuilder
}

export const getMegaMenu = (items: MenuItemInterface[]): MenuItemInterface[] =>
  items
    .filter(item => item.parentId === null)
    .map(parentItem => {
      const parentLeft = parentItem.left || 0
      const parentRight = parentItem.right || 0

      parentItem.children = items.filter(item => {
        const itemLeft = item.left || 0
        const itemRight = item.right || 0
        return item.level < 4 && itemLeft > parentLeft && itemRight < parentRight
      })

      return parentItem
    })

export const getMenu = (input: MenuInput): MenuItemInterface[] => {
  const items = input.categories
    .map((category: CategoryInterface) =>
      getMenuItem({
        category,
        locale: input.locale,
        fallbackLocale: input.fallbackLocale,
        linkBuilder: input.linkBuilder,
      }),
    )
    .filter(Boolean) as MenuItemInterface[]

  return removeRoot(items)
}

type MenuItemInput = {
  category: CategoryInterface
  locale: string
  fallbackLocale: string
  linkBuilder?: LinkBuilder
}

const getMenuItem = (input: MenuItemInput): MenuItemInterface | null => {
  const locale = getLocale(input)

  if (locale === null) {
    return null
  }

  const rootId = input.category.treeRootId ? input.category.treeRootId : input.category.id
  const name = _at('name', input.category.translations, locale)!
  const slug = _at('slug', input.category.description.translations, locale)
  const description = input.category.description.translations[locale].description

  const item = {
    id: input.category.id,
    rootId,
    parentId: input.category.treeParentId,
    left: input.category.treeLeft,
    right: input.category.treeRight,
    level: input.category.treeLevel,
    name,
    anchor: name,
    slug: slug!,
    link: null,
    description,
    category: _.cloneDeep(input.category),
    isBreak: input.category.break,
    isSpecial: input.category.special,
    children: [],
  }

  return {
    ...item,
    children: [],
    link:
      input.linkBuilder != null
        ? input.linkBuilder(input.category, item)
        : resolveRoute(routes.catalog, {
            categoryId: input.category.id,
            categorySlug: slug,
          }),
  }
}

type ItemLocaleInput = {
  category: CategoryInterface
  locale: string
  fallbackLocale: string
}

const getLocale = (input: ItemLocaleInput): string | null => {
  let result: string | null = null

  if (_.has(input.category.translations, input.locale)) {
    result = input.locale
  } else if (_.has(input.category.translations, input.fallbackLocale)) {
    result = input.fallbackLocale
  } else if (!_.isEmpty(input.category.translations)) {
    result = Object.keys(input.category.translations).shift() || null
  }

  return result
}

const removeRoot = (items: MenuItemInterface[]): MenuItemInterface[] => {
  const index = items.findIndex(item => item.parentId === null)

  if (index === -1) {
    return items
  }

  const rootId = items.splice(index, 1)[0].id

  return items.map(item => {
    if (item.parentId === rootId) {
      item.parentId = null
    }

    return item
  })
}

export const getItem = (items: MenuItemInterface[], id: number | undefined): MenuItemInterface | null => {
  const item = items.find(_item => _item.id === id)
  return item ? _.cloneDeep(item) : null
}

export const getItemByPathname = (items: MenuItemInterface[], pathname: string): MenuItemInterface | null => {
  //TODO: pathname undefined!
  const item = items.find(_item => pathname?.includes(_item.link))
  return item ? _.cloneDeep(item) : null
}

export const getParentItem = (items: MenuItemInterface[], childItem: MenuItemInterface | undefined | null): MenuItemInterface | null => {
  const parentItem = childItem?.parentId ? items.find(item => item.id === childItem.parentId) : null
  return parentItem ? _.cloneDeep(parentItem) : null
}

export const getActiveItems = (items: MenuItemInterface[], activeItem: MenuItemInterface | undefined | null) => {
  const result = getActiveItemIds(items, activeItem)
  return items.filter(item => result.includes(item.id))
}

export const getActiveItemIds = (items: MenuItemInterface[], activeItem: MenuItemInterface | undefined | null) => {
  if (!activeItem) {
    return []
  }

  const result = [activeItem.id]
  let parentId = activeItem.parentId

  while (parentId !== null) {
    result.unshift(parentId)
    const parent = items.find(item => item.id === parentId)
    parentId = parent !== undefined ? parent.parentId : null
  }

  return result
}
