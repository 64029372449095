import ls from '@livesession/sdk'

function windowErrorHandler(message: Event | string, file?: string, line?: number, column?: number, errorObject?: Error): any {
  //column = column || (window.event && window.event.errorCharacter)
  const stack: string[] | string | undefined | null = errorObject ? errorObject.stack : null

  const data = {
    message,
    file,
    line,
    column,
    errorStack: stack,
  }

  ls.track('App Error', {
    message_str: message,
    file_str: file,
    line_int: line,
    stack_str: stack,
  })
  console.error(data)
  // send mail notification in future with browser info!
  // return true to mute error in console
}

export default windowErrorHandler
