import { FormControl, TextField as MaterialTextField } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField'
import _ from 'lodash'
import * as React from 'react'

type Props = TextFieldProps & {
  formControlProps?: Object
}
const TextField: React.FC<Props> = (props: any) => {
  return (
    <FormControl {...props.formControlProps}>
      <MaterialTextField {..._.omit(props, 'formControlProps')} variant="outlined" id={props.name} />
    </FormControl>
  )
}
export default TextField
