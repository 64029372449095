import { FormControlProps } from '@mui/material/FormControl'
import { InputProps } from '@mui/material/Input'
import { TextFieldProps } from '@mui/material/TextField'
import { FormikProps } from 'formik'
import _ from 'lodash'
import React from 'react'
import TextField from '../TextField'
/* eslint-disable no-nested-ternary */

type Props = TextFieldProps & {
  field: {
    name: string
    value: any
    onChange: (event: React.FocusEvent<HTMLInputElement>) => void
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
    multiline?: boolean
    rows?: number
  }
  form: FormikProps<any>
  label?: React.ReactNode | string
  placeholder?: string
  error?: boolean
  helperText?: any
  fullWidth?: boolean
  InputProps?: InputProps
  classes?: {
    root?: string
  }
  disabled?: boolean
  onChange?: (value: string) => void
  inputProps?: object
  inputRef?: React.RefObject<any>
  formControlProps?: FormControlProps
  disablePaste?: boolean
}

const FormTextField: React.FC<Props> = ({ field: { ...fields }, form: { touched, errors, status }, ...props }) => {
  return (
    <TextField
      variant="outlined"
      {..._.pick(props, [
        'margin',
        'placeholder',
        'type',
        'label',
        'fullWidth',
        'classes',
        'children',
        'InputProps',
        'inputProps',
        'inputRef',
        'formControlProps',
        'disabled',
      ])}
      {...fields}
      onPaste={(event: React.ClipboardEvent<HTMLDivElement>) => {
        if (props.disablePaste) {
          event.preventDefault()
          return false
        }
        return true
      }}
      onChange={(event: React.FocusEvent<HTMLInputElement>) => {
        fields.onChange(event)
        props.onChange ? props.onChange(event.currentTarget.value) : _.noop()
      }}
      error={Boolean(_.get(touched, fields.name, false) && (_.get(errors, fields.name, false) || _.has(status, fields.name)))}
      helperText={
        _.get(touched, fields.name, false) && (_.has(errors, fields.name) || _.has(status, fields.name))
          ? _.get(errors, fields.name, _.get(status, fields.name))
          : props.helperText
          ? props.helperText
          : ''
      }
    />
  )
}

export default FormTextField
