import { Button, FormControl } from '@mui/material'
import classnames from 'classnames'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import _ from 'lodash'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import styles from './SignInForm.module.scss'
import signInValidationSchema from './SignInFormSchema'
import routes from '../../app/routes'
import HistoryContext from '../../context/HistoryContext'
import { useAuth } from '../../hooks/auth/auth'
import { AxiosError, AxiosResponse } from '../../utils/axios'
import { RESET_PASSWORD_ORIGIN } from '../../web/views/ResetPassword'
import FormattedMessage from '../FormattedMessage'
import FormTextField from '../FormTextField'
import Link from '../Link'
import notify from '../Notification/notify'
import useAppEvents from '../../hooks/events/events'
import { AppEventsEnum } from '../../hooks/events/events.types'

export const FORM_NAME = 'FROM_SIGNIN'

type Props = {
  exited: boolean
  expanded: boolean
  validationSchema?: any
  onExpand: () => void
  toggle: (name: string, expanded: boolean) => void
  from?: RESET_PASSWORD_ORIGIN
}

export interface FormikValues {
  email: string
  plainPassword: string
}

const SignInForm: React.FC<Props> = (props: Props) => {
  let _mounted = false
  const intl = useIntl()
  const history = useContext(HistoryContext)
  const location = history.location

  const toggleClassName = classnames({
    [styles['form__element--hidding']]: !props.expanded,
    [styles['form__element--hidden']]: props.exited,
  })

  const formControlProps = {
    className: classnames(toggleClassName),
    fullWidth: true,
  }

  const { signIn } = useAuth()

  React.useEffect(() => {
    _mounted = true
    return () => {
      _mounted = false
    }
  })

  const { triggerEvent } = useAppEvents()

  const onSubmit = (values: FormikValues, formikBag: FormikHelpers<FormikValues>) => {
    signIn(
      values,
      (response: AxiosResponse) => {
        if (!_mounted) return
        triggerEvent(AppEventsEnum.SIGN_IN, { pathname: location.pathname, method: 'Brandshop' })
        //formikBag.setSubmitting(false)
      },
      (error: AxiosError) => {
        if (!_mounted) return
        formikBag.setSubmitting(false)
        notify.error(
          <FormattedMessage
            id="SignInForm.AuthenticationError"
            description="SignInForm.AuthenticationError"
            defaultMessage="You provided wrong credentials"
          />,
        )
      },
    )
  }

  return (
    <Formik
      initialValues={{
        email: '',
        plainPassword: '',
      }}
      validationSchema={signInValidationSchema(intl)}
      onSubmit={(values: FormikValues, formikBag: FormikHelpers<FormikValues>) => {
        onSubmit(values, formikBag)
      }}
      render={({ submitForm, isSubmitting }) => (
        <div className={styles['container']}>
          <Form className={styles['form']}>
            <Field
              name="email"
              label={intl.formatMessage({ id: 'SignInForm.EmailAddress', defaultMessage: 'Email address' })}
              component={FormTextField}
              formControlProps={formControlProps}
            />
            <Field
              type="password"
              name="plainPassword"
              label={intl.formatMessage({ id: 'SignInForm.YourPassword', defaultMessage: 'Your password' })}
              component={FormTextField}
              formControlProps={formControlProps}
            />
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                onClick={_.isFunction(props.onExpand) && !props.expanded ? props.onExpand : submitForm}
                disabled={isSubmitting}
              >
                <FormattedMessage id="SignInForm.SignInButton" description="SignInForm.SignInButton" defaultMessage="Sign In" />
              </Button>
            </FormControl>
            <Link
              to={`${routes.authForgotPassword}?from=${props.from || RESET_PASSWORD_ORIGIN.STANDALONE}`}
              className={classnames(toggleClassName, styles['form__password-reset'])}
            >
              <FormattedMessage
                id="SignInForm.ForgottenPasswordLink"
                description="SignInForm.ForgottenPasswordLink"
                defaultMessage="Forgotten your password?"
              />
            </Link>
          </Form>
        </div>
      )}
    />
  )
}

export default SignInForm
