import { IntlShape } from 'react-intl'
import validationMessages from '../../app/i18n/validationMessages'
import { passwordMinChars } from '../../utils/validationConst'
import Yup from '../../utils/yup'

const schema = (intl: IntlShape) =>
  Yup.object().shape({
    newPassword: Yup.string()
      .required(intl.formatMessage(validationMessages.schemaFieldRequired))
      .ensure()
      .min(passwordMinChars, intl.formatMessage(validationMessages.schemaMinChar, { val: passwordMinChars })),
    newPasswordConfirm: Yup.string()
      .oneOf([Yup.ref('newPassword')], intl.formatMessage(validationMessages.schemaPasswordsNotMatch))
      .required(intl.formatMessage(validationMessages.schemaFieldRequired)),
  })

export default schema
