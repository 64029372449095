import Typography from '@mui/material/Typography'
import React, { useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import routes from '../../../app/routes'
import CatalogList from '../../../components/CatalogList'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import FormattedMessage from '../../../components/FormattedMessage'
import PageLayout from '../../../components/PageLayout'
import Pagination, { PaginationModeEnum } from '../../../components/Pagination'
import useClubContext from '../../../hooks/club/clubContext'
import useSearch, { RESULTS_PER_PAGE } from '../../../hooks/search'
import CatalogInterface, { CatalogListItemInterface } from '../../../interfaces/CatalogInterface'
import PagerInterface from '../../../interfaces/PagerInterface'
import { SearchProductsInterface } from '../../../interfaces/SearchInterface'
import * as clubApi from '../../../services/club/club.api'
import styles from './ClubOffers.module.scss'

const ClubOffers = () => {
  const [items, setItems] = useState<CatalogListItemInterface[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [, setPager] = useState<PagerInterface>()
  const query = useRef<string>('')
  const { transformProducts } = useSearch()
  const clubContext = useClubContext()

  const getProducts = async (page: number, perPage: number = RESULTS_PER_PAGE, fetchAllPages: boolean = false, rest?: object) => {
    setPager({ currentPage: 0, itemCount: 0, pageCount: 0, perPage: 0 })

    //@ts-ignore
    const q = rest.query
    query.current = q || query.current

    setIsLoading(true)

    return clubApi.searchOffers(page, perPage, fetchAllPages).finally(() => {
      setIsLoading(false)
    })
  }

  const productsCallback = (data: SearchProductsInterface) => {
    setItems(data.items)
    setPager(data.pager)
  }

  if (!clubContext.isInClubFlow) {
    return <Redirect to={routes.home} />
  }

  return (
    <PageLayout>
      <ErrorBoundary>
        <div>
          <Typography variant="h1" align="center">
            {!isLoading && items.length === 0 ? (
              <div className={styles['no-products__container']}>
                <FormattedMessage
                  id="Club.Offers.ProductsNotFound"
                  defaultMessage="There are currently no products in the shop. Check back later when it is stocked again!"
                  className={styles['no-products__label']}
                />
              </div>
            ) : null}
          </Typography>
          {isLoading || items.length > 0 ? (
            <div>
              <Pagination<CatalogInterface, {}>
                mode={PaginationModeEnum.INFINITE}
                fetchData={getProducts}
                dataTransformer={transformProducts}
                ResultsComponent={CatalogList}
                resultsPerPage={RESULTS_PER_PAGE}
                fetchCallback={productsCallback}
                onLoading={setIsLoading}
                showLoader
                skipDefaultError
                resultsProps={{
                  isLoading,
                }}
                initItems={Array(RESULTS_PER_PAGE).fill(undefined)}
              />
            </div>
          ) : null}
        </div>
      </ErrorBoundary>
    </PageLayout>
  )
}

export default ClubOffers
