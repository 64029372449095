import * as notificationTypes from './Notification.types'
import * as actions from '../../services/notification/notification.actions'
import { NotifyElement, NotifyOptionsInterface } from '../../services/notification/notification.types'
import store from '../../services/store'

let id = 0

function generateId(options: NotifyOptionsInterface | null): number | string {
  if (options && ((typeof options.id === 'number' && !Number.isNaN(options.id)) || typeof options.id === 'string')) {
    return options.id
  }

  return ++id
}

function notify(notifyType: string, content: NotifyElement, options: NotifyOptionsInterface | null) {
  store.dispatch(actions.notificationCreateRequest(generateId(options), notifyType, content, options || {}))
}

function success(content: NotifyElement, options: NotifyOptionsInterface | null = null) {
  notify(notificationTypes.SUCCESS, content, options)
}

function info(content: NotifyElement, options: NotifyOptionsInterface | null = null) {
  notify(notificationTypes.INFO, content, options)
}

function warning(content: NotifyElement, options = null) {
  notify(notificationTypes.WARNING, content, options)
}

function error(content: NotifyElement, options = null) {
  notify(notificationTypes.ERROR, content, options)
}

function dismiss(_id: number | null = null) {
  _id ? store.dispatch(actions.notificationDeleteRequest(_id)) : store.dispatch(actions.notificationsDeleteRequest())
}

export default {
  success,
  info,
  warn: warning,
  warning,
  error,
  dismiss,
}
