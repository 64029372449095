import MuiAlert from '@mui/material/Alert'
import React from 'react'

export const PhishingAlert = () => {
  return (
    <MuiAlert elevation={6} variant="filled" severity="error">
      You have probably been the victim of a phishing attempt.
    </MuiAlert>
  )
}

export default PhishingAlert
