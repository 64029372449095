import { all, call, fork } from 'redux-saga/effects'
import app from './app/app.saga'
import auth from './auth/auth.saga'
import cart from './cart/cart.saga'
import locale from './locale/locale.saga'

export default function* rootSagas() {
  if (!cart || !locale || !auth || !app) return

  //yield all([fork(cart)])
  yield all([locale(), auth(), cart(), app()])
}
