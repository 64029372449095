import { FormControlProps, TextField } from '@mui/material'
import classNames from 'classnames'
import { LocationDescriptorObject } from 'history'
import { get, has } from 'lodash'
import React, { RefObject, useContext, useEffect, useRef, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import * as styles from './Search.module.scss'
import routes from '../../app/routes'
import HistoryContext from '../../context/HistoryContext'
import useSearch from '../../hooks/search'
import { _at } from '../../utils/translations'
import FormattedMessage from '../FormattedMessage/FormattedMessage'
import Link from '../Link'

type Props = {
  placeholder?: string
  isVisible?: boolean
  onClose?: () => void
  formControlProps?: FormControlProps
  disableNativeAutocomplete?: boolean
  classes?: {
    root?: string
    input?: string
  }
  field?: {
    name?: string
    label?: string
    id?: string
    helperText?: string
    error?: boolean
  }
  setResults?: React.Dispatch<React.SetStateAction<JSX.Element[]>>
  skipResultsRender?: boolean
}

type SearchLinkType = {
  to: string | LocationDescriptorObject
  classes?: {
    root?: string
  }
  ref?: RefObject<any>
}

const WAIT_BEFORE_SEARCH_TIMEOUT = 700 // ms
const MAX_VISIBLE_SUGGESTIONS = 100 // as fallback, as backend decides how many suggestions to show

export const Search = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>()
  const { query, cursor, setCursor, getHints, searchHints } = useSearch()
  const history = useContext(HistoryContext)
  const searchTimer = useRef<NodeJS.Timeout | null>(null)
  const [results, setResults] = useState<JSX.Element[]>([])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchTimer.current!)
    searchTimer.current = setTimeout(() => {
      getHints(event.target.value)
    }, WAIT_BEFORE_SEARCH_TIMEOUT)
  }

  const showMore = (value: string = query.current) => {
    if (props.onClose) {
      props.onClose()
    }
    history.push({ pathname: routes.search, search: `?query=${encodeURIComponent(value)}` })
  }

  const onShowMoreClick = () => {
    showMore(query.current)
  }

  const onSuggestionClick = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }

    getHints()

    if (props.onClose) {
      props.onClose()
    }
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value
    const keyCode = get(event, 'keyCode', -1)
    const maxLength = searchHints.length > MAX_VISIBLE_SUGGESTIONS ? MAX_VISIBLE_SUGGESTIONS - 1 : searchHints.length - 1

    if (keyCode === 13) {
      if (cursor < 0 && value !== '') {
        showMore(value)
      } else if (has(searchHints, cursor)) {
        history.push(searchHints[cursor].to!)
      } else if (cursor > searchHints.length - 1) {
        showMore(value)
      }
    } else if (keyCode === 38 && cursor > -1) {
      setCursor(cursor - 1)
    } else if (keyCode === 40 && cursor <= maxLength) {
      setCursor(cursor + 1)
    } else if (keyCode === 27 && props.onClose) {
      getHints('')
      if (inputRef.current) {
        inputRef.current.value = ''
      }
      props.onClose()
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [props.isVisible, inputRef.current])

  useEffect(() => {
    const elements = searchHints?.map((suggestion, i) => {
      const productId = suggestion.id
      const linkProps: SearchLinkType = {
        to: suggestion.to || '',
      }
      return (
        <CSSTransition key={productId} timeout={200} classNames={animClasses}>
          <li className={styles['suggestion']}>
            <Link
              onClick={onSuggestionClick}
              classes={{ root: classNames(styles['suggestion__link'], { [styles['suggestion__link--active']]: cursor === i }) }}
              {...linkProps}
            >
              <span>{_at('name', suggestion.translations)}</span>
            </Link>
          </li>
        </CSSTransition>
      )
    })
    setResults(elements)

    if (props.setResults) {
      props.setResults(elements)
    }
  }, [JSON.stringify(searchHints), searchHints.length])

  const animClasses = {
    enterActive: styles['suggestion--enter-active'],
    enterDone: styles['suggestion--enter-done'],
    exitActive: styles['suggestion--exit-active'],
    exitDone: styles['suggestion--exit-done'],
  }

  if (results.length > 0) {
    results.push(
      <CSSTransition key={100} timeout={200} classNames={animClasses}>
        <li className={styles['suggestion']}>
          <a
            className={classNames(styles['suggestion__show-more'], { [styles['suggestion__link--active']]: cursor === searchHints.length })}
            onClick={onShowMoreClick}
          >
            <FormattedMessage id="search.showMore" defaultMessage="Show more results" tag="span" />
          </a>
        </li>
      </CSSTransition>,
    )
  }

  return (
    <div className={classNames(styles['search-box'], props.classes?.root)}>
      <TextField
        placeholder={props.placeholder ? props.placeholder : ''}
        fullWidth
        autoFocus
        focused
        classes={{ root: classNames(styles['input'], props.classes?.input) }}
        name={props.field?.name || 'search'}
        id={props.field?.id || 'search'}
        helperText={props.field?.helperText}
        error={props.field?.error}
        InputProps={{
          inputRef,
          classes: { input: props.classes?.input },
        }}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      {props.skipResultsRender ? null : (
        <TransitionGroup component="ul" className={styles['suggestions']}>
          {results}
        </TransitionGroup>
      )}
    </div>
  )
}

export default Search
