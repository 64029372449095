import _ from 'lodash'
import { ActionTypes, types } from './cart.types'
import { CartInterface } from '../../interfaces/CartInterface'
import { ChannelSiteInterface } from '../../interfaces/ChannelInterface'

export interface State extends CartInterface {}

export const initialState: Readonly<State> = {
  id: null,
  status: 1,
  currency: '',
  itemCount: false,
  totalItemsNet: '',
  totalItemsGross: '',
  totalItemsTax: '',
  totalNet: '',
  totalGross: '',
  totalTax: '',
  shippingCostNet: '',
  shippingCostGross: '',
  userId: null,
  createdAt: '',
  submittedAt: '',
  exchangeRate: '',
  locale: '',
  valid: false,
  errors: [],
  items: [],
  shippingMethod: null,
  billingAddress: null,
  shippingAddress: null,
  discounts: [],
  totalDiscountNet: '',
  totalDiscountGross: '',
  payments: [],
  gifts: null,
  requiredPayment: '',
  deliveryNotice: null,
  authorityToLeave: undefined,
  type: null,
  channelEntry: {} as ChannelSiteInterface,
  paymentsLimit: 6,
  forcedCountry: null,
}

export default function cartReducer(state: State = initialState, action: ActionTypes) {
  switch (action.type) {
    case types.UPDATE_SUCCESS: {
      let newState = _.clone(state)
      newState = { ...action.payload }
      return newState
    }

    case types.DELETE_SUCCESS: {
      const newState = _.clone(initialState)
      return newState
    }

    default: {
      return state
    }
  }

  return state
}
