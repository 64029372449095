import React from 'react'
import { State as UserState } from '../services/user/user.reducer'

const UserContext = React.createContext<UserState>({
  profile: undefined,
  auth: {
    tokenId: undefined,
    secret: undefined,
  },
})

export default UserContext
