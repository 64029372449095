import { Button, DialogActions, DialogContent } from '@mui/material'
import { FormikProps } from 'formik'
import * as React from 'react'
import styles from './PrivacyConsentDetailsDialogView.module.scss'
import PrivacyConsentInterface, { PrivacyConsentsFieldEnum } from '../../../interfaces/PrivacyConsentInterface'
import FormattedMessage from '../../FormattedMessage/FormattedMessage'
import FormCheckboxField from '../../FormCheckboxField/FormCheckboxField'

type Props = {
  formik: FormikProps<PrivacyConsentInterface>
  onSubmit: () => void
}

const PrivacyConsentDetailsView = (props: Props) => {
  const { formik, onSubmit } = props
  const formControlProps = {
    className: styles['details__checkbox'],
  }

  return (
    <>
      <DialogContent>
        <form className={styles['details__content-container']} onSubmit={formik.handleSubmit}>
          <FormCheckboxField
            field={{
              name: PrivacyConsentsFieldEnum.REQUIRED,
              value: formik.values[PrivacyConsentsFieldEnum.REQUIRED],
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              disabled: true,
            }}
            label={<FormattedMessage id="PrivacyConsentDialog.RequiredTitle" defaultMessage="Required" />}
            form={formik}
            formControlProps={formControlProps}
          />
          <FormattedMessage
            id="PrivacyConsentDialog.RequiredDescription"
            className={styles['details__description']}
            defaultMessage="These cookies are necessary for the website to function and cannot be switched off in our systems. 
            They don't store personally identifiable information."
          />
          <FormCheckboxField
            field={{
              name: PrivacyConsentsFieldEnum.FUNCTIONAL,
              value: formik.values[PrivacyConsentsFieldEnum.FUNCTIONAL],
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
            }}
            label={<FormattedMessage id="PrivacyConsentDialog.FunctionalTitle" defaultMessage="Functional" />}
            form={formik}
            formControlProps={formControlProps}
          />
          <FormattedMessage
            id="PrivacyConsentDialog.FunctionalDescription"
            className={styles['details__description']}
            defaultMessage="These cookies allow us to count visits and traffic sources so we can measure and improve 
            the performance of our site, they allow us to use behavioural data to optimise performance,
            review how you interact with our sites."
          />
          <FormCheckboxField
            field={{
              name: PrivacyConsentsFieldEnum.MARKETING,
              value: formik.values[PrivacyConsentsFieldEnum.MARKETING],
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
            }}
            label={<FormattedMessage id="PrivacyConsentDialog.MarketingTitle" defaultMessage="Marketing" />}
            form={formik}
            formControlProps={formControlProps}
          />
          <FormattedMessage
            id="PrivacyConsentDialog.MarketingDescription"
            className={styles['details__description']}
            defaultMessage="These cookies are used to track visitors across websites. The intention 
            is to display ads that are relevant and engaging for the individual user and thereby 
            more valuable for publishers and third party advertisers."
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} color="info" variant="contained">
          <FormattedMessage id="PrivacyConsentDialog.SaveChoices" defaultMessage="Save choices" />
        </Button>
      </DialogActions>
    </>
  )
}

export default PrivacyConsentDetailsView
