import { Button, FormControl } from '@mui/material'
import { FormikHelpers, useFormik } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import FormattedMessage from '../FormattedMessage'
import FormTextField from '../FormTextField'
import schema from './ClubSignInFormSchema'

export interface ClubSignInFormValues {
  password: string
}

interface Props {
  className: string
  onSubmit: (values: ClubSignInFormValues, formik: FormikHelpers<ClubSignInFormValues>) => Promise<void>
}

const ClubSignInForm = (props: Props) => {
  const intl = useIntl()

  const formik = useFormik<ClubSignInFormValues>({
    initialValues: {
      password: '',
    },
    validateOnMount: false,
    validationSchema: schema,
    onSubmit: async (values, formikHelpers) => {
      await props.onSubmit(values, formikHelpers)
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await formik.submitForm()
  }

  return (
    <div className={props.className}>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth>
          <FormTextField
            fullWidth
            form={formik}
            type="password"
            label={intl.formatMessage({ id: 'Club.SignInForm.Password', defaultMessage: 'Password' })}
            field={{
              name: 'password',
              value: formik.values.password,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            <FormattedMessage id="Club.SignInForm.SubmitActionButton" defaultMessage="Sign In" />
          </Button>
        </FormControl>
      </form>
    </div>
  )
}

export default ClubSignInForm
