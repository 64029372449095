import _ from 'lodash'
import CatalogInterface, { CatalogItemType, CatalogListItemInterface, CatalogListProductInterface } from '../interfaces/CatalogInterface'
import { getCategoryRoute, getProductName, getProductRoute } from '../utils/catalog'
import { _at } from '../utils/translations'

type TransformerType = (items: CatalogListItemInterface[]) => CatalogListItemInterface[]

export const toCatalogProducts = (items: CatalogListProductInterface[]) => {
  return items.map((item: CatalogListProductInterface) => {
    const itemSlug = _at(false, item.slug!) || _at('slug', item.description.translations)
    const styleCategory = _at('styleCategory', item.translations)
    const styleName = _at('styleName', item.translations)
    const styleType = _at('styleType', item.translations)

    item.itemType = CatalogItemType.Product
    item.to = getProductRoute(item.id, itemSlug)
    item.name = getProductName(styleCategory, styleName, styleType)

    return item
  })
}

export const toCatalogCategories = (items: CatalogListItemInterface[]) => {
  return items.map((item: CatalogListItemInterface) => {
    const categorySlug = item && item.description.translations ? _at('slug', item.description.translations) : ''
    item.itemType = CatalogItemType.Category
    item.to = getCategoryRoute(item.id, categorySlug)
    item.name = _at('name', item.translations)
    return item
  })
}

export const toCatalogItems = (data: CatalogInterface, transformer: TransformerType) => ({
  items: transformer(data.items),
  pager: _.get(data, 'pager'),
  filters: _.get(data, 'filters'),
})
