import { ActionTypes, types } from './cart.types'
import { CartInterface } from '../../interfaces/CartInterface'
/* eslint-disable @typescript-eslint/no-shadow */

export const ACTION_ADJUST = ' CART_ACTION_ADJUST'
export const ACTION_SET = ' CART_ACTION_SET'
export enum ACTION_METHOD {
  ACTION_ADJUST,
  ACTION_SET,
}

export function cartDeleteRequest(): ActionTypes {
  return {
    type: types.DELETE_REQUEST,
  }
}

export function cartDeleteSuccess(): ActionTypes {
  return {
    type: types.DELETE_SUCCESS,
  }
}

export function cartReadRequest(): ActionTypes {
  return {
    type: types.UPDATE_REQUEST,
  }
}

export function cartReadSuccess(order: CartInterface): ActionTypes {
  return {
    type: types.UPDATE_SUCCESS,
    payload: order,
  }
}
