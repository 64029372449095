import { _at } from './translations'
import routes from '../app/routes'
import { PriceInterface } from '../interfaces/ProductInterface'

export const getProductRoute = (productId: number, productSlug: string) => ({
  name: routes.product,
  params: {
    productId,
    productSlug,
  },
})

export const getCategoryRoute = (categoryId: number, categorySlug: string) => ({
  name: routes.catalog,
  params: {
    categoryId,
    categorySlug,
  },
})

export const getProductPrice = (prices: PriceInterface[], currency: string) => {
  const defaultPrice = {
    grossPrice: '0.00',
    currency,
    salePriceGross: null,
    percentDiscountAmount: null as number | null,
  }
  const itemPrice = prices?.find(price => price.currency === currency)
  return itemPrice || defaultPrice
}

export const getProductName = (styleCategory: string, styleName: string, styleType: string) => {
  return `${styleCategory || ''} ${styleName || ''} ${styleType || ''}`
}
