import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './Blocks.module.scss'
import ChannelContext from '../../../context/ChannelContext'
import CategoryInterface from '../../../interfaces/CategoryInterface'
import { State } from '../../../services/reducers'
import ChannelLink from '../../ChannelLink'
import Link from '../../Link'
import * as MenuHelper from '../../Menu/Menu.helper'

type Props = {
  categories: CategoryInterface[]
}

const CHANNEL_SWITCHER_URL = '$channelSwitcher$'

const Blocks = (props: Props) => {
  const channel = React.useContext(ChannelContext)
  const locations = useSelector((state: State) => state.global.locations[channel.alternate?.id || -1])
  const [mobileBlockActive, setMobileBlockActive] = useState<boolean | number>(false)
  const { categories } = props
  const language = useSelector((state: State) => state.locale.language)
  const fallbackLanguageIsoCode = useSelector((state: State) => state.global.fallbackLanguageIsoCode)
  const onBlockClick = (id: number) => {
    setMobileBlockActive(mobileBlockActive === id ? false : id)
  }

  const items = MenuHelper.getMenu({
    categories,
    locale: language.isoCode,
    fallbackLocale: fallbackLanguageIsoCode,
    //TODO: add typing for item after helper refactor
    linkBuilder: (category: CategoryInterface, item: any) => {
      if (category.url) {
        return category.url
      }
      return ''
    },
  })

  const blockCategories = MenuHelper.getMegaMenu(items)
  //TODO: add typing item after helper refactor

  const renderBlock = (item: any) => {
    const listClasses = classNames(styles['block__list'], {
      [styles['block__list--showed']]: mobileBlockActive === item.id,
    })

    const iconClasses = {
      root: styles['heading__icon'],
    }

    const icon = mobileBlockActive === item.id ? <ArrowDropUpIcon classes={iconClasses} /> : <ArrowDropDownIcon classes={iconClasses} />

    return (
      <div className={styles['blocks__block']} key={item.id}>
        <span
          className={styles['block__heading']}
          onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => onBlockClick(item.id)}
        >
          {item.anchor}
          {icon}
        </span>
        <ul className={listClasses}>{item.children.map(renderBlockItem)}</ul>
      </div>
    )
  }
  //TODO: add typing item after helper refactor
  const renderBlockItem = (item: any) => {
    if (item.link == CHANNEL_SWITCHER_URL) {
      return (
        <li key={item.id} className={styles['list__item']}>
          <ChannelLink code={channel?.alternate?.code} to={locations?.last || '/'}>
            {item.anchor}
          </ChannelLink>
        </li>
      )
    }
    return (
      <li key={item.id} className={styles['list__item']}>
        <Link to={item.link}>{item.anchor}</Link>
      </li>
    )
  }

  return <div className={styles['blocks']}>{blockCategories && blockCategories.length > 0 ? blockCategories.map(renderBlock) : null}</div>
}

export default Blocks
