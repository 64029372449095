import UserInterface from '../../interfaces/UserInterface'

export enum types {
  USER_PROFILE_READ = 'USER_PROFILE_READ',
}

export interface UserProfileReadSuccess {
  type: types.USER_PROFILE_READ
  payload: UserInterface
}

export type ActionTypes = UserProfileReadSuccess
