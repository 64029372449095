import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import React from 'react'
import styles from './SidebarAuthForms.module.scss'
import ChannelContext from '../../../context/ChannelContext'
import FacebookButton from '../../FacebookButton'
import FormattedMessage from '../../FormattedMessage'
import SignInForm from '../../SignInForm'
import SignUpForm from '../../SignUpForm'

type Props = {
  onFacebookButtonClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const SidebarAuthForms = (props: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <ChannelContext.Consumer>
      {({ current: channel }) => (
        <div className={styles['container']}>
          <Accordion className={styles['accordion']} expanded={expanded === 'sign-in'} onChange={handleChange('sign-in')}>
            <AccordionSummary
              classes={{
                root: styles['accordion__label'],
                content: styles['accordion__caption'],
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel-sign-in"
            >
              <FormattedMessage
                id="SidebarAuthForms.SignInAccount"
                description="SidebarAuthForms.SignInAccount"
                defaultMessage="Sign In To Your Account"
              />
            </AccordionSummary>
            <AccordionDetails>
              <SignInForm exited={false} toggle={() => {}} expanded onExpand={() => {}} />
            </AccordionDetails>
          </Accordion>
          <Accordion className={styles['accordion']} expanded={expanded === 'sign-up'} onChange={handleChange('sign-up')}>
            <AccordionSummary
              classes={{
                root: styles['accordion__label'],
                content: styles['accordion__caption'],
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel-sign-up"
            >
              <FormattedMessage id="SidebarAuthForms.SignUp" description="SidebarAuthForms.SignUp" defaultMessage="Become a Member" />
            </AccordionSummary>
            <AccordionDetails>
              <SignUpForm
                newsletterOption={channel.newsletterOption || undefined}
                exited={false}
                toggle={() => {}}
                expanded
                onExpand={() => {}}
              />
            </AccordionDetails>
          </Accordion>
          {/* <FacebookButton
            className={styles['btn-facebook']}
            label={<FormattedMessage id="SignForms.LoginByFacebookButton" defaultMessage="Continue with Facebook" />}
            onClick={props.onFacebookButtonClick}
          /> */}
        </div>
      )}
    </ChannelContext.Consumer>
  )
}

export default SidebarAuthForms
