import classnames from 'classnames'
import React from 'react'
import routes from '../../../app/routes'
import FormattedMessage from '../../FormattedMessage'
import Link from '../../Link'

type Classes = {
  link?: string
}

type Props = {
  classes?: Classes
}

const GuestMenu = (props: Props) => {
  return (
    <Link to={routes.authLogin} classes={{ root: classnames(props.classes?.link, 'login') }}>
      <FormattedMessage id="AuthMenu.Guest.LogIn" description="AuthMenu.Guest.LogIn" defaultMessage="Log In" />
    </Link>
  )
}

export default GuestMenu
