import classNames from 'classnames'
import React, { ErrorInfo } from 'react'
import ChildrenInterface from '../../interfaces/ChildrenInterface'
import isDevMode from '../../utils/isDevMode'
import FormattedMessage from '../FormattedMessage'

type Props = {
  show?: boolean
  message?: string
  children: ChildrenInterface
}

type State = {
  error: Error | null
  info: ErrorInfo | null
}

class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError(error: Error) {
    ErrorBoundary.logError(error, null)
    return {
      error,
      info: null,
    }
  }

  static logError(error: Error, info: ErrorInfo | null) {
    if (isDevMode) {
      console.error({ error, info })
    }
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      error: null,
      info: null,
    } as State
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    ErrorBoundary.logError(error, info)
    this.setState({
      error,
      info,
    })
  }

  render() {
    const { show, message } = this.props

    if (this.state.error === null) {
      return this.props.children
    }
    if (show !== true) {
      return null
    }

    let error

    if (isDevMode) {
      error = [<p key="message">{this.state.error.message}</p>, <pre key="stack">{this.state.error.stack}</pre>]
    } else if (message!.length > 0) {
      error = <span>{message}</span>
    } else {
      error = <FormattedMessage id="App.UnknownError" defaultMessage="An unknown error has occurred" />
    }

    console.info(this.state.info)

    return <div className={classNames('error-boundary', isDevMode && 'dev')}>{error}</div>
  }
}
//@ts-ignore
ErrorBoundary.defaultProps = { show: true, message: '' }

export default ErrorBoundary
