import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import MegaMenu from './MegaMenu'
import * as MenuHelper from './Menu.helper'
import MobileMenu from './MobileMenu'
import routes from '../../app/routes'
import CategoryInterface from '../../interfaces/CategoryInterface'
import { ChannelInterface } from '../../interfaces/ChannelInterface'
import { MenuItemInterface } from '../../interfaces/MenuInterface'
import { State as AppState } from '../../services/reducers'
import { _at } from '../../utils/translations'
import { resolveRoute } from '../Link/linkResolver'
import { DISPLAY_MODE } from '../Localization/SwitchPanel'
/* eslint-disable no-nested-ternary */

type Classes = {
  root?: string
}

type Props = {
  classes?: Classes
  channel: ChannelInterface
  show?: boolean
  type: DISPLAY_MODE
  onItemClick?: (event: React.SyntheticEvent) => void
  selfRef?: React.RefObject<HTMLDivElement>
}

type State = {
  channelId: number
  parentItem: MenuItemInterface | undefined | null
  activeItem: MenuItemInterface | undefined | null
  menu: MenuItemInterface[]
}

const Menu = (props: Props) => {
  const handleItemClick = (event: React.SyntheticEvent): void => {
    const target = event.target as HTMLButtonElement
    const activeItem = MenuHelper.getItemByPathname(state.menu, target.dataset['link'] as string)
    setState({ ..._.cloneDeep(state), activeItem })
  }

  const handleMobileItemClick = (event: React.SyntheticEvent): void => {
    const target = event.target as HTMLButtonElement
    const nextItem = MenuHelper.getItemByPathname(state.menu, target.dataset['link'] as string)
    const hasChildren =
      nextItem !== null &&
      (nextItem.id != null || nextItem.parentId != null) &&
      state.menu.findIndex(item => item.parentId === nextItem.id) !== -1

    setIsBackClicked(false)

    if (hasChildren) {
      event.preventDefault()
      setState({ ..._.cloneDeep(state), parentItem: nextItem })
    } else {
      setState({ ..._.cloneDeep(state), activeItem: nextItem })

      if (props.onItemClick != null) {
        props.onItemClick(event)
      }
    }
  }

  const handleMobileBackClick = (): void => {
    if (!isBackClicked) {
      const parentItem = MenuHelper.getParentItem(state.menu, getActiveParentItem(state))
      setIsBackClicked(true)
      setState({ ..._.cloneDeep(state), parentItem })
    }
  }

  const getMenu = (channel: ChannelInterface, locale: string, fallbackLocale: string): MenuItemInterface[] =>
    MenuHelper.getMenu({
      categories: channel.categories,
      locale,
      fallbackLocale,
      linkBuilder: (category: CategoryInterface, item: MenuItemInterface<null>) => {
        if (category.url) {
          return category.url
        }

        return resolveRoute(routes.catalog, {
          categoryId: category.id,
          categorySlug: _at('slug', category.description.translations, locale),
        })
      },
    })

  const { locale, fallbackLocale } = useSelector<AppState, Locales>(store => ({
    locale: store.locale.language.isoCode,
    fallbackLocale: store.global.fallbackLanguageIsoCode,
  }))
  const menuItems = getMenu(props.channel, locale, fallbackLocale)
  const activeItem = MenuHelper.getItemByPathname(menuItems, window.location.pathname)
  const parentItem = MenuHelper.getParentItem(menuItems, activeItem)
  const [isBackClicked, setIsBackClicked] = React.useState<boolean>(false)
  const [state, setState] = React.useState<State>(buildState(props.channel, parentItem, activeItem, menuItems))
  let result: JSX.Element

  if (props.type === DISPLAY_MODE.MOBILE) {
    result = (
      <MobileMenu
        classes={{ root: props.classes?.root }}
        items={state.menu}
        parentItem={getActiveParentItem(state)}
        activeItem={getActiveItem(state)}
        isBackClicked={isBackClicked}
        onItemClick={handleMobileItemClick}
        onBackClick={handleMobileBackClick}
        onAnimExited={() => setIsBackClicked(false)}
      />
    )
  } else if (props.type === DISPLAY_MODE.DESKTOP) {
    result = (
      <MegaMenu
        selfRef={props.selfRef}
        classes={{ root: props.classes?.root }}
        items={state.menu}
        activeItem={MenuHelper.getItemByPathname(state.menu, window.location.pathname)}
        onItemClick={handleItemClick}
      />
    )
  } else {
    throw new Error('Invalid menu type')
  }

  return <>{result}</>
}

const getActiveItem = (state: State): MenuItemInterface | null =>
  state.activeItem !== undefined
    ? state.activeItem !== null
      ? _.cloneDeep(state.activeItem)
      : null
    : MenuHelper.getItemByPathname(state.menu, window.location.pathname)

const getActiveParentItem = (state: State): MenuItemInterface | null =>
  state.parentItem !== undefined
    ? state.parentItem !== null
      ? _.cloneDeep(state.parentItem)
      : null
    : MenuHelper.getParentItem(state.menu, getActiveItem(state))

type Locales = {
  locale: string
  fallbackLocale: string
}

const buildState = (
  channel: ChannelInterface,
  parentItem: MenuItemInterface | null,
  activeItem: MenuItemInterface | null,
  menu: MenuItemInterface[],
) => ({
  channelId: channel.id,
  parentItem,
  activeItem,
  menu,
})

export default Menu
