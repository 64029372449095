import { createTheme } from '@mui/material/styles'
import _ from 'lodash'
import theme from './funkita.module.scss'
import { overrides as muiOverrides } from './mui'

const funkitaTheme = createTheme({
  palette: {
    primary: {
      main: theme['color--primary'],
      contrastText: theme['color__text--primary'],
    },
    secondary: {
      main: theme['color--secondary'],
      contrastText: theme['color__text--secondary'],
    },
    grey: {
      500: theme['color--gray'],
    },
    error: {
      main: theme['color--error'],
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
  shape: {
    borderRadius: 4,
  },
  components: _.merge({}, muiOverrides, {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-outlinedInfo': {
            borderColor: theme['mui-button__background--info'],
            color: theme['color__text--secondary'],
            ':hover': {
              background: theme['mui-button-text__background--info-hover'],
            },
          },
          '&.MuiButton-containedInfo': {
            background: theme['mui-button__background--info'],
            color: theme['color__text--secondary'],
          },
          '&.MuiButton-textInfo': {
            color: theme['color__text--secondary'],
            ':hover': {
              background: theme['mui-button-text__background--info-hover'],
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          background: theme['color--secondary'],
          borderColor: theme['color--gray'],
          '&:last-child': {
            borderRightColor: theme['color--gray'],
          },
          '&selected': {
            background: theme['color--primary'],
            borderColor: theme['color--primary'],
            '&:last-child': {
              borderRightColor: theme['color--primary'],
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          background: '#ff5a5a',
        },
      },
    },
  }),
})

export default funkitaTheme
