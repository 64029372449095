import { PresetInterface, SizeGuideLabelKeys, SizeLabelItemType } from '../../interfaces/SizeGuideInterface'

const BOTTOM_BODY_LABELS: SizeGuideLabelKeys[] = ['waist', 'hip']
const FULL_BODY_LABELS: SizeGuideLabelKeys[] = ['chest', 'bodyLength', ...BOTTOM_BODY_LABELS]

const unisexPreset: PresetInterface = { label: 'Unisex', type: 'unisex', requiredLabels: ['chest', ...BOTTOM_BODY_LABELS] }

export const womenOrder: PresetInterface[] = [
  { label: 'Women', type: 'women', image: 'img/sizing_ladies.jpg', requiredLabels: FULL_BODY_LABELS },
  { label: 'Girls', type: 'girls', image: 'img/sizing_girls.jpg', requiredLabels: FULL_BODY_LABELS },
  { label: 'Toddler Girls', type: 'toddlerGirls', image: 'img/sizing_toddlergirls.jpg', requiredLabels: ['chest', 'waist'] },
  {
    label: 'Performance Women',
    type: 'performanceWoman',
    image: 'img/sizing_perfwomen.jpg',
    requiredLabels: FULL_BODY_LABELS,
  },
  unisexPreset,
]

export const menOrder: PresetInterface[] = [
  { label: 'Men', type: 'men', image: 'img/sizing_mens.jpg', requiredLabels: ['chest', ...BOTTOM_BODY_LABELS] },
  { label: 'Boys', type: 'boys', image: 'img/sizing_boys.jpg', requiredLabels: ['waist', 'hip'] },
  {
    label: 'Toddler Boys',
    type: 'toddlerBoys',
    image: 'img/sizing_toddlerboys.jpg',
    requiredLabels: ['chest', 'waist'],
  },
  {
    label: 'Performance Men',
    type: 'performanceMen',
    image: 'img/sizing_perfmen.jpg',
    requiredLabels: BOTTOM_BODY_LABELS,
  },
  unisexPreset,
]

export const sizeLabels: SizeLabelItemType[] = [
  { key: 'australia', label: 'AUS', isCountryLabel: true },
  { key: 'us', label: 'US', isCountryLabel: true },
  { key: 'uk', label: 'UK', isCountryLabel: true },
  { key: 'france', label: 'France', isCountryLabel: true },
  { key: 'italy', label: 'Italy', isCountryLabel: true },
  { key: 'germany', label: 'Germany', isCountryLabel: true },
  { key: 'chest', label: 'Chest' },
  { key: 'waist', label: 'Waist' },
  { key: 'hip', label: 'Hip' },
  { key: 'bodyLength', label: 'Body Loop' },
]
