import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as actions from './locale.actions'
import * as api from './locale.api'
import { getCurrencyByCode, getLanguageByIsoCode } from '../../utils/locale'
import {
  CurrencyUpdateRequestInterface,
  CurrencyUpdateSuccessInterface,
  LanguageUpdateRquestInterface,
  LanguageUpdateSuccessInterface,
  types,
} from './locale.types'

/** Language **/
function* languageUpdateRequestEvent(action: LanguageUpdateRquestInterface): any {
  let successAction = null
  try {
    const response = yield call(api.updateLanguage, action.payload)
    successAction = yield put(actions.updateLanguageSuccess(getLanguageByIsoCode(response.data.locale)))
  } catch (error: any) {
    console.log(error)
    successAction = yield put(actions.updateLanguageSuccess(getLanguageByIsoCode(action.payload.isoCode)))
  }
  yield all([successAction])
}

function* languageUpdateSuccessEvent(action: LanguageUpdateSuccessInterface) {
  yield all([])
}

function* languageUpdateRequestListener() {
  yield takeEvery(types.UPDATE_LANGUAGE_REQUEST, languageUpdateRequestEvent)
}

function* languageUpdateSuccessListener() {
  yield takeEvery(types.UPDATE_LANGUAGE_SUCCESS, languageUpdateSuccessEvent)
}

/** Currency **/
function* currencyUpdateRequestEvent(action: CurrencyUpdateRequestInterface): any {
  let successAction = null
  try {
    const response = yield call(api.updateCurrency, action.payload)
    successAction = yield put(actions.updateCurrencySuccess(getCurrencyByCode(response.data.currency)))
  } catch (error: any) {
    console.log(error)
  }
  yield all([successAction])
}

function* currencyUpdateSuccessEvent(action: CurrencyUpdateSuccessInterface) {
  yield all([])
}

function* currencyUpdateRequestListener() {
  yield takeEvery(types.UPDATE_CURRENCY_REQUEST, currencyUpdateRequestEvent)
}

function* currencyUpdateSuccessListener() {
  yield takeEvery(types.UPDATE_LANGUAGE_SUCCESS, currencyUpdateSuccessEvent)
}

export default function* localeSaga() {
  yield all([
    currencyUpdateRequestListener(),
    currencyUpdateSuccessListener(),
    languageUpdateRequestListener(),
    languageUpdateSuccessListener(),
  ])
}
