import BigNumber from 'bignumber.js'
import moment from 'moment-timezone'
import store from '../services/store'

/* eslint-disable class-methods-use-this */

export const DefaultTimezone = 'Australia/Melbourne'

class DateUtil {
  getLocalTimezone() {
    //console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
    return Intl.DateTimeFormat().resolvedOptions().timeZone || DefaultTimezone
  }

  /**
   * Only for Date, not DateTime as it midnight as default
   * @param date
   * @param noZero
   */
  dateToUnixTimestamp(date: string, noZero?: true): number | false {
    moment.locale(store.getState().locale.language.isoCode)
    const currentFormat = this.getDateFormatPattern(moment())
    const isDate = moment(date, currentFormat, true).isValid()
    const momentLocal = moment(date, currentFormat, true).tz(this.getLocalTimezone())
    const momentUTC = moment(date, currentFormat, true).utc()
    const timestamp = new BigNumber(momentUTC.unix()).plus(new BigNumber(momentLocal.utcOffset()).multipliedBy(60).toNumber()).toNumber()
    if ((!timestamp || Number.isNaN(timestamp)) && !Number.isNaN(parseInt(date, 10))) {
      return parseInt(date, 10)
    }

    if (Number.isNaN(timestamp)) {
      return false
    }
    return isDate === false && !noZero ? 0 : timestamp
  }

  timestampToDateString(timestamp: number | string | null | undefined) {
    if (!timestamp) {
      return null
    }

    if (typeof timestamp === 'string') {
      timestamp = parseInt(timestamp, 10)
    }

    moment.locale(store.getState().locale.language.isoCode)
    const currentFormat = this.getDateFormatPattern(moment as unknown as moment.Moment)
    return moment.unix(timestamp).tz(this.getLocalTimezone()).format(currentFormat)
  }

  /**
   * Return the date format from Moment.ts
   * @param longFormat - possible values below
   * LT : 'HH:mm',
   * LTS : 'HH:mm:ss',
   * L : 'DD/MM/YYYY',
   * LL : 'D MMMM YYYY',
   * LLL : 'D MMMM YYYY HH:mm',
   * LLLL : 'dddd D MMMM YYYY HH:mm'
   **/
  getDateFormatPattern(dateMoment: moment.Moment, longFormat: moment.LongDateFormatKey = 'L') {
    moment.locale(store.getState().locale.language.isoCode)
    const momentLocale = dateMoment.localeData()

    return momentLocale.longDateFormat(longFormat)
  }
}

const date = new DateUtil()
export default date
