import classNames from 'classnames'
import React from 'react'
import styles from './QuickCartAdd.module.scss'
import QuickCartAddItem from './QuickCartAddItem'
import useMatchMedia from '../../hooks/matchMedia/matchMedia'
import { ProductInterface } from '../../interfaces/ProductInterface'
import { ViewMode } from '../CatalogList/CatalogViewSwitch/CatalogViewSwitch'
import FormattedMessage from '../FormattedMessage'

type Props = {
  item?: ProductInterface
  visible?: boolean
  viewMode?: ViewMode
}

export const QuickCartAdd = (props: Props) => {
  const { item, visible } = props
  const { isMobile } = useMatchMedia()

  const classes = classNames(styles['quick-add'], {
    [styles['quick-add--visible']]: visible !== false,
    [styles['quick-add--double-column']]: isMobile && props.viewMode === ViewMode.DOUBLE_COLUMNS,
  })

  const items = React.useMemo(() => {
    if (item?.variants) {
      return item?.variants
        ?.sort((a, b) => {
          if (a.size.sortableRank > b.size.sortableRank) return 1
          return -1
        })
        .map(_item => <QuickCartAddItem item={_item} product={item} key={_item.id} />)
    }
    if (item) {
      return [<QuickCartAddItem item={item!} product={item} key={item.id} />]
    }
  }, [JSON.stringify(item?.variants)])

  if (!item?.variants && !item?.id) return null
  return (
    <div className={classes}>
      <FormattedMessage id="CatalogListProduct.QuickAddHeading" defaultMessage="Quick Add" className={styles['quick-add__heading']} />
      <div className={styles['quick-add__items']}>{items}</div>
    </div>
  )
}

export default QuickCartAdd
