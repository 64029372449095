export enum CHANNEL_THEMES {
  FUNKITA = 'funkita',
  FUNKYTRUNKS = 'funkytrunks',
  FK = '/fk/',
  FT = '/ft/',
}

export function getThemeFromUrl() {
  const host = window?.location.host
  const path = window?.location.pathname
  if (host.includes(CHANNEL_THEMES.FUNKYTRUNKS) || path.startsWith(CHANNEL_THEMES.FT)) {
    return CHANNEL_THEMES.FUNKYTRUNKS
  }
  //also as fallback
  return CHANNEL_THEMES.FUNKITA
}
