import CloseIcon from '@mui/icons-material/Close'
import { Drawer, Tab, Tabs, Typography } from '@mui/material'
import React from 'react'
import Flag from 'react-country-flag'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import styles from './Sidebar.module.scss'
import SidebarTabAccount from './SidebarTabAccount'
import SidebarTabLocalization from './SidebarTabLocalization'
import ChannelContext from '../../context/ChannelContext'
import useMatchMedia from '../../hooks/matchMedia'
import { State } from '../../services/reducers'
import { DEFAULT_COUNTRY_CODE } from '../../utils/locale'
import { DISPLAY_MODE } from '../Localization/SwitchPanel'
import Menu from '../Menu'

type Props = {
  show: boolean
  onClose: () => void
}

const Sidebar = (props: Props) => {
  const intl = useIntl()
  const language = useSelector((store: State) => store.locale.language)

  const handleMenuItemClick = () => {
    props.onClose()
  }

  const handleItemClick = () => {
    props.onClose()
  }

  const handleTabClick = (event: React.ChangeEvent<{}>, value: number) => {
    setValue(value)
  }

  const handleCloseClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    props.onClose()
  }

  const [value, setValue] = React.useState(0)
  const { isDesktop } = useMatchMedia()
  const { show } = props

  const drawerClasses = {
    paper: styles['drawer'],
  }

  const tabsClasses = {
    root: styles['sidebar__tabs'],
    indicator: styles['sidebar__indicator'],
  }

  const tabClasses = {
    root: styles['sidebar__tab'],
    selected: styles['sidebar__tab--selected'],
    wrapper: styles['sidebar__tab__label'],
    labelContainer: styles['sidebar__tab__label'],
  }

  const tabCloseClasses = {
    ...tabClasses,
    labelWrapped: styles['sidebar__tab__label'],
    root: styles['sidebar__tab-close'],
  }

  return isDesktop ? null : (
    <ChannelContext.Consumer>
      {({ current: channel }) => (
        <Drawer disablePortal anchor="left" open={show} onClose={props.onClose} classes={drawerClasses}>
          <div className="desktop--hidden">
            <Tabs classes={tabsClasses} onChange={handleTabClick} value={value}>
              <Tab
                classes={tabClasses}
                disableRipple
                label={intl.formatMessage({
                  id: 'Sidebar.MenuTab',
                  description: 'Sidebar.MenuTab',
                  defaultMessage: 'Menu',
                })}
              />
              <Tab
                classes={tabClasses}
                disableRipple
                label={intl.formatMessage({
                  id: 'Sidebar.AccountTab',
                  description: 'Sidebar.AccountTab',
                  defaultMessage: 'Account',
                })}
              />
              <Tab
                classes={tabClasses}
                disableRipple
                label={
                  <span className={styles['flag']}>
                    <Flag
                      countryCode={channel.flag?.toLocaleLowerCase() || DEFAULT_COUNTRY_CODE.toLocaleLowerCase()}
                      svg
                      className={styles['flag__icon']}
                    />
                  </span>
                }
              />
              <Tab
                classes={tabCloseClasses}
                disableRipple
                label={
                  <div className={styles['btn-close']} onClick={handleCloseClick}>
                    <CloseIcon />
                  </div>
                }
              />
            </Tabs>
            {value === 0 ? (
              <Typography component="div">
                <Menu channel={channel} type={DISPLAY_MODE.MOBILE} show={show} onItemClick={handleMenuItemClick} />
              </Typography>
            ) : null}
            {value === 1 ? (
              <Typography component="div">
                <SidebarTabAccount onItemClick={handleItemClick} />
              </Typography>
            ) : null}
            {value === 2 ? (
              <Typography component="div">
                <SidebarTabLocalization onChange={props.onClose} />
              </Typography>
            ) : null}
          </div>
        </Drawer>
      )}
    </ChannelContext.Consumer>
  )
}

export default Sidebar
