import { ActionTypes, SignInSuccessPayload, SignUpSuccessPayload, types } from './auth.types'

export function signInSuccess(data: SignInSuccessPayload): ActionTypes {
  return {
    type: types.SIGN_IN_SUCCESS,
    payload: data,
  }
}

export function signUpSuccess(data: SignUpSuccessPayload): ActionTypes {
  return {
    type: types.SIGN_UP_SUCCESS,
    payload: data,
  }
}

export function logOutSuccess(): ActionTypes {
  return {
    type: types.LOG_OUT_SUCCESS,
  }
}
