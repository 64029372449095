import classnames from 'classnames'
import React from 'react'
import useClubContext from '../../../hooks/club/clubContext'
import FormattedMessage from '../../FormattedMessage'

type Classes = {
  link?: string
}

type Props = {
  classes?: Classes
}

const ClubMenu = (props: Props) => {
  const clubContext = useClubContext()

  return (
    <a href="#" className={classnames(props.classes?.link, 'logout')} onClick={clubContext.logout}>
      <FormattedMessage id="AuthMenu.UserMenu.LogOut" description="AuthMenu.UserMenu.LogOut" defaultMessage="Log Out" />
    </a>
  )
}

export default ClubMenu
